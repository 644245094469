
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CssBaseline } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// import { SizeMe } from 'react-sizeme';
import { useHistory } from 'react-router';
import Utils from '../../felles_____OOO/Utils';
import MenuItemTarget from '../../model/MenuItemTarget';
import UISize from '../../model/UISize';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';
import LPAppBar from '../landing-page/components/LPAppBar';
import LPDrawer from '../landing-page/components/LPDrawer';
import ProfilForm from './components/ProfilForm';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { display: 'flex', height: '100vh', width: '100vw' },
        content: {
            marginTop: 25,
            flexGrow: 1,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -340,
        },
        contentShift: {
            marginTop: 25,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    }),
);

export default function PageProfil(/*props*/) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const screenSize: UISize = useSelector(state => (state as AppState).ui.screenSize);
    const open = screenSize.width > 0 && screenSize.width < 1200 ? false : true;
    const history = useHistory();

    const n: MenuItemTarget = useSelector((state) => (state as AppState).menuItem.menuItemTarget);
    //const open: boolean = useSelector((state) => (state as AppState).ui.isDrawerOpened);
    const transError: any = useSelector((state) => (state as AppState).trans.error);
    useEffect(() => {

      

        dispatch(actions.setLocationPath(<>Brukeradministrasjon {'>'} Min profil</>));
        dispatch(actions.userProfileDataFetch());
    }, [dispatch]);
    //
    const handlePressedKey = (event) => {
        const { key } = event;
        if ((event.shiftKey && key === 'End') || key === 'F3') {
            dispatch(actions.menuItemSetTarget(new MenuItemTarget(n.rootItem, n.item !== '' ? n.item : n.rootItem, '', '', n.tilpasset, n.hasChildren, n.title)));
            dispatch(actions.menuItemSetSelected([n.rootItem, n.item + n.rootItem]));
            history.push(Utils.HOMEPAGE_CONTEXT + '/menu');
        }
    }

    const button: boolean = useSelector((state) => (state as AppState).ui.isSandwichButtonTurnedOn);

    return (
        <div className={classes.root} tabIndex={0} onKeyDown={(e) => handlePressedKey(e)}>
            <CssBaseline />
            <LPDrawer />
            <main className={clsx(classes.content, { [classes.contentShift]: button ? true : open })}>
                <LPAppBar ismenuvisible={true} />
                <ProfilForm />
            </main>
        </div>
    );
}