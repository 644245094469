import * as actionTypes from './actionTypes';


export const setSearchInputPopulatedState = (payload:any) => {
  return { type: actionTypes.SEARCH_FILLED_STATE_UPDATED, payload };
};

export const setSearchSelectionOptions = (payload:any) => {
  return { type: actionTypes.SEARCH_SELECTION_OPTION_UPDATED, payload };
};


export const setSearchInputValue = (payload:any) => {
  return { type: actionTypes.SEARCH_INPUT_VALUE_UPDATED, payload };
};


export const setSearchInputFocused = (payload:any) => {
  return { type: actionTypes.SEARCH_INPUT_FOCUSED, payload };
};
