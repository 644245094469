import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import ShiftHome from '../../components/ShiftHome';
import UISize from '../../model/UISize';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';
import LPDrawer from './components/LPDrawer';
import LPContent from './LPContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex', height: '100vh', width: '100vw' },
    content: {
      marginTop: 0,
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -340,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },

    headerBG: {
      visibility: 'hidden',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },

    //IPAD AIR
    '@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)': {
      headerBG: {
        visibility: 'visible',
        height: 45,
        width: '100%',
        position: 'fixed',
        zIndex: 300,
      },
    },
  })
);

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const screenSize: UISize = useSelector((state) => (state as AppState).ui.screenSize);
  const open: boolean = useSelector((state) => (state as AppState).ui.isDrawerOpened);
  const button: boolean = useSelector((state) => (state as AppState).ui.isSandwichButtonTurnedOn);
  //
  useEffect(() => {
    dispatch(actions.setDrawerState(!(screenSize.width > 0 && screenSize.width < 1200)));
    // Give focus to the search field after login. - 
    // Utils.giveFocusToSearchField();
  }, [dispatch, screenSize]);
  //
  return (
    <div className={classes.root}>
      <ShiftHome />
      <CssBaseline />
      {/*<div className={classes.headerBG}></div>*/}
      <LPDrawer />
      <main className={clsx(classes.content, { [classes.contentShift]: button ? true : open })}>
        {/*<LPDrawerHeader />*/}
        <SizeMe>{({ size }) => <LPContent size={size} />}</SizeMe>
      </main>
    </div>
  );
}
