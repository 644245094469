import Utils from '../../felles_____OOO/Utils';
import UISize from '../../model/UISize';
import * as actionTypes from '../actions/actionTypes';
import * as fromApp from '../rootReducer';

export interface FeatureState extends fromApp.AppState {
  state: StateUI;
}

export interface StateUI {
  screenScale: number;
  screenSize: UISize;
  screenBreakpoint: string;
  gridScale: number;
  gridSize: UISize;
  gridWidth: number;
  drawerWidth: number;
  currentBreakpoint: string;
  compactType: any;
  isMobileLayoutEnabled: boolean;
  mounted: boolean;
  layout: any;
  layouts: any;
  cols: any;
  width: number;
  height: number;
  isDrawerOpened: boolean;
  isSandwichButtonTurnedOn: boolean;
  gridItemHeight: any;

  contextMenuVisible: boolean;
  contextMenuX: number;
  contextMenuY: number;
  transidRedirect: string;
  tilpasset: string;
  scrollTop: number;
  locationPath: any;
}
// drawerWidth: 340, //402
const initialState = {
  screenScale: 1,
  screenSize: new UISize(window.innerWidth, window.innerHeight),
  gridScale: 1,
  gridSize: new UISize(0, 0),
  gridWidth: 900,
  drawerWidth: 340,
  currentBreakpoint: 'lg',
  screenBreakpoint: 'lg',
  compactType: 'vertical',
  isMobileLayoutEnabled: false,
  mounted: false,
  layout: undefined,
  layouts: undefined,
  cols: { xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 },
  // cols: { xl: 24, lg: 24, md: 12, sm: 12, xs: 4, xxs: 2 },
  width: 0,
  height: 0,
  isDrawerOpened: true,
  isSandwichButtonTurnedOn: false,
  gridItemHeight: { xxl: 25, xl: 25, lg: 15, md: 15, sm: 10, xs: 10, xxs: 10 },

  contextMenuVisible: false,
  contextMenuX: 0,
  contextMenuY: 0,
  transidRedirect: '',
  tilpasset: '0',
  scrollTop: 0,
  locationPath: undefined,
};


const setLocationPath = (state: StateUI, action: any) => {
  return Utils.updateStore(state, { locationPath: action.payload});
};

const setScreenSize = (state: StateUI, action: any) => {
  return Utils.updateStore(state, { screenSize: action.payload /*, isFetchingUserProfileData: true*/ });
};

export const setContextMenuVisible = (state: StateUI, action: any) => {
  return Utils.updateStore(state, { contextMenuVisible: action.payload /*, isFetchingUserProfileData: true*/ });
};

export const setTransidRedirect = (state: StateUI, action: any) => {
  return Utils.updateStore(state, { transidRedirect: action.payload /*, isFetchingUserProfileData: true*/ });
};

export const setTilpasset = (state: StateUI, action: any) => {
  return Utils.updateStore(state, { tilpasset: action.payload /*, isFetchingUserProfileData: true*/ });
};

export const setContexMenuX = (state: StateUI, action: any) => {
  return Utils.updateStore(state, { contextMenuX: action.payload /*, isFetchingUserProfileData: true*/ });
};

export const setContexMenuY = (state: StateUI, action: any) => {
  return Utils.updateStore(state, { contextMenuY: action.payload /*, isFetchingUserProfileData: true*/ });
};


const uiReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SCREEN_ZOOM_SCALE:
      return { ...state, screenScale: action.payload };
    case actionTypes.SCREEN_SIZE:
      return setScreenSize(state, action);


      case actionTypes.LOCATION_PATH:
        return setLocationPath(state, action);


    case actionTypes.SET_CONTEXTMENU_VISIBLE:
      return setContextMenuVisible(state, action);


    case actionTypes.SET_TRANSID_REDIRECT:
      return setTransidRedirect(state, action);

      case actionTypes.SET_TILPASSET:
      return setTilpasset(state, action);

    case actionTypes.SET_CONTEXTMENU_X:
      return setContexMenuX(state, action);

    case actionTypes.SET_CONTEXTMENU_Y:
      return setContexMenuY(state, action);

    case actionTypes.GRID_SCALE:
      return { ...state, gridScale: action.payload };
    case actionTypes.GRID_SIZE:
      return { ...state, gridWidth: action.payload };
    case actionTypes.GRID_WIDTH:
      return { ...state, gridWidth: action.payload - state.drawerWidth };
    case actionTypes.SET_GRID_LAYOUTS:
      return { ...state, layout: action.layout, layouts: action.layouts };
    case actionTypes.SET_GRID_LAYOUT:
      return { ...state, layout: action.layout };
    case actionTypes.SET_CURRENT_BREAK_POINT:
      return { ...state, currentBreakpoint: action.payload };
    case actionTypes.SET_SCREEN_BREAK_POINT:
      return { ...state, screenBreakpoint: action.payload };
    case actionTypes.SET_COMPACT_TYPE:
      return { ...state, compactType: action.payload };


      case actionTypes.SET_IS_MOBILE_ENABLED:
        return { ...state, isMobileLayoutEnabled: action.payload };

    case actionTypes.SET_MOUNTED:
      return { ...state, mounted: action.payload };
    case actionTypes.SET_DRAWER_STATE:
      return { ...state, isDrawerOpened: action.payload };
    case actionTypes.SET_BUTTON_SANDWICH_STATE_CLICKED:
      return { ...state, isSandwichButtonTurnedOn: action.payload };
    case actionTypes.SET_SCROLLTOP:
      return { ...state, scrollTop: action.payload };

    case actionTypes.DESTROY_SESSION:
      return initialState;

    default:
      return state;
  }
};

export default uiReducer;
