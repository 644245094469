import Utils from '../../felles_____OOO/Utils';
import * as actionTypes from '../actions/actionTypes';
import * as fromApp from '../rootReducer';

export interface FeatureState extends fromApp.AppState {
  state: StateUtils;
}

export interface StateUtils {
  isLoading: boolean;
  msg: string;
  title: string;
  isSimpleDialogueOpen: boolean;
  isDebugEnabled: boolean;
  driftContent: any;
  isDriftContentLoading: boolean;
  nyheterContent: any;
  isNyheterContentLoading: boolean;
  error: any;
  charCode: string;
  recordingState: number;
  recordingLog: any[];
}

const initialState = {
  isLoading: false,
  msg: '',
  title: '',
  isSimpleDialogueOpen: false,
  isDebugEnabled: false,
  driftContent: undefined,
  driftContentList: [],
  isDriftContentLoading: false,
  nyheterContent: undefined,
  nyheterContentList: [],
  isNyheterContentLoading: false,
  error: undefined,
  charCode: 'B',
  recordingState: 0,
  recordingLog: [],
  //
};

const switchDebugMode = (state: StateUtils) => {
  return Utils.updateStore(state, { isDebugEnabled: !state.isDebugEnabled });
};

const driftMeldingStarted = (state: StateUtils) => {
  return Utils.updateStore(state, { isDriftContentLoading: true });
};

const driftMeldingFetchStarted = (state: StateUtils) => {
  return Utils.updateStore(state, { isDriftContentLoading: true });
};

const driftMeldingFetchSuccess = (state: StateUtils, action: any) => {
  return Utils.updateStore(state, { isDriftContentLoading: false, driftContent: action.payload });
};

const driftMeldingFetchFailed = (state: StateUtils, action: any) => {
  return Utils.updateStore(state, { isDriftContentLoading: false, error: action });
};

const nyheterMeldingStarted = (state: StateUtils) => {
  return Utils.updateStore(state, { isNyheterContentLoading: true });
};

const nyheterMeldingFetchStarted = (state: StateUtils) => {
  return Utils.updateStore(state, { isNyheterContentLoading: true });
};

const nyheterMeldingFetchSuccess = (state: StateUtils, action: any) => {
  return Utils.updateStore(state, { isNyheterContentLoading: false, nyheterContent: action.payload });
};

const nyheterMeldingFetchFailed = (state: StateUtils, action: any) => {
  return Utils.updateStore(state, { isNyheterContentLoading: false, error: action });
};

const setLoadingState = (state: StateUtils, action: any) => {
  return Utils.updateStore(state, { isLoading: action.payload });
};

const setCharEnc = (state: StateUtils, action: any) => {
  return Utils.updateStore(state, { charCode: action.payload });
};

const setRecordingState = (state: StateUtils, action: any) => {
  return Utils.updateStore(state, { recordingState: action.payload });
};

const updateRecordingLog = (state: StateUtils, action: any) => {
  const myClonedArray: any[] = [...state.recordingLog];
  myClonedArray.push(action.payload);
  return Utils.updateStore(state, { recordingLog: (action.payload.length === 0) ? [] : myClonedArray });
};


/*t submitReport = (state: StateUtils) => {
  return Utils.updateStore(state, { setRecordingState: 2 });
};


const reportSubmitted  = (state: StateUtils) => {
  return Utils.updateStore(state, { setRecordingState: 0 });
};
/**/

const utilsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    //
    case actionTypes.UI_SET_LOADING:
      return setLoadingState(state, action);
    case actionTypes.UI_SET_MSG:
      return { ...state, msg: action.payload };

    case actionTypes.UI_SET_TITLE:
      return { ...state, title: action.payload };
    case actionTypes.UI_SET_SIMPLE_DIALOG:
      return { ...state, isSimpleDialogueOpen: action.payload };

    case actionTypes.SWITCH_DEBUG_MODE:
      return switchDebugMode(state);

    case actionTypes.DESTROY_SESSION:
      return initialState;

    case actionTypes.DRIFT_MELDING_GET_INIT:
      return driftMeldingStarted(state);

    case actionTypes.DRIFT_MELDING_GET:
      return driftMeldingFetchStarted(state);

    case actionTypes.DRIFT_MELDING_GET_SUCCESS:
      return driftMeldingFetchSuccess(state, action);

    case actionTypes.DRIFT_MELDING_GET_FAILED:
      return driftMeldingFetchFailed(state, action);

    case actionTypes.NYHETER_MELDING_INIT:
      return nyheterMeldingStarted(state);

    case actionTypes.NYHETER_MELDING_GET_INIT:
      return nyheterMeldingFetchStarted(state);

    case actionTypes.NYHETER_MELDING_GET_SUCCESS:
      return nyheterMeldingFetchSuccess(state, action);

    case actionTypes.NYHETER_MELDING_GET_FAILED:
      return nyheterMeldingFetchFailed(state, action);
    case actionTypes.SET_CHAR_ENC:
      return setCharEnc(state, action);
    case actionTypes.SET_RECORDING_STATE:
      return setRecordingState(state, action);
    case actionTypes.UPDATE_BUG_RECORDING:
      return updateRecordingLog(state, action);
    default:
      return state;
  }
};

export default utilsReducer;


