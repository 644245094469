import { put, select } from 'redux-saga/effects';
import { getAccountingJobCSVList, getAccountingJobList, getJobIdMarkedAsDeleted } from '../../felles_____OOO/X_X_X_X_X_X_X_X_APICalls';
import * as actions from '../actions';
import * as selectors from '../selectors';
import fileDownload from 'js-file-download';
import Utils from '../../felles_____OOO/Utils';

//
export function* accountingJobListFetchSaga() {
  try {
    const date = yield select(selectors.date);
    //const user = yield select(selectors.userCred);
    yield put(actions.accountingJobListGetInit());
    const response = yield getAccountingJobList(date);
    yield put(actions.accountingJobListGetSuccess(response.data));
  } catch (error) {
    yield put(actions.accountingJobListGetFailed(error));
  }
}

// CSV
export function* accountingJobCSVListFetchSaga() {
  try {
    const date = yield select(selectors.date);
    yield put(actions.accountingJobCVSListGetInit());
    const response = yield getAccountingJobCSVList(date);
    yield fileDownload(response.data, 'jobs_' + Utils.getFormattedDate(date) + '_' + new Date().getTime() + '.csv');
    yield put(actions.accountingJobCVSListGetSuccess());
  } catch (error) {
    yield put(actions.accountingJobCVSListGetFailed(error));
  }
}

//
export function* accoutingJobListDeleteSelected() {
 // const date = yield select(selectors.date);
   const selectedJobList = yield select(selectors.selectedJobList);
  try {
    yield put(actions.accountingJobListSelectedDeletionPostInit());
   /* const response = */yield getJobIdMarkedAsDeleted(selectedJobList);
    yield put(actions.accountingJobListSelectedDeletionPostSuccess());
    //dispatch(actions.accountingDeletionDialogUpdated(false));
  } catch (error) {
    // Fall back - restoring list - assuming there is network connection
    yield put(actions.accountingJobListGet());
    yield put(actions.accountingJobListSelectedDeletionPostFailed());
  }
}

//
export function* accoutingJobListExportSelected() {
  try {
    yield put(actions.accountingJobListSelectedExportGetInit());
    yield put(actions.accountingJobListSelectedExportGetSuccess());
  } catch (error) {
    yield put(actions.accountingJobListSelectedExportGetFailed());
  }
}
