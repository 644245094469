import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { red } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import SVGLinks from '../../../components/SVGLinks';
import CAutodataWeb from './CAutodataWeb';
import CDriftStatus from './CDriftStatus';
import CLenker from './CLenker';
import CNyheter from './CNyheter';
import CSupport from './CSupport';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { borderRadius: 0, height: '100%', border: '1px solid grey' },
    fieldWrapper: { border: '1px solid', width: '80%', margin: '0 auto' },
    cardHeader: { backgroundColor: '#7c7980', color: '#fff', border: '1px solid #7c7980', paddingTop: 12, paddingBottom: 12, paddingLeft: 20 },
    cardContent: { paddingTop: 0, paddingBottom: 0, paddingLeft: 20 },
    subtitle: { fontWeight: 500, marginBottom: 5 },
    title: { display: 'flex', width: '100%', fontSize: '1.6em' },

    content: { marginBottom: 0 },
    media: { height: 0, paddingTop: '56.25%' },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: { transform: 'rotate(180deg)' },
    avatar: { backgroundColor: red[500] },
    logo: {
      lineHeight: 1.5,
      marginLeft: '10px',
      color: '#fff',
      width: '15%',
      cursor: 'pointer',
    },

    /*XXS*/[theme.breakpoints.between(0, 801)]: { title: { fontSize: '.7em' }, logo: { width: 12 }, cardContent: { fontSize: '1em' }, cardHeader: { height: 30 }, },
    /*XS */[theme.breakpoints.between(801, 1367)]: { title: { fontSize: '.7em' }, logo: { width: 12 }, cardContent: { fontSize: '1.1em' }, cardHeader: { height: 30 }, },
    /*SM */[theme.breakpoints.between(1367, 1681)]: { title: { fontSize: '.7em' }, logo: { width: 12 }, cardContent: { fontSize: '1.3em' }, cardHeader: { height: 30 }, },
    /*MD */[theme.breakpoints.between(1681, 1921)]: { title: { fontSize: '.9em' }, logo: { width: 14 }, cardContent: { fontSize: '1.4em' }, cardHeader: { height: 40 }, },
    /*LD */[theme.breakpoints.between(1921, 2561)]: { title: { fontSize: '1.2em' }, logo: { width: 20 }, cardContent: { fontSize: '1.6em' }, cardHeader: { height: 60 }, },
    /*XL */[theme.breakpoints.between(2561, 3841)]: { title: { fontSize: '1.4em' }, logo: { width: 20 }, cardContent: { fontSize: '2em' }, cardHeader: { height: 70 }, },
    /*XXL*/[theme.breakpoints.between(3841, 10000)]: { title: { fontSize: '1.6em' }, logo: { width: 20 }, cardContent: { fontSize: '3em' }, cardHeader: { height: 70 }, },
  })
);

export default function LPContentCard(props) {
  const classes = useStyles();
  const { link, title } = props;

  //
  function getCardContentByType(type: number) {
    switch (type) {
      case 0:
        return <CDriftStatus numitems={3} />;
      case 1:
        return <CAutodataWeb />;
      case 2:
        return <CNyheter numitems={3} />;
      case 3:
        return <CSupport />;
      case 4:
        return <CLenker />;
      default:
        return [];
    }
  }

  //
  function handleSubmit(event, url) {
    event.preventDefault();
    if (link.length > 2) {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  }

  return (
    <Card elevation={3} className={classes.root}>
      <CardHeader
        onClick={(e) => handleSubmit(e, link)}
        className={classes.cardHeader}
        title={
          <div className={classes.title}>
            <div>{title}</div>
            {link !== '' ? (
              <div className={classes.logo}>
                <SVGLinks width="20" />
              </div>
            ) : null}
          </div>
        }
      />
      <CardContent className={classes.cardContent}>{getCardContentByType(props.cardType)}</CardContent>
    </Card>
  );
}
