import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import sizeMe, { SizeMe } from 'react-sizeme';
import MenuItemTarget from '../../model/MenuItemTarget';
import { AppState } from '../../store/rootReducer';
import LPAppBar from './components/LPAppBar';
import LPContentDefault from './LPContentDefault';
import LPContentItem from './LPContentItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contWrap: { marginTop: 50 },
    /*XXS*/ [theme.breakpoints.between(0, 801)]: { contWrap: { marginTop: 70 } },
    /*XS */ [theme.breakpoints.between(801, 1367)]: { contWrap: { marginTop: 80 } },
    /*SM */ [theme.breakpoints.between(1367, 1681)]: { contWrap: { marginTop: 94 } },
    /*MD */ [theme.breakpoints.between(1681, 1921)]: { contWrap: { marginTop: 180 } },
    /*LD */ [theme.breakpoints.between(1921, 2561)]: { contWrap: { marginTop: 180 } },
    /*XL */ [theme.breakpoints.between(2561, 3841)]: { contWrap: { marginTop: 180 } },
    /*XXL*/ [theme.breakpoints.between(3841, 10000)]: { contWrap: { marginTop: 180 } },

    // Apple 10.2-inch iPad Wi-Fi + Cellular - 9. generasjon
    '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
      contWrap: { marginTop: 30 },
    },
  })
);

function LPContent(props) {
  //
  const menuItemTarget: MenuItemTarget = useSelector((state) => (state as AppState).menuItem.menuItemTarget);
  const classes = useStyles();

   //
   useEffect(() => {
    document.title = 'Vitec Autodata';
  }, []);
  //


  //
  const generateDOM = () => {
    switch (true) {
      case menuItemTarget.rootItem === '':
        return <SizeMe>{({ size }) => <LPContentDefault size={size} />}</SizeMe>;

      default:
        return (
          <>
            <LPAppBar ismenuvisible={true} />
            <LPContentItem size={props.size} />
          </>
        );
    }
  };

  return <div className={classes.contWrap}>{generateDOM()}</div>;
}
export default sizeMe()(LPContent);
