export default class MenuItemTarget {
  private _rootItem: string;
  private _item: string;
  private _subitem: string;
  private _code: string;
  private _hasChildren: boolean;
  private _title: string;
  private _tilpasset: number;
  

  constructor(rootItem = '', item = '', subitem = '', code = '', tilpasset = 0,  hasChildren = false, title = '') {
    this._rootItem = rootItem;
    this._item = item;
    this._subitem = subitem;
    this._code = code;
    this._tilpasset = tilpasset;
    this._hasChildren = hasChildren;
    this._title = title;
  }

  get item(): string {
    return this._item;
  }

  set item(value: string) {
    this._item = value;
  }

  get subitem(): string {
    return this._subitem;
  }

  set subitem(value: string) {
    this._subitem = value;
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get tilpasset(): number {
    return this._tilpasset;
  }

  set tilpasset(value: number) {
    this._tilpasset = value;
  }

  get rootItem(): string {
    return this._rootItem;
  }

  set rootItem(value: string) {
    this._rootItem = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

   get hasChildren(): boolean {
    return this._hasChildren;
  }

}
