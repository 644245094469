import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { red } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Utils from '../../../felles_____OOO/Utils';
import MenuEl from '../../../model/MenuEl';
import MenuItemTarget from '../../../model/MenuItemTarget';
import * as actions from '../../../store/actions/index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiCardContent-root': {
        fontSize: '1.2em',
        fontWeight: 'bold',
        lineHeight: '25px',
        padding: 0,
      },
      '.MuiCardHeader-title': {
        fontWeight: 'bold',
        lineHeight: '25px',
        /* XXS */[theme.breakpoints.between(0, 801)]: { fontSize: '1.2em' },
        /* XS  */[theme.breakpoints.between(801, 1367)]: { fontSize: '1.2em' },
        /* SM  */[theme.breakpoints.between(1367, 1681)]: { fontSize: '1.3em' },
        /* MD  */[theme.breakpoints.between(1681, 1921)]: { fontSize: '1.3em' },
        /* LD  */[theme.breakpoints.between(1921, 2561)]: { fontSize: '1.3em' },
        /* XL  */[theme.breakpoints.between(2561, 3841)]: { fontSize: '1.4em' },
        /* XXL */[theme.breakpoints.between(3841, 10000)]: { fontSize: '1.4em' },
      },
    },

    root: {
      borderRadius: 0,
      height: 'auto',
      width: 'auto',
      padding: 0,
      margin: 0,
      backgroundColor: '#7c7980',
      zIndex: -10,

      // '&
      /*
            '& .$MuiCardContent-root': {
              fontSize: '1.2em',
              fontWeight: 'bold',
              lineHeight: '25px',
              padding: 0,
            },
      */
      cardContent: { fontSize: '1.2em', fontWeight: 'bold', lineHeight: '25px', padding: 0, '&:last-child': { paddingBottom: 0 }, },
      /**/
    },
    cardHeader: {
      backgroundColor: '#7c7980',
      color: '#fff',
      border: '1px solid #7c7980',
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 20,
    },
    subtitle: {
      fontWeight: 500,
      /* XXS */[theme.breakpoints.between(0, 801)]: { fontSize: '.7em' },
      /* XS  */[theme.breakpoints.between(801, 1367)]: { fontSize: '.7em' },
      /* SM  */[theme.breakpoints.between(1367, 1681)]: { fontSize: '.7em' },
      /* MD  */[theme.breakpoints.between(1681, 1921)]: { fontSize: '.7em' },
      /* LD  */[theme.breakpoints.between(1921, 2561)]: { fontSize: '.7em' },
      /* XL  */[theme.breakpoints.between(2561, 3841)]: { fontSize: '1em' },
      /* XXL */[theme.breakpoints.between(3841, 10000)]: { fontSize: '1em' },
    },
    content: {
      backgroundColor: '#fff',
      padding: 10,
      /* XXS */[theme.breakpoints.between(0, 801)]: { fontSize: '.8em' },
      /* XS  */[theme.breakpoints.between(801, 1367)]: { fontSize: '.8em' },
      /* SM  */[theme.breakpoints.between(1367, 1681)]: { fontSize: '.8em' },
      /* MD  */[theme.breakpoints.between(1681, 1921)]: { fontSize: '.8em' },
      /* LD  */[theme.breakpoints.between(1921, 2561)]: { fontSize: '1em' },
      /* XL  */[theme.breakpoints.between(2561, 3841)]: { fontSize: '1em' },
      /* XXL */[theme.breakpoints.between(3841, 10000)]: { fontSize: '1.4em' },

      borderTop: '1px solid #7c7980',
      '&:hover': { cursor: 'pointer', backgroundColor: '#f3f3f3' },
      '&:active': { cursor: 'pointer', backgroundColor: '#000', color: '#f3f3f3' },
      '&:first-child': {
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        //  border: '1px solid #7c7980',
      },
    },
    media: { height: 0 },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: { transform: 'rotate(180deg)' },
    avatar: { backgroundColor: red[500] },
  })
);

export default function LPContentCardVSE(props) {
  const classes = useStyles();
  const title = props.title;
  const data = props.data;
  const dispatch = useDispatch();
  const history = useHistory();
  //
  const handleItemClicked = (event, menuItemTarget: MenuItemTarget) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(actions.setSandwichButtonStateClicked(false));
    dispatch(actions.setLocationPath(Utils.getLocationPatchFromMenuItem(menuItemTarget, menuItemTarget.code, menuItemTarget.title)));
    if (+menuItemTarget.tilpasset === 1) {
      history.push(Utils.HOMEPAGE_CONTEXT + '/' + menuItemTarget.code);
    } else if (+menuItemTarget.tilpasset === 2) {
      history.push(Utils.HOMEPAGE_CONTEXT + '/' + menuItemTarget.code);
    }

    else {
      dispatch(actions.transGet(menuItemTarget));
    }
  };

  function handleRightClick(e, menuItem) {
    const clickX = e.clientX;
    const clickY = e.clientY;
    dispatch(actions.setContextMenuVisible(true));
    dispatch(actions.setContexMenuX(clickX));
    dispatch(actions.setContexMenuY(clickY));
    dispatch(actions.setTilpasset(menuItem.tilpasset));
    dispatch(actions.setTransidRedirect(menuItem.code));
  }

  const renderMenuItems = () => {
    const JSXItemElemments: any = [];
    let count = 0;
    data.forEach((el: MenuEl) => {
      JSXItemElemments.push(
        <Typography title={el.description}
          onClick={(event) => handleItemClicked(event, new MenuItemTarget(el.rootName, el.parentName, el.name, el.code, el.tilpasset, el.hasChildren, el.description))}
          onContextMenu={(event) => handleRightClick(event, new MenuItemTarget(el.rootName, el.parentName, el.name, el.code, el.tilpasset, el.hasChildren, el.description))}
          className={classes.content} style={{ height: 'auto ! important', }}
          key={el.code + count++}
          variant="body2"
          color="textSecondary"
          component="p">
          <span className={classes.subtitle}>{el.code}&nbsp;&nbsp;</span>
          <span >{' ' + el.description}</span>
	  </Typography>
      );
    });
    //
    return <CardContent>{JSXItemElemments}</CardContent>;
  };
  //
  return (
    <Card elevation={3} className={classes.root}>
      <CardHeader className={classes.cardHeader} title={title} />
      {renderMenuItems()}
    </Card>
  );
}
