import { Button, CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Papyrus from '../assets/images/background-body.png';
import * as label from '../autobuild_version';
import BugRepportHandler from '../containers/error/BugRepportHandler';
import RightClickMenu from '../containers/landing-page/components/RightClickMenu';
import Utils from '../felles_____OOO/Utils';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';
import ButtonVitec from './ButtonVitec';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex', height: '100vh', width: '100vw' },
    backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },

    appLayout: { margin: 0, padding: 0, width: '100vw', height: '100vh', },
    bgHeader: {
      background: 'rgba(150,100,103, 1)',
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      display: 'block',
      backgroundImage: `url(${Papyrus})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      opacity: 0.13,
      pointerEvents: 'none',
    },
    version: { position: 'fixed', color: '#f3f3f3', right: '15px', margin: '2px', opacity: 0.2, },
    debug: {
      position: 'absolute',
      display: ' inline-block',
      verticalAlign: ' top',
      fontSize: '1.5em',
      color: '#f3f3f3',
      top: 0,
      right: 0,
      width: 1050,
    },
  })
);

const Layout = ({ children }) => {
  const style = { width: '100%', margin: 0, padding: 0 };
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading: boolean = useSelector((state) => (state as AppState).utils.isLoading);
  const dialogMsg: string = useSelector((state) => (state as AppState).utils.msg);
  const dialogTitle: string = useSelector((state) => (state as AppState).utils.title);
  const isSimpleDialogueOpen: boolean = useSelector((state) => (state as AppState).utils.isSimpleDialogueOpen);
  const apiVersion = 'A' + Utils.T_apiVersion;
  const recordingState: number = useSelector((state) => (state as AppState).utils.recordingState);
  // const QTR: number = useSelector((state) => (state as AppState).auth.stateQTR);
  const reportingLog: any[] = useSelector((state) => (state as AppState).utils.recordingLog);
  const isDebugEnabled: boolean = useSelector((state) => (state as AppState).utils.isDebugEnabled);

  const handleDiagClose = () => {
    dispatch(actions.setIsloading(false));
    dispatch(actions.setSimpledialogOpeningState(false));
    dispatch(actions.setMsg(''));
    dispatch(actions.setTitle(''));
  };

  const handleStartClickedBtn = (val) => {
    if (recordingState === 0) {
      dispatch(actions.setRecordingState(1));
      dispatch(actions.updateRecordingLog([<p>helloe</p>]));
    }
    else if (recordingState > 0) {
      dispatch(actions.setRecordingState(0));
    }
  }

  const handleSubmitClickedBtn = (val) => {
    dispatch(actions.setRecordingState(2));
  }

  const handleResetClickedBtn = (val) => {
    dispatch(actions.setRecordingState(0));
    dispatch(actions.updateRecordingLog([]));

    if (isDebugEnabled && recordingState === 0) {
      dispatch(actions.switchDebugMode());
    }
  }

  /*
  function handlePressedKey(event): void {
    const { key } = event;
    if (key === 'Escape') {
      event.preventDefault();
      dispatch(actions.setIsloading(false));
    }
  }
  */
 
  return (
    <>
      <div id="no-portrait">
        <div id="chg-landscape">Vennligst bytt til liggende modus</div>
      </div>
      {/*' tabIndex={0} onKeyDown={(e) => handlePressedKey(e)} '*/}
      <div className={classes.appLayout}>
        <div className={classes.version}>
          {'F' + label?.version + ' | ' + apiVersion}
        </div>
        <div className={classes.bgHeader} style={{ zIndex: -1251 }}></div>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog open={isSimpleDialogueOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{dialogMsg}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDiagClose} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <div className="app-layout-content" style={style}>
          {children}
        </div>
      </div>
      <RightClickMenu />
      <div style={{ position: 'fixed', bottom: 10, right: 10 }}>  {
        /*QTR === 1 && */ isDebugEnabled ?
          <>{ /*recordingState + ' --- ' + reportingLog + ' --- ' + reportingLog.length*/}<ButtonVitec _type={'submit'} _fontsize={'1em'} _onclick={() => handleStartClickedBtn(recordingState === 0 ? 1 : 0)} _name={'record'}
            _label={recordingState === 0 ? 'Start recording' : 'Stop recording'} _width={250} _height={30} _padding={5} _margin={0} />
            <ButtonVitec _type={'submit'} _fontsize={'1em'} _onclick={handleResetClickedBtn} _name={'submit'}
              _label={isDebugEnabled && recordingState === 0 ? 'Quit debug' : 'Reset'} _width={100} _height={30} _padding={5} _margin={0} />
            <ButtonVitec _type={'submit'} _fontsize={'1em'} _onclick={handleSubmitClickedBtn} _name={'submit'}
              _label={'Submit aktiviteter'} _width={100} _height={30} _padding={10} _margin={0} disabled={reportingLog?.length === 0} />
          </>
          : null}
      </div>
      {recordingState > 1 ? <BugRepportHandler /> : null}

    </>
  );
};

export default Layout;
