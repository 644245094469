import { Backdrop, Input } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { createRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ButtonVitec from '../../components/ButtonVitec';
import DialogVitec from '../../components/DialogVitec';
import ExcelFormData from '../../model/ExcelFormData';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customTooltip: {
      // I used the rgba color for the standard "secondary" color
      backgroundColor: '#fff',
      fontSize: '1em',
      color: 'black',
      border: '1px solid #fff',
      margin: 0,
      marginTop: 10,
      marginLeft: -5,
    },
    customArrow: { color: ' #fff', },
    root: { marginLeft: 0, marginRight: 0, paddingTop: 0 },
    formWrapper: { width: '100vw', display: 'table-cell', verticalAlign: 'middle', height: '93vh', padding: 20, },
    formAdd: {
      marginTop: 'min(20%)',
      marginBottom: 'min(20%)',
      boxShadow: '3px 3px 3px #222',
      horizontalAlign: 'center',
      backgroundColor: '#f3f3f3',
      width: 630,
      margin: '0 auto',
      padding: 30,
      border: '1px solid #333',
      borderRadius: '5px',
      '& .MuiTextField-root': { padding: 0, width: '30ch', border: 'none' },
    },
    mInt: { width: '100%', padding: '10px', marginBottom: '14px', marginTop: '3px', border: '1px solid #ccc' },
    myLabel: { width: 600, fontSize: '1.2em' },
    myTitle: { width: 600, fontSize: '1.6em', marginBottom: '30px', color: 'black' },
    formError: { fontSize: '1.4em', marginTop: '30px', color: 'red', align: 'center' },
    backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
    checkboxWrapper: { color: '#000' },
    checkbox: { display: 'inline-flex', marginLeft: 10, marginBottom: 10 },
    grpCheckbox: { marginLeft: 4, color: '#000', align: 'top' },
    myButtons: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', },
    dialogCustomizedWidth: { minWidth: '400px', maxWidth: '400px', minHeight: '150px' },
    myDialogTitle: { textAlign: 'center', fontSize: '1.4em', width: '100%', color: '#000' },
    rowField: { display: 'flex', },

    '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
      root: { marginLeft: 0, marginRight: 0, paddingTop: 0 },
      formWrapper: { padding: 5 },
      formAdd: { width: 700, height: 500, padding: 15, },
      mInt: { width: '100%', padding: '5px', marginBottom: '7px', marginTop: '0px', border: '1px solid #ccc' },
      myLabel: { width: 500, fontSize: '1em' },
      myTitle: { width: 500, fontSize: '1.2em', marginBottom: '10px', color: 'black' },
      formError: { fontSize: '1.4em', marginTop: '30px', color: 'red', align: 'center' },
      backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
      checkboxWrapper: { color: '#000' },
      checkbox: { display: 'inline-flex', marginLeft: 5, marginBottom: 5 },
      grpCheckbox: { marginLeft: 4, color: '#000', align: 'top' },
      myButtons: { display: 'inline-flex', verticalAlign: 'top', margin: '0 50%', transform: 'translateX(-295px)' },
      dialogCustomizedWidth: { minWidth: '400px', maxWidth: '400px', minHeight: '150px' },
    },

    myInput: {
      width: '100%',
      padding: '12px',
      marginBottom: '10px',
      border: '1px solid #ccc',
      color: '#000',
    },

    txtWarn: {
      fontSize: '1.2em',
      color: '#000',
    },
  })
);


const ExcelForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formRef: any = createRef();
  const excelImportState: number = useSelector((state) => (state as AppState).tallenesTale.excelImportState);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { register, watch, handleSubmit } = useForm({ defaultValues: { file: null } })

  const fileObj: any = watch("file");

  useEffect(() => {
    if (fileObj && fileObj.length > 0)
      setIsButtonDisabled(false);
    else
      setIsButtonDisabled(true);
  }, [excelImportState, fileObj]);



  const onSubmit = (data: any = undefined) => {
    const body = new FormData();
    body.append('file', data.file[0]);

    dispatch(actions.excelPost(new ExcelFormData(data.file[0].name, '', '', '', body)));
  };

  //
  const onCancel = () => {
    dispatch(actions.setExcelImportState(0));
    // Som clean up
  };
  //
  const generateExcelImportStates = () => {
    switch (true) {
      case excelImportState === 1:
        return (
          <form onSubmit={onSubmit} encType="multipart/form-data" ref={formRef} className={classes.formAdd}>
            <div className={classes.txtWarn}>
              Alle tidligere oppføringer vil bli slettet fra databasen. Sørg for at dokumentet ditt er fullstendig.</div>
            <Input className={classNames(classes.mInt)} {...register("file", { required: true })} type="file" id="file"
              style={{ marginTop: 16, }} />
            <div className={classes.myButtons}>
              {<ButtonVitec _onclick={handleSubmit(onSubmit)} _width={200} _height={37} _type={'submit'} _label={'IMPORTERE'} disabled={isButtonDisabled} />}
              <ButtonVitec _onclick={() => onCancel()} _width={80} _height={37} _type={'button'} _label={'Avbryt'} />
            </div>
          </form>
        );
      case excelImportState === 2:
        return (
          <DialogVitec>
            <div className={classes.myDialogTitle}>Vennligst vent...</div>
          </DialogVitec>
        );
      case excelImportState === 3:
        return (
          <DialogVitec>
            <div className={classes.myDialogTitle}>Informasjon lagret</div>
            <div className={classes.myButtons}>
              <ButtonVitec _onclick={() => {dispatch(actions.setExcelImportState(0)); dispatch(actions.criteriaItemListGet())}} _width={80} _height={37} _type={'button'} _label={'Ok'} />
            </div>
          </DialogVitec>
        );
      case excelImportState === 4:
        return (
          <DialogVitec>
            <div className={classes.myDialogTitle}>Det oppstod et problem ved import av ditt Excel-dokument. <br/>Vennligst kontakt brukerstøtte for ytterligere assistanse.</div>
            <div className={classes.myButtons}>
              <ButtonVitec _onclick={() => dispatch(actions.setExcelImportState(0))} _width={80} _height={37} _type={'button'} _label={'Ok'} />
            </div>
          </DialogVitec>
        );
      default:
        return null;
    }
  };
  //
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={true}>
        {generateExcelImportStates()}
      </Backdrop>
    </div>
  );
};

export default ExcelForm;