// Font: ANSI SHADOW

// ███████╗████████╗ █████╗ ████████╗███████╗
// ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██╔════╝
// ███████╗   ██║   ███████║   ██║   █████╗  
// ╚════██║   ██║   ██╔══██║   ██║   ██╔══╝  
// ███████║   ██║   ██║  ██║   ██║   ███████╗
// ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝   ╚══════╝
//                                          
export const DESTROY_SESSION = 'DESTROY_SESSION';

// ██╗   ██╗██╗
// ██║   ██║██║
// ██║   ██║██║
// ██║   ██║██║
// ╚██████╔╝██║
//  ╚═════╝ ╚═╝
//
export const GRID_SCALE = 'GRID_SCALE';
export const GRID_SIZE = 'GRID_SIZE';
export const GRID_WIDTH = 'GRID_WIDTH';
export const SET_GRID_LAYOUTS = 'SET_GRID_LAYOUTS';
export const SET_GRID_LAYOUT = 'SET_GRID_LAYOUT';
export const SET_SCREEN_BREAK_POINT = 'SET_CURRENT_BREAK_POINT';
export const SET_CURRENT_BREAK_POINT = 'SET_CURRENT_BREAK_POINT';
export const SET_MOUNTED = 'SET_MOUNTED';
export const SET_COMPACT_TYPE = 'SET_COMPACT_TYPE';
export const SET_DRAWER_STATE = 'SET_DRAWER_STATE';
export const SET_BUTTON_SANDWICH_STATE_CLICKED = 'SET_BUTTON_SANDWICH_STATE_CLICKED';

export const SET_IS_MOBILE_ENABLED = 'SET_IS_MOBILE_ENABLED';

export const SCREEN_ZOOM_SCALE = 'SCREEN_ZOOM_SCALE';
export const SCREEN_SIZE = 'SCREEN_SIZE';
export const LOCATION_PATH = 'LOCATION_PATH' ;
export const UI_SET_LOADINGA = 'UI_SET_LOADINGA';
export const UI_SET_LOADING = 'UI_SET_LOADING';
export const UI_SET_MSG = 'UI_SET_MSG';
export const UI_SET_TITLE = 'UI_SET_TITLE';
export const UI_SET_SIMPLE_DIALOG = 'UI_SET_SIMPLE_DIALOG';
export const SWITCH_DEBUG_MODE = 'SWITCH_DEBUG_MODE';
export const SET_TRANSID_REDIRECT = 'SET_TRANSID_REDIRECT';
export const SET_TILPASSET = 'SET_TILPASSET';
export const SET_CONTEXTMENU_VISIBLE = 'SET_CONTEXTMENU_VISIBLE';
export const SET_CONTEXTMENU_X = 'SET_CONTEXTMENU_X';
export const SET_CONTEXTMENU_Y = 'SET_CONTEXTMENU_Y';
export const SET_SCROLLTOP = 'SET_SCROLLTOP';
export const SET_LOADING_STATE = 'SET_LOADING_STATE';


//export const  SET_IS_LOADING_COMPONENT = 'SET_IS_LOADING_COMPONENT';


//  █████╗ ██╗   ██╗████████╗██╗  ██╗
// ██╔══██╗██║   ██║╚══██╔══╝██║  ██║
// ███████║██║   ██║   ██║   ███████║
// ██╔══██║██║   ██║   ██║   ██╔══██║
// ██║  ██║╚██████╔╝   ██║   ██║  ██║
// ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝
//
export const AUTODATA_AUTH_USER = 'AUTODATA_AUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';

export const AUTODATA_USER_JWT_UPDATED = 'AUTODATA_USER_JWT_UPDATED';
export const HEIRLOOM_USER_JWT_UPDATED = 'HEIRLOOM_USER_JWT_UPDATED';

export const USER_LOGIN_TO_AUTODATA_GET_INIT = 'USER_LOGIN_TO_AUTODATA_GET_INIT';
export const USER_LOGIN_TO_AUTODATA_GET_AUTH = 'USER_LOGIN_TO_AUTODATA_GET_AUTH';
export const USER_LOGIN_TO_AUTODATA_GET_SUCCESS = 'USER_LOGIN_TO_AUTODATA_GET_SUCCESS';
export const USER_LOGIN_TO_AUTODATA_GET_FAILED = 'USER_LOGIN_TO_AUTODATA_GET_FAILED';

export const USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET_INIT = 'USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET_INIT';
export const USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET = 'USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET';
export const USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET_SUCCESS = 'USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET_SUCCESS';
export const USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET_FAILED = 'USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET_FAILED';
export const SET_SESSION_AS_TABBED = 'SET_SESSION_AS_TABBED';
export const SET_SESSION_AS_PARENT = 'SET_SESSION_AS_PARENT';
export const SET_HAS_SIBLING = 'SET_HAS_SIBLING';
export const SET_IS_LOGGED_FROM_ANOTHER_TAB = 'SET_IS_LOGGED_FROM_ANOTHER_TAB';
export const SET_IS_ALREADY_LOGGED_FROM_ANOTHER_TAB = 'SET_IS_ALREADY_LOGGED_FROM_ANOTHER_TAB';

export const SET_TABLIST_SIZE = 'SET_TABLIST_SIZE';
export const SET_TAB_AS_ACTIVE = 'SET_TAB_AS_ACTIVE';
export const SET_TAB_ID = 'SET_TAB_ID';

export const SET_IS_LOGGED = 'SET_IS_LOGGED';
export const SET_HAS_LOGGED_OUT = 'SET_HAS_LOGGED_OUT';

export const SET_QTR_STATE = 'SET_QTR_STATE';

export const SET_INITIAL_REDIRECT_URL = 'SET_INITIAL_REDIRECT_URL';

export const USER_LOGIN_TO_HEIRLOOM_INIT = 'USER_LOGIN_TO_HEIRLOOM_INIT';
export const USER_LOGIN_TO_HEIRLOOM_POST = 'USER_LOGIN_TO_HEIRLOOM_POST';
export const USER_LOGIN_TO_HEIRLOOM_POST_SUCCESS = 'USER_LOGIN_TO_HEIRLOOM_POST_SUCCESS';
export const USER_LOGIN_TO_HEIRLOOM_POST_FAILED = 'USER_LOGIN_TO_HEIRLOOM_POST_FAILED';

export const HEIRLOOM_AUTH_UPDATED = 'HEIRLOOM_AUTH_UPDATED';
export const USER_LOGIN_DIALOG_UPDATED = 'USER_LOGIN_DIALOG_UPDATED';

export const IS_USER_AUTH_TO_AUTODATA_API = "IS_USER_AUTH_TO_AUTODATA_API";
export const IS_USER_AUTH_TO_HEIRLOOM_API = "IS_USER_AUTH_TO_HEIRLOOM_API";

// AUTH USER LOGOUT
export const USER_LOGOUT_POST_INIT = 'USER_LOGOUT_POST_INIT';
export const USER_LOGOUT_POST = 'USER_LOGOUT_POST';
export const USER_LOGOUT_POST_SUCCESS = 'USER_LOGOUT_POST_SUCCESS';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';


export const USER_LOGOUT_FROM_TERMINAL = 'USER_LOGOUT_FROM_TERMINAL';


// AUTH USER PASSWORD RECOVERY RESET
export const USER_PASSWORD_RESET_POST_INIT = 'USER_PASSWORD_RESET_POST_INIT';
export const USER_PASSWORD_RESET_POST = 'USER_PASSWORD_RESET_POST';
export const USER_PASSWORD_RESET_POST_SUCCESS = 'USER_PASSWORD_RESET_POST_SUCCESS';
export const USER_PASSWORD_RESET_POST_FAILED = 'USER_PASSWORD_RESET_POST_FAILED';
export const USER_PASSWORD_RESET_DIALOG_BUTTON_VISIBLE = 'USER_PASSWORD_RESET_DIALOG_BUTTON_VISIBLE';
export const USER_PASSWORD_LINK_RECOVERY_DIALOG_BUTTON_VISIBLE = 'USER_PASSWORD_LINK_RECOVERY_DIALOG_BUTTON_VISIBLE';
// AUTH USER PASSWORD RECOVERY SEND LINK
export const USER_PASSWORD_LINK_RECOVERY_POST_INIT = 'USER_PASSWORD_LINK_RECOVERY_POST_INIT';
export const USER_PASSWORD_LINK_RECOVERY_POST = 'USER_PASSWORD_LINK_RECOVERY_POST';
export const USER_PASSWORD_LINK_RECOVERY_POST_SUCCESS = 'USER_PASSWORD_LINK_RECOVERY_POST_SUCCESS';
export const USER_PASSWORD_LINK_RECOVERY_POST_FAILED = 'USER_PASSWORD_LINK_RECOVERY_POST_FAILED';


// ██████╗ ██████╗  ██████╗ ███████╗██╗██╗     ███████╗
// ██╔══██╗██╔══██╗██╔═══██╗██╔════╝██║██║     ██╔════╝
// ██████╔╝██████╔╝██║   ██║█████╗  ██║██║     █████╗  
// ██╔═══╝ ██╔══██╗██║   ██║██╔══╝  ██║██║     ██╔══╝  
// ██║     ██║  ██║╚██████╔╝██║     ██║███████╗███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚══════╝╚══════╝
//                                                   
export const USER_PROFILE_DATA_GET_INIT = 'USER_PROFILE_DATA_GET_INIT';
export const USER_PROFILE_DATA_GET = 'USER_PROFILE_DATA_GET';
export const USER_PROFILE_DATA_GET_SUCCESS = 'USER_PROFILE_DATA_GET_SUCCESS';
export const USER_PROFILE_DATA_GET_FAILED = 'USER_PROFILE_DATA_GET_FAILED';
//
export const USER_PROFILE_DATA_UPDATE_POST_INIT = 'USER_PROFILE_DATA_UPDATE_POST_INIT';
export const USER_PROFILE_DATA_UPDATE_POST = 'USER_PROFILE_DATA_UPDATE_POST';
export const USER_PROFILE_DATA_UPDATE_POST_SUCCESS = 'USER_PROFILE_DATA_UPDATE_POST_SUCCESS';
export const USER_PROFILE_DATA_UPDATE_POST_FAILED = 'USER_PROFILE_DATA_UPDATE_POST_FAILED';
export const USER_PROFILE_DATA_DIALOG_UPDATED = 'USER_PROFILE_DATA_DIALOG_UPDATED';
export const USER_PROFILE_DATA_DIALOG_SET_MSG = 'USER_PROFILE_DATA_DIALOG_SET_MSG';
export const USER_PROFILE_MEMBER_STATE_UPDATE = 'USER_PROFILE_MEMBER_STATE_UPDATE';

// ██████╗ ███████╗ ██████╗ ██╗███████╗████████╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
// ██╔══██╗██╔════╝██╔════╝ ██║██╔════╝╚══██╔══╝██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
// ██████╔╝█████╗  ██║  ███╗██║███████╗   ██║   ██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
// ██╔══██╗██╔══╝  ██║   ██║██║╚════██║   ██║   ██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
// ██║  ██║███████╗╚██████╔╝██║███████║   ██║   ██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
// ╚═╝  ╚═╝╚══════╝ ╚═════╝ ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
//
export const USER_REGISTRATION_POST_INIT = 'USER_REGISTRATION_POST_INIT';
export const USER_REGISTRATION_POST = 'USER_REGISTRATION_POST';
export const USER_REGISTRATION_POST_SUCCESS = 'USER_REGISTRATION_POST_SUCCESS';
export const USER_REGISTRATION_POST_FAILED = 'USER_REGISTRATION_POST_FAILED';

export const PASSWORD_RESET_DIALOG_CLOSED = 'PASSWORD_RESET_DIALOG_CLOSED';
export const PASSWORD_RECOVERY_DIALOG_CLOSED = 'PASSWORD_RECOVERY_DIALOG_CLOSED';
export const UPDATE_RECOVERY_DIALOG = 'UPDATE_RECOVERY_DIALOG';
export const UPDATE_RESET_DIALOG = 'UPDATE_RESET_DIALOG';

//  █████╗  ██████╗ ██████╗ ██████╗ ██╗   ██╗███╗   ██╗████████╗██╗███╗   ██╗ ██████╗ 
// ██╔══██╗██╔════╝██╔════╝██╔═══██╗██║   ██║████╗  ██║╚══██╔══╝██║████╗  ██║██╔════╝ 
// ███████║██║     ██║     ██║   ██║██║   ██║██╔██╗ ██║   ██║   ██║██╔██╗ ██║██║  ███╗
// ██╔══██║██║     ██║     ██║   ██║██║   ██║██║╚██╗██║   ██║   ██║██║╚██╗██║██║   ██║
// ██║  ██║╚██████╗╚██████╗╚██████╔╝╚██████╔╝██║ ╚████║   ██║   ██║██║ ╚████║╚██████╔╝
// ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝╚═╝  ╚═══╝ ╚═════╝ 
//                                                                                  
export const ACCOUNTING_JOB_LIST_GET_INIT = 'ACCOUNTING_JOB_LIST_GET_INIT';
export const ACCOUNTING_JOB_LIST_GET = 'ACCOUNTING_JOB_LIST_GET';
export const ACCOUNTING_JOB_LIST_GET_SUCCESS = 'ACCOUNTING_JOB_LIST_GET_SUCCESS';
export const ACCOUNTING_JOB_LIST_GET_FAILED = 'ACCOUNTING_JOB_LIST_GET_FAILED';
export const ACCOUNTING_JOB_LIST_DATA_UPDATED = 'ACCOUNTING_JOB_LIST_DATA_UPDATED';
export const ACCOUNTING_JOB_LIST_SET_DATE = 'ACCOUNTING_JOB_LIST_SET_DATE';
// CSV
export const ACCOUNTING_JOB_CSV_LIST_GET_INIT = 'ACCOUNTING_JOB_CSV_LIST_GET_INIT';
export const ACCOUNTING_JOB_CSV_LIST_GET = 'ACCOUNTING_JOB_CSV_LIST_GET';
export const ACCOUNTING_JOB_CSV_LIST_GET_SUCCESS = 'ACCOUNTING_JOB_CSV_LIST_GET_SUCCESS';
export const ACCOUNTING_JOB_CSV_LIST_GET_FAILED = 'ACCOUNTING_JOB_CSV_LIST_GET_FAILED';
//
export const ACCOUNTING_JOB_LIST_SELECTED_UPDATED = 'ACCOUNTING_JOB_LIST_SELECTED_UPDATED';
//
export const ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST_INIT = 'ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST_INIT';
export const ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST = 'ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST';
export const ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST_SUCCESS = 'ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST_SUCCESS';
export const ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST_FAILED = 'ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST_FAILED';

export const ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET_INIT = 'ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET_INIT';
export const ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET = 'ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET';
export const ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET_SUCCESS = 'ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET_SUCCESS';
export const ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET_FAILED = 'ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET_FAILED';

export const ACCOUNTING_DELETE_DIALOG_UPDATED = 'ACCOUNTING_DELETE_DIALOG_UPDATED';

// ███╗   ██╗ █████╗ ██╗   ██╗██╗ ██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
// ████╗  ██║██╔══██╗██║   ██║██║██╔════╝ ██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
// ██╔██╗ ██║███████║██║   ██║██║██║  ███╗███████║   ██║   ██║██║   ██║██╔██╗ ██║
// ██║╚██╗██║██╔══██║╚██╗ ██╔╝██║██║   ██║██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
// ██║ ╚████║██║  ██║ ╚████╔╝ ██║╚██████╔╝██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
// ╚═╝  ╚═══╝╚═╝  ╚═╝  ╚═══╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
//                                                                              
export const MENU_ITEM_GET_INIT = 'MENU_ITEM_GET_INIT';
export const MENU_ITEM_GET = 'MENU_ITEM_GET';
export const MENU_ITEM_GET_SUCCESS = 'MENU_ITEM_GET_SUCCESS';
export const MENU_ITEM_GET_FAILED = 'MENU_ITEM_GET_FAILED';
export const NAV_GET_TARGET = 'NAV_GET_TARGET';
export const NAV_SET_EXPANDED = 'NAV_SET_EXPANDED';
export const NAV_SET_SELECTED = 'NAV_SET_SELECTED';
export const NAV_SET_JSXEL = 'NAV_SET_JSXEL';

// ███████╗███████╗ █████╗ ██████╗  ██████╗██╗  ██╗
// ██╔════╝██╔════╝██╔══██╗██╔══██╗██╔════╝██║  ██║
// ███████╗█████╗  ███████║██████╔╝██║     ███████║
// ╚════██║██╔══╝  ██╔══██║██╔══██╗██║     ██╔══██║
// ███████║███████╗██║  ██║██║  ██║╚██████╗██║  ██║
// ╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝
//                                                
export const SEARCH_FILLED_STATE_UPDATED = 'SEARCH_FILLED_STATE_UPDATED';
export const SEARCH_SELECTION_OPTION_UPDATED = 'SEARCH_SELECTION_OPTION_UPDATED';
export const SEARCH_INPUT_VALUE_UPDATED = 'SEARCH_INPUT_VALUE_UPDATED';
export const SEARCH_INPUT_FOCUSED = 'SEARCH_INPUT_FOCUSED';

// ████████╗██████╗  █████╗ ███╗   ██╗███████╗ █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
// ╚══██╔══╝██╔══██╗██╔══██╗████╗  ██║██╔════╝██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
//    ██║   ██████╔╝███████║██╔██╗ ██║███████╗███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
//    ██║   ██╔══██╗██╔══██║██║╚██╗██║╚════██║██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
//    ██║   ██║  ██║██║  ██║██║ ╚████║███████║██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
//    ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
//                                                                                              
// TRANSACTION TO HEILRLOOM
export const TRANS_GET_INIT = 'TRANS_GET_INIT';
export const TRANS_GET = 'TRANS_GET';
export const TRANS_GET_SUCCESS = 'TRANS_GET_SUCCESS';
export const TRANS_GET_FINISHED = 'TRANS_GET_FINISHED';
export const TRANS_GET_FAILED = 'TRANS_GET_FAILED';
//
//export const SET_WEBSOCKET_ACTIVE = 'SET_WEBSOCKET_ACTIVE';
//
export const TRANS_FORMDATA_POST_INIT = 'TRANS_FORMDATA_POST_INIT';
export const TRANS_FORMDATA_POST = 'TRANS_FORMDATA_POST';
export const TRANS_FORMDATA_POST_SUCCESS = 'TRANS_FORMDATA_POST_SUCCESS';
export const TRANS_FORMDATA_POST_FAILED = 'TRANS_FORMDATA_POST_FAILED';

export const TRANS_FORMDATA_TO_WEBSOCKET = 'TRANS_FORMDATA_TO_WEBSOCKET';

//export const TRANS_FORMDATA_CLOSE_WEBSOCKET = 'TRANS_FORMDATA_CLOSE_WEBSOCKET';

//
export const TRANS_OMW_FORMDATA_POST_INIT = 'TRANS_OMW_FORMDATA_POST_INIT';
export const TRANS_OMW_POST_FORMDATA_INIT = 'TRANS_OMW_POST_FORMDATA_INIT';
export const TRANS_OMW_POST_FORMDATA_SUCCESS = 'TRANS_OMW_POST_FORMDATA_SUCCESS';
export const TRANS_OMW_POST_FORMDATA_FAILED = 'TRANS_OMW_POST_FORMDATA_FAILED';
//
export const UPDATE_PRESSED_KEY = 'UPDATE_PRESSED_KEY';
export const UPDATE_DIALOG_STATE = 'UPDATE_DIALOG_STATE';
//
//export const TRANS_WS_GET_INIT = 'TRANS_WS_GET_INIT';
//export const TRANS_WEBSOCKET_GET = 'TRANS_WEBSOCKET_GET';
//export const TRANS_WEBSOCKET_SET = 'TRANS_WEBSOCKET_SET';

//export const TRANS_WS_GET_SUCCESS = 'TRANS_WS_GET_SUCCESS';
//export const TRANS_WS_GET_FAILED = 'TRANS_WS_GET_FAILED';

//export const TRANS_SET_WEBSOCKET_MESSAGE = 'TRANS_SET_WEBSOCKET_MESSAGE';


//
export const TRANS_TARGET_UPDATED = 'TRANS_WS_GET_FAILED';
//
export const TRANS_UPDATE_INPUT_STATE = 'TRANS_UPDATE_INPUT_STATE';
export const TRANS_UPDATE_INPUT_ARRAY_STATE = 'TRANS_UPDATE_INPUT_ARRAY_STATE';
export const TRANS_UPDATE_INPUT_ARRAY_LINE_STATE = 'TRANS_UPDATE_INPUT_ARRAY_LINE_STATE';
//
export const TRANS_SET_INPUT_STATE = 'TRANS_SET_INPUT_STATE';
export const TRANS_SET_FOCUSED_FIELD = 'TRANS_SET_FOCUSED_FIELD';
export const TRANS_SET_CURRENT_FOCUSED_FIELD = 'TRANS_SET_CURRENT_FOCUSED_FIELD';
//
export const TRANS_SET_NO_FOCUS_ON_ANY_FIELD = 'TRANS_SET_NO_FOCUS_ON_ANY_FIELD';
//
export const TRANS_DEFAULT_SET_FOCUSED_FIELD = 'TRANS_DEFAULT_SET_FOCUSED_FIELD';
//
export const TRANS_SET_FOCUSED_ENABLED = 'TRANS_SET_FOCUSED_ENABLED';
//
export const TRANS_SET_VSE_DIALOG = 'TRANS_SET_VSE_DIALOG';
export const TRANS_SET_SHOW_HELP_CONTENT = 'TRANS_SET_SHOW_HELP_CONTENT';
//
export const TRANS_SET_SHOW_OPEN_MODAL_WINDOW = 'TRANS_SET_SHOW_OPEN_MODAL_WINDOW';

//
export const TRANS_RESET_HTML_CONTENT = 'TRANS_RESET_HTML_CONTENT';
export const TRANS_RESET_HTML_HELP_CONTENT = 'TRANS_RESET_HTML_HELP_CONTENT';
//
export const TRANS_RESET_KEY_INPUT_FIELD_BINDING = 'TRANS_RESET_KEY_INPUT_FIELD_BINDING';
export const TRANS_SET_HELP_TEXT = 'TRANS_SET_HELP_TEXT';
export const TRANS_SET_HELP_PARAMS = 'TRANS_SET_HELP_PARAMS';
//
export const TRANS_SET_EDIT_HELP_PARAMS = 'TRANS_SET_EDIT_HELP_PARAMS';
export const TRANS_SET_EDIT_HELP_DIALOG_OPEN_STATE = 'TRANS_SET_EDIT_HELP_DIALOG_OPEN_STATE';

/*
export const WEBSOCKET_SEND = 'WEBSOCKET_SEND';
export const WEBSOCKET_MESSAGE = 'WEBSOCKET_MESSAGE';
*/

export const TRANS_SET_CLICKED_BTN = 'TRANS_SET_CLICKED_BTN';

//
/*
export const CONNECTION_SUCCESS = 'CONNECTION_SUCCESS';
export const INCOMIN_EVENT = 'INCOMIN_EVENT';
export const CONNECTION_ERROR = 'CONNECTION_ERROR';
export const DISCONNECT_SUCCESS = 'DISCONNECT_SUCCESS';
*/

// ██╗  ██╗███████╗██╗     ██████╗                ██████╗ ██╗  ██╗███████╗
// ██║  ██║██╔════╝██║     ██╔══██╗              ██╔═══██╗██║  ██║██╔════╝
// ███████║█████╗  ██║     ██████╔╝    █████╗    ██║   ██║███████║█████╗  
// ██╔══██║██╔══╝  ██║     ██╔═══╝     ╚════╝    ██║   ██║██╔══██║██╔══╝  
// ██║  ██║███████╗███████╗██║                   ╚██████╔╝██║  ██║██║     
// ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝                    ╚═════╝ ╚═╝  ╚═╝╚═╝     
//
export const TRANS_HELP_GET_INIT = 'TRANS_HELP_GET_INIT';
export const TRANS_HELP_GET = 'TRANS_HELP_GET';
export const TRANS_HELP_GET_SUCCESS = 'TRANS_HELP_GET_SUCCESS';
export const TRANS_HELP_GET_FAILED = 'TRANS_HELP_GET_FAILED';
//
export const TRANS_API_HELP_GET_INIT = 'TRANS_API_HELP_GET_INIT';
export const TRANS_API_HELP_GET = 'TRANS_API_HELP_GET';
export const TRANS_API_HELP_GET_SUCCESS = 'TRANS_API_HELP_GET_SUCCESS';
export const TRANS_API_HELP_GET_FAILED = 'TRANS_API_HELP_GET_FAILED';
//
export const TRANS_API_EDIT_HELP_INIT = 'TRANS_API_EDIT_HELP_INIT';
export const TRANS_API_EDIT_HELP_POST_INIT = 'TRANS_API_EDIT_HELP_POST_INIT';
export const TRANS_API_EDIT_HELP_POST_SUCCESS = 'TRANS_API_EDIT_HELP_POST_SUCCESS';
export const TRANS_API_EDIT_HELP_POST_FAILED = 'TRANS_API_EDIT_HELP_POST_FAILED';


export const TRANS_SET_GET_IN_PROGRESS = 'TRANS_SET_GET_IN_PROGRESS';
export const TRANS_SET_POST_IN_PROGRESS = 'TRANS_SET_POST_IN_PROGRESS';
export const TRANS_SET_WS_IN_PROGRESS = 'TRANS_SET_WS_IN_PROGRESS';

export const TRANS_UPDATE_FORMDATA_FOR_BUG_REPORT = 'TRANS_UPDATE_FORMDATA_FOR_BUG_REPORT';



//  ██████╗ ██████╗ ███████╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗    
// ██╔═══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║    
// ██║   ██║██████╔╝█████╗  ██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║    
// ██║   ██║██╔═══╝ ██╔══╝  ██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║    
// ╚██████╔╝██║     ███████╗██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║    
//  ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝    

//    ██╗       ███╗   ██╗███████╗██╗    ██╗███████╗                           
//    ██║       ████╗  ██║██╔════╝██║    ██║██╔════╝                           
// ████████╗    ██╔██╗ ██║█████╗  ██║ █╗ ██║███████╗                           
// ██╔═██╔═╝    ██║╚██╗██║██╔══╝  ██║███╗██║╚════██║                           
// ██████║      ██║ ╚████║███████╗╚███╔███╔╝███████║                           
// ╚═════╝      ╚═╝  ╚═══╝╚══════╝ ╚══╝╚══╝ ╚══════╝                           

// DRIFT_MELDING
export const DRIFT_MELDING_GET_INIT = 'DRIFT_MELDING_GET_INIT';
export const DRIFT_MELDING_GET = 'DRIFT_MELDING_GET';
export const DRIFT_MELDING_GET_SUCCESS = 'DRIFT_MELDING_GET_SUCCESS';
export const DRIFT_MELDING_GET_FAILED = 'DRIFT_MELDING_GET_FAILED';
// NYHETER_MELDING
export const NYHETER_MELDING_INIT = 'NYHETER_MELDING_INIT';
export const NYHETER_MELDING_GET_INIT = 'NYHETER_MELDING_GET_INIT';
export const NYHETER_MELDING_GET_SUCCESS = 'NYHETER_MELDING_GET_SUCCESS';
export const NYHETER_MELDING_GET_FAILED = 'NYHETER_MELDING_GET_FAILED';

export const SET_CHAR_ENC = 'SET_CHAR_ENC';

export const LOG_TO_API = 'LOG_TO_API';

export const SET_RECORDING_STATE = 'SET_RECORDING_STATE';

export const UPDATE_BUG_RECORDING = 'UPDATE_BUG_RECORDING';



export const BUG_RECORDING_POST_INIT = 'BUG_RECORDING_POST_INIT';
export const BUG_RECORDING_POST = 'BUG_RECORDING_POST';
export const BUG_RECORDING_POST_SUCCESS = 'BUG_RECORDING_POST_SUCCESS';
export const BUG_RECORDING_POST_FAILED = 'BUG_RECORDING_POST_FAILED';


export const SUBMIT_REPORT = 'SUBMIT_REPORT';
export const REPORT_SUBMITTED = 'REPORT_SUBMITTED';



// ██╗   ██╗███████╗███████╗██████╗      █████╗ ██████╗ ███╗   ███╗██╗███╗   ██╗
// ██║   ██║██╔════╝██╔════╝██╔══██╗    ██╔══██╗██╔══██╗████╗ ████║██║████╗  ██║
// ██║   ██║███████╗█████╗  ██████╔╝    ███████║██║  ██║██╔████╔██║██║██╔██╗ ██║
// ██║   ██║╚════██║██╔══╝  ██╔══██╗    ██╔══██║██║  ██║██║╚██╔╝██║██║██║╚██╗██║
// ╚██████╔╝███████║███████╗██║  ██║    ██║  ██║██████╔╝██║ ╚═╝ ██║██║██║ ╚████║
//  ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝    ╚═╝  ╚═╝╚═════╝ ╚═╝     ╚═╝╚═╝╚═╝  ╚═══╝
//
export const USER_LIST_GET_INIT = 'USER_LIST_GET_INIT';
export const USER_LIST_GET = 'USER_LIST_GET';
export const USER_LIST_GET_SUCCESS = 'USER_LIST_GET_SUCCESS';
export const USER_LIST_GET_FAILED = 'USER_LIST_GET_FAILED';
export const USER_LIST_DATA_UPDATED = 'USER_LIST_DATA_UPDATED';
export const USER_LIST_SET_DATE = 'USER_LIST_SET_DATE';
//
export const USER_LIST_SELECTED_UPDATED = 'USER_LIST_SELECTED_UPDATED';
//
export const USER_LIST_SELECTED_DELETION_POST_INIT = 'USER_LIST_SELECTED_DELETION_POST_INIT';
export const USER_LIST_SELECTED_DELETION_POST = 'USER_LIST_SELECTED_DELETION_POST';
export const USER_LIST_SELECTED_DELETION_POST_SUCCESS = 'USER_LIST_SELECTED_DELETION_POST_SUCCESS';
export const USER_LIST_SELECTED_DELETION_POST_FAILED = 'USER_LIST_SELECTED_DELETION_POST_FAILED';
//
export const USER_DELETE_DIALOG_UPDATED = 'USER_DELETE_DIALOG_UPDATED';
//
export const USER_GROUP_LIST_FROM_LDAP_GET_INIT = 'USER_GROUP_LIST_FROM_LDAP_GET_INIT';
export const USER_GROUP_LIST_FROM_LDAP_GET = 'USER_GROUP_LIST_FROM_LDAP_GET';
export const USER_GROUP_LIST_FROM_LDAP_GET_SUCCESS = 'USER_GROUP_LIST_FROM_LDAP_GET_SUCCESS';
export const USER_GROUP_LIST_FROM_LDAP_GET_FAILED = 'USER_GROUP_LIST_FROM_LDAP_GET_FAILED';
export const USER_GROUP_LIST_FROM_LDAP_SET_SELECTED = 'USER_GROUP_LIST_FROM_LDAP_SET_SELECTED';
//
export const ADMIN_FIRM_LIST_FROM_DB_GET_INIT = 'ADMIN_FIRM_LIST_FROM_DB_GET_INIT';
export const ADMIN_FIRM_LIST_FROM_DB_GET = 'ADMIN_FIRM_LIST_FROM_DB_GET';
export const ADMIN_FIRM_LIST_FROM_DB_GET_SUCCESS = 'ADMIN_FIRM_LIST_FROM_DB_GET_SUCCESS';
export const ADMIN_FIRM_LIST_FROM_DB_GET_FAILED = 'ADMIN_FIRM_LIST_FROM_DB_GET_FAILED';
export const ADMIN_FIRM_LIST_FROM_DB_SET_SELECTED = 'ADMIN_FIRM_LIST_FROM_DB_SET_SELECTED';
//
export const USER_DATA_GET_INIT = 'USER_DATA_GET_INIT';
export const USER_DATA_GET = 'USER_DATA_GET';
export const USER_DATA_GET_SUCCESS = 'USER_DATA_GET_SUCCESS';
export const USER_DATA_GET_FAILED = 'USER_DATA_GET_FAILED';
//
export const USER_DATA_UPDATE_POST_INIT = 'USER_DATA_UPDATE_POST_INIT';
export const USER_DATA_UPDATE_POST = 'USER_DATA_UPDATE_POST';
export const USER_DATA_UPDATE_POST_SUCCESS = 'USER_DATA_UPDATE_POST_SUCCESS';
export const USER_DATA_UPDATE_POST_FAILED = 'USER_DATA_UPDATE_POST_FAILED';
//
export const USER_STATE_DELETE = 'USER_STATE_DELETE';
export const USER_STATE_REGISTRATION = 'USER_STATE_REGISTRATION';
export const USER_STATE_EDIT = 'USER_STATE_EDIT';
//
export const USER_LIST_EXPORT_TO_CVS_INIT = 'USER_LIST_EXPORT_TO_CVS_INIT';
export const USER_LIST_EXPORT_TO_CVS = 'USER_LIST_EXPORT_TO_CVS';
export const USER_LIST_EXPORT_TO_CVS_SUCCESS = 'USER_LIST_EXPORT_TO_CVS_SUCCESS';
export const USER_LIST_EXPORT_TO_CVS_FAILED = 'USER_LIST_EXPORT_TO_CVS_FAILED';




// ██╗   ██╗████████╗██╗██╗     ███████╗
// ██║   ██║╚══██╔══╝██║██║     ██╔════╝
// ██║   ██║   ██║   ██║██║     ███████╗
// ██║   ██║   ██║   ██║██║     ╚════██║
// ╚██████╔╝   ██║   ██║███████╗███████║
//  ╚═════╝    ╚═╝   ╚═╝╚══════╝╚══════╝
//
// PDF/EMAIL
export const PDF_GET_INIT = 'PDF_GET_INIT';
export const PDF_GET = 'PDF_GET';
export const PDF_GET_SUCCESS = 'PDF_GET_SUCCESS';
export const PDF_GET_FAILED = 'PDF_GET_FAILED';
// SUPPORT
export const SUPPORT_GET_INIT = 'SUPPORT_GET_INIT';
export const SUPPORT_GET = 'SUPPORT_GET';
export const SUPPORT_GET_SUCCESS = 'SUPPORT_GET_SUCCESS';
export const SUPPORT_GET_FAILED = 'SUPPORT_GET_FAILED';

// SUPPORT
export const AUTO_ERROR_SEND_POST_INIT = 'AUTO_ERROR_SEND_POST_INIT';
export const AUTO_ERROR_SEND_POST = 'AUTO_ERROR_SEND_POST';
export const AUTO_ERROR_SEND_POST_SUCCESS = 'AUTO_ERROR_SEND_POST_SUCCESS';
export const AUTO_ERROR_SEND_POST_FAILED = 'AUTO_ERROR_SEND_POST_FAILED';


export const AUTO_ERROR_SENT_STATE = 'AUTO_ERROR_SENT_STATE';


// ERROR
export const SET_HAS_ERROR = 'SET_HAS_ERROR';
export const SET_ERROR = 'SET_ERROR';
export const SET_ERROR_INFO = 'SET_ERROR_INFO';



// TODOS
export const GET_TODOS = 'GET_TODOS';
export const GET_TODOS_SUCCESS = 'GET_TODOS_SUCCESS';
export const GET_TODOS_FAILURE = 'GET_TODOS_FAILURE';
export const ADD_TODO = 'ADD_TODO';
export const ADD_TODO_SUCCESS = 'ADD_TODO_SUCCESS';
export const ADD_TODO_FAILRE = 'ADD_TODO_FAILRE';
export const ON_TODO_ADDED = 'ON_TODO_ADDED';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';
export const WS_MESSAGE = 'WS_MESSAGE';
export const WS_SEND_MESSAGE = 'WS_SEND_MESSAGE';

// TALLENES TALE
export const TCRIT_LIST_GET_INIT = 'TCRIT_LIST_GET_INIT';
export const TCRIT_LIST_GET = 'TCRIT_LIST_GET';
export const TCRIT_LIST_GET_SUCCESS = 'TCRIT_LIST_GET_SUCCESS';
export const TCRIT_LIST_GET_FAILED = 'TCRIT_LIST_GET_FAILED';


// FAVORITTLISTE
//REPORT
export const TFAVORITTLISTE_LIST_GET_REPORT_INIT = 'TFAVORITTLISTE_LIST_GET_REPORT_INIT';
export const TFAVORITTLISTE_LIST_GET_REPORT = 'TFAVORITTLISTE_LIST_GET_REPORT';


export const TFAVORITTLISTE_LIST_GET_REPORT_K_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_K_SUCCESS';
export const TFAVORITTLISTE_LIST_GET_REPORT_V_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_V_SUCCESS';
export const TFAVORITTLISTE_LIST_GET_REPORT_H_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_H_SUCCESS';
export const TFAVORITTLISTE_LIST_GET_REPORT_G_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_G_SUCCESS';
export const TFAVORITTLISTE_LIST_GET_REPORT_S_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_S_SUCCESS';

export const TFAVORITTLISTE_LIST_GET_REPORT_PDF_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_PDF_SUCCESS';
export const TFAVORITTLISTE_LIST_GET_REPORT_EXCEL_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_EXCEL_SUCCESS';


export const TFAVORITTLISTE_LIST_GET_REPORT_FAILED = 'TFAVORITTLISTE_LIST_GET_REPORT_FAILED';
export const TFAVORITTLISTE_LIST_GET_REPORT_STATE = 'TFAVORITTLISTE_LIST_GET_REPORT_STATE';
export const TFAVORITTLISTE_SET_VISNING_TYPE = 'TFAVORITTLISTE_SET_VISNING_TYPE';
export const TFAVORITTLISTE_SET_STATE = 'TFAVORITTLISTE_SET_STATE';

export const T_SET_SEARCH_PARAM_KUNDE = 'T_SET_SEARCH_PARAM_KUNDE';
export const T_SET_SEARCH_PARAM_HGRUPPE = 'T_SET_SEARCH_PARAM_HGRUPPE';
export const T_SET_SEARCH_PARAM_GRUPPE = 'T_SET_SEARCH_PARAM_GRUPPE';
export const T_SET_SEARCH_PARAM_VGGRUPPE = 'T_SET_SEARCH_PARAM_VGGRUPPE';
export const T_SET_SEARCH_PARAM_SGRUPPE = 'T_SET_SEARCH_PARAM_SGRUPPE';

export const TFAVORITTLISTE_LIST_GET_INIT = 'TFAVORITTLISTE_LIST_GET_INIT';
export const TFAVORITTLISTE_LIST_GET = 'TFAVORITTLISTE_LIST_GET';
export const TFAVORITTLISTE_LIST_GET_SUCCESS = 'TFAVORITTLISTE_LIST_GET_SUCCESS';
export const TFAVORITTLISTE_LIST_GET_FAILED = 'TFAVORITTLISTE_LIST_GET_FAILED';

export const TFAVORITTLISTE_LIST_SAVE_POST_INIT = 'TFAVORITTLISTE_LIST_SAVE_POST_INIT';
export const TFAVORITTLISTE_LIST_SAVE_POST = 'TFAVORITTLISTE_LIST_SAVE_POST';
export const TFAVORITTLISTE_LIST_SAVE_POST_SUCCESS = 'TFAVORITTLISTE_LIST_SAVE_POST_SUCCESS';
export const TFAVORITTLISTE_LIST_SAVE_POST_FAILED = 'TFAVORITTLISTE_LIST_SAVE_POST_FAILED';

export const TFAVORITTLISTE_LIST_DELETE_POST_INIT = 'TFAVORITTLISTE_LIST_DELETE_POST_INIT';
export const TFAVORITTLISTE_LIST_DELETE_POST = 'TFAVORITTLISTE_LIST_DELETE_POST';
export const TFAVORITTLISTE_LIST_DELETE_POST_SUCCESS = 'TFAVORITTLISTE_LIST_DELETE_POST_SUCCESS';
export const TFAVORITTLISTE_LIST_DELETE_POST_FAILED = 'TFAVORITTLISTE_LIST_DELETE_POST_FAILED';

// TALLENES TALE
export const TCRIT_LIST_GET_AS_EXCEL_INIT = 'TCRIT_LIST_GET_AS_EXCEL_INIT';
export const TCRIT_LIST_GET_AS_EXCEL = 'TCRIT_LIST_GET_AS_EXCEL';
export const TCRIT_LIST_GET_AS_EXCEL_SUCCESS = 'TCRIT_LIST_GET_AS_EXCEL_SUCCESS';
export const TCRIT_LIST_GET_AS_EXCEL_FAILED = 'TCRIT_LIST_GET_AS_EXCEL_FAILED';

//
export const TCRIT_LIST_SELECTED_UPDATED = 'TCRIT_LIST_SELECTED_UPDATED';
//
export const TCRIT_LIST_SELECTED_DELETION_POST_INIT = 'TCRIT_LIST_SELECTED_DELETION_POST_INIT';
export const TCRIT_LIST_SELECTED_DELETION_POST = 'TCRIT_LIST_SELECTED_DELETION_POST';
export const TCRIT_LIST_SELECTED_DELETION_POST_SUCCESS = 'TCRIT_LIST_SELECTED_DELETION_POST_SUCCESS';
export const TCRIT_LIST_SELECTED_DELETION_POST_FAILED = 'TCRIT_LIST_SELECTED_DELETION_POST_FAILED';
//
export const TCRIT_ITEM_UPDATED_POST_INIT = 'TCRIT_ITEM_UPDATED_POST_INIT';
export const TCRIT_ITEM_UPDATED_POST = 'TCRIT_ITEM_UPDATED_POST';
export const TCRIT_ITEM_UPDATED_POST_SUCCESS = 'TCRIT_ITEM_UPDATED_POST_SUCCESS';
export const TCRIT_ITEM_UPDATED_POST_FAILED = 'TCRIT_ITEM_UPDATED_POST_FAILED';
//
export const TCRIT_ITEM_DELETION_POST_INIT = 'TCRIT_ITEM_DELETION_POST_INIT';
export const TCRIT_ITEM_DELETION_POST = 'TCRIT_ITEM_DELETION_POST';
export const TCRIT_ITEM_DELETION_POST_SUCCESS = 'TCRIT_ITEM_DELETION_POST_SUCCESS';
export const TCRIT_ITEM_DELETION_POST_FAILED = 'TCRIT_ITEM_DELETION_POST_FAILED';
//
export const TCRIT_ITEM_REGISTRATION_POST_INIT = 'TCRIT_ITEM_REGISTRATION_POST_INIT';
export const TCRIT_ITEM_REGISTRATION_POST = 'TCRIT_ITEM_REGISTRATION_POST';
export const TCRIT_ITEM_REGISTRATION_POST_SUCCESS = 'TCRIT_ITEM_REGISTRATION_POST_SUCCESS';
export const TCRIT_ITEM_REGISTRATION_POST_FAILED = 'TCRIT_ITEM_REGISTRATION_POST_FAILED';
//
export const TCRIT_DELETION_STATE = 'TCRIT_DELETION_STATE';
export const TCRIT_UPDATED_STATE = 'TCRIT_UPDATED_STATE';
export const TCRIT_REGISTRATION_STATE = 'TCRIT_REGISTRATION_STATE';
//
export const TCRIT_LIST_EXPORT_TO_EXCELL_INIT = 'TCRIT_LIST_EXPORT_TO_EXCELL_INIT';
export const TCRIT_LIST_EXPORT_TO_EXCELL = 'TCRIT_LIST_EXPORT_TO_EXCELL';
export const TCRIT_LIST_EXPORT_TO_EXCELL_SUCCESS = 'TCRIT_LIST_EXPORT_TO_EXCELL_SUCCESS';
export const TCRIT_LIST_EXPORT_TO_EXCELL_FAILED = 'TCRIT_LIST_EXPORT_TO_EXCELL_FAILED';
//
export const TCRIT_DELETION_DIALOGUE_UPDATED = 'TCRIT_DELETION_DIALOGUE_UPDATED';

// 
export const TTALE_STATISTIC_REPORT_GET_INIT = 'TTALE_STATISTIC_REPORT_GET_INIT';
export const TTALE_STATISTIC_REPORT_GET = 'TTALE_STATISTIC_REPORT_GET';
export const TTALE_STATISTIC_REPORT_GET_SUCCESS = 'TTALE_STATISTIC_REPORT_GET_SUCCESS';
export const TTALE_STATISTIC_REPORT_GET_FAILED = 'TTALE_STATISTIC_REPORT_GET_FAILED';

// 
export const TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET_INIT = 'TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET_INIT';
export const TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET = 'TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET';
export const TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET_SUCCESS = 'TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET_SUCCESS';
export const TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET_FAILED = 'TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET_FAILED';

// 
export const TTALE_STATISTIC_REPORT_EXPORT_PDF_GET_INIT = 'TTALE_STATISTIC_REPORT_EXPORT_PDF_GET_INIT';
export const TTALE_STATISTIC_REPORT_EXPORT_PDF_GET = 'TTALE_STATISTIC_REPORT_EXPORT_PDF_GET';
export const TTALE_STATISTIC_REPORT_EXPORT_PDF_GET_SUCCESS = 'TTALE_STATISTIC_REPORT_EXPORT_PDF_GET_SUCCESS';
export const TTALE_STATISTIC_REPORT_EXPORT_PDF_GET_FAILED = 'TTALE_STATISTIC_REPORT_EXPORT_PDF_GET_FAILED';
export const TCRIT_SET_SELETED_LIST = 'TCRIT_SET_SELETED_LIST';

export const TCRIT_SET_CRITERIA_ITEM_ID = 'TCRIT_SET_CRITERIA_ITEM_ID';
export const TCRIT_FAVORITTLISTE_STATE = 'TCRIT_FAVORITTLISTE_STATE';




// PDF/EMAIL
export const EXCEL_POST_INIT = 'EXCEL_POST_INIT';
export const EXCEL_POST = 'EXCEL_POST';
export const EXCEL_POST_SUCCESS = 'EXCEL_POST_SUCCESS';
export const EXCEL_POST_FAILED = 'EXCEL_POST_FAILED';

export const EXCEL_IMPORT_STATE = 'EXCEL_IMPORT_STATE';


export const SET_SELECTED_KUNDE = 'SET_SELECTED_KUNDE';
export const SET_SELECTED_SUB_KUNDE = 'SET_SELECTED_SUB_KUNDE';

export const SET_FULL_RESET_SALESREPORT = 'SET_FULL_RESET_SALESREPORT';

export const SET_RESET_SALESREPORT = 'SET_RESET_SALESREPORT';
