// import ReactNumeric from 'react-numeric';
import { Theme, withStyles } from '@material-ui/core/styles';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import React, { Component, createRef } from 'react';
import 'react-grid-layout/css/styles.css';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { connect } from 'react-redux';
import OpenMWVitec from '../../components/OpenMWVitec';
import Utils, { programExcludeListWithF3MenyKeyDisabled, programIncludeListWithF3MenyKeyEnabled, validKey } from '../../felles_____OOO/Utils';
import HjelpFormData from '../../model/HjelpFormData';
import MenuItemTarget from '../../model/MenuItemTarget';
import NavFormData from '../../model/NavFormData';
import * as actions from '../../store/actions/index';
import { history } from '../../store/history';
import { AppState } from '../../store/rootReducer';
import MenuEl from '../../model/MenuEl';


/*
 *                                                    VSEContent
 */
/* **********************************************************************************************************/
class VSEContent extends Component<Props> {
  zForm: JSX.Element | never[] = [];
  parsedContent: string | JSX.Element[] | JSX.Element = [];
  zFormChildren: JSX.Element[] = [];
  formRef: any = createRef();
  trans = '';
  runComponentUpdate = false;
  IsF3MenuKeyEnabled = false;
  previousFieldName = '';

  constructor(props) {
    super(props);
    this.state = { clickedBtn: undefined }; // header widths
  }

  handleResize() {
    const _target: HTMLFormElement = this.formRef.current;
    if (_target?.length !== -1 && this.props && this.props?.currentFieldwithFocus && this.props?.currentFieldwithFocus?.getAttribute('name') !== null)
      this.handleFocusAssingment(this.props?.currentFieldwithFocus?.getAttribute('name'));
  }

  //Init
  componentDidMount() {
    this.handleHTMLResponseUpdate();
    this.runComponentUpdate = true;
    window.addEventListener('blur', Utils.debounce(() => { this.handleResize() }));
  }

  //Update
  componentDidUpdate() {
    if (this.props.htmlContent) {
      this.handleHTMLResponseUpdate();
    }
    if (this.props.menuItemTarget && this.props.locationPath === undefined) {
      this.props.setLocationPath(Utils.getLocationPatchFromMenuItem(this.props.menuItemTarget, this.trans, this.props.menuItems));
    }
    //
    if (this.runComponentUpdate /*!this.props.isTransGetInProgress && this.props.fieldToAttachFocus*/) {
      this.handleFocusAssingment(this.props.fieldToAttachFocus);
    }
    this.runComponentUpdate = true;
  }

  componentWillUnmount() {
    this.props.userLogoutFromTerminal();
    window.removeEventListener(
      'blur',
      Utils.debounce(() => {
        this.handleResize();
      })
    );
  }

  //
  handlerInputClicked(e: any /*, _this: VSEContent*/) {
    const _target: HTMLFormElement = this.formRef.current;
    const _input = e.target?.id || e.id;
    this.runComponentUpdate = false;
    if (_target && _input) {
      for (let i = 0; i < _target.length; i++) {
        if (_target.elements[i].getAttribute('name') === 'DFH_CURSOR') {
          _target.elements[i].setAttribute('value', _input);
        }
      }
    }
    //
    this.props.setCurrentFieldwithFocus(e.target || e);
  }

  // Utils to hangle change focus for the 1 char sized input field
  handlerKeyUpInputChanged(e: React.FormEvent<HTMLInputElement>) {
    // list of key to not to reponsd
    const excludedKey: number[] = [37, 38, 39, 40, 9, 16];
    //
    if (
      !excludedKey.includes((e as any).keyCode) &&
      e.currentTarget.size === 1 &&
      (e.target as any).value.length + '' === (e.target as any).getAttribute('size')
    ) {
      this.handleFocusAssingment((e.target as any).name);
    }
  }

  //
  handlerInputChanged(e: React.FormEvent<HTMLInputElement>) {
    (e.target as any).setAttribute('data-state', 1);
    switch (true) {
      case (e.target as any).value.length === 0:
        (e.target as any).setAttribute('data-state', 2);
        break;
      default:
        (e.target as any).setAttribute('data-state', 1);
        break;
    }
    //
    this.props.setCurrentField((e.target as any).name);
    this.props.updateInputState((e.target as any).name, (e.target as any).value);
    this.props.setSelectionEnabled(false);
    // IMPORTANT TO CHECK
    this.runComponentUpdate = false;
    if ((e.target as any).value.length + '' === (e.target as any).getAttribute('size')) {
      this.handleFocusAssingment((e.target as any).name);
    }
  }
  //
  //
  handlerOnBlurChanged = (e: React.FormEvent<HTMLInputElement>) => {
    //remove focus from the selected field
    e.currentTarget.blur();
  };
  //
  //
  handleSubmit(event) {
    event.preventDefault();
    /*
     let target = event.target;
     let form = new FormData();
     for (let i = 0; i < target.length; i++) {
       form.append(target.elements[i].getAttribute('name'), target.elements[i].value);
     }
     let formData: string = '';
     for (let i = 0; i < target.length; i++) {
       formData += '&' + target.elements[i].getAttribute('name') + '=' + target.elements[i].value;
     }
     if (!this.props.isTransPostInProgress)
       //new
       this.props.postFormData(new NavFormData(this.props.menuItemTarget.code, '', formData, this.props.termId, '', true, false));
    */
  }
  //
  handlePressedEndKey(key = null, event: any = null) {
    event?.preventDefault();
    const n = this.props.menuItemTarget;
    if (n !== undefined) {
      this.props.menuItemSetTarget(new MenuItemTarget(n.rootItem, n.item !== '' ? n.item : n.rootItem, '', '', n.tilpasset, n.hasChildren, n.title));
      this.props.menuItemSetSelected([n.rootItem, n.item + n.rootItem]);
      history.push(Utils.HOMEPAGE_CONTEXT + '/menu');
      return;
    }
  }
  //
  handlePressedHomeKey(key, event: any = null) {
    if (this.props.defaultFieldToAttachFocus === (document.activeElement as any).getAttribute('name')) {
      this.props.setSearchInputFocused(true);
      return;
    }
    this.handleFocusAssingment(this.props.defaultFieldToAttachFocus, true);
  }
  cLi = -1;
  cO = -1;
  //
  handlePressedArrowsKey(key, event: any = null) {
    let cF: any = undefined;
    const _target: any = this.formRef.current;
    this.cLi = -1;
    this.cO = -1;
    const irl: any = this.props.inputArrayLineState || {};
    let rangeSel: any = undefined;
    for (let i = 0; i < _target.length; i++) {
      if (_target.elements[i] === document.activeElement && _target.elements[i].getAttribute('class').includes('line')) {
        cF = _target.elements[i];
        rangeSel = Utils.getTextSelection(cF);
        if (irl) {
          irl.forEach((elA, m) => {
            elA.forEach((elB, n) => {
              if (elB === _target.elements[i].getAttribute('name')) {
                this.cLi = m;
                this.cO = n;
              }
            });
          });
        } else console.log('irl is undefined');
      }
    }
    //
    let inputTarget = undefined;
    switch (true) {
      case key === 'left':
        switch (true) {
          case rangeSel.start === 0 && rangeSel.end === 0:
            if (irl) {
              inputTarget =
                irl[this.cLi] && irl[this.cLi][this.cO - 1]
                  ? irl[this.cLi][this.cO - 1]
                  : irl[this.cLi - 1]
                    ? irl[this.cLi - 1][irl[this.cLi - 1].length - 1]
                    : undefined;
            }
            break;
          case rangeSel.start === 0 && rangeSel.end === cF?.value.length:
            cF.setSelectionRange(0, 0);
            break;
          case cF?.selectionStart !== 0:
            cF.setSelectionRange(cF?.selectionStart - 1, cF?.selectionStart - 1);
            break;
          default:
            break;
        }
        break;
      case key === 'tab':
        inputTarget = irl[this.cLi] && irl[this.cLi][this.cO + 1] ? irl[this.cLi][this.cO + 1] : irl[this.cLi + 1] ? irl[this.cLi + 1][0] : undefined;
        break;
      case key === 'right':
        switch (true) {
          case rangeSel.end === cF?.value.length && rangeSel.start === 0:
            cF.setSelectionRange(rangeSel.end, rangeSel.end);
            break;
          case cF?.value.length === cF?.selectionStart || Utils.getTextSelection(cF) === cF?.selectionStart:
            inputTarget = irl[this.cLi] && irl[this.cLi][this.cO + 1] ? irl[this.cLi][this.cO + 1] : irl[this.cLi + 1] ? irl[this.cLi + 1][0] : undefined;
            break;
          case cF?.selectionStart < cF?.value.length:
            cF.setSelectionRange(cF?.selectionStart + 1, cF?.selectionStart + 1);
            break;
          default:
            break;
        }
        break;
      case key === 'down':
        inputTarget = irl[this.cLi + 1][0] ? irl[this.cLi + 1][0] : undefined;
        break;
      case key === 'up':
        inputTarget =
          irl[this.cLi - 1] && irl[this.cLi - 1][this.cO]
            ? irl[this.cLi - 1][this.cO]
            : irl[this.cLi - 1]
              ? irl[this.cLi - 1][irl[this.cLi - 1].length - 1]
              : undefined;
        break;
      default:
        break;
    }
    /**/
    if (inputTarget) {
      this.props.setSelectionEnabled(true);
      this.handleFocusAssingment(inputTarget);
      this.handlerInputClicked(document.activeElement);
    }
  }

  //
  handlePressedKey(__KEY, event) {
    //
    event.preventDefault();
    //
    if (this.props.showOpenModalWindow) return;
    //
    if (__KEY === 'enter' && this.props.showHelpContent) {
      this.props.setShowHelpContent(false);
      return;
    }
    if (__KEY === 'ctrl + s' && !this.props.showHelpContent /**/) {
      Utils.giveFocusToSearchField();
      return;
    }

    // Block enter / form submit flodding.
    if (this.props.isTransPostInProgress) {
      return;
    }
    //
    if (__KEY === 'left' || __KEY === 'right' || __KEY === 'up' || __KEY === 'down' || __KEY === 'tab') {
      this.handlePressedArrowsKey(__KEY, event);
      return;
    } else if (__KEY === 'shift + home') {
      this.handlePressedHomeKey(__KEY, event);
      return;
    } else if (__KEY === 'shift + end') {
      this.handlePressedEndKey(__KEY, event);
      return;
    } else if ((__KEY === 'shift + f1' || __KEY === 'f1') && !this.props.istransVSEHelpFetchInProgress) {
      //key === 'ctrl + shift + f1' || */
      // Default
      let fieldId = '001000';
      let fieldValue = '';
      let elementId = '';
      // Cycles through all field to detec if one has the focus.
      //let isAFieldWithFocus: boolean = false;
      this.runComponentUpdate = false;
      this.props.inputArrayState?.forEach((element) => {
        if (!element.name.includes('_')) {
          const el = document.getElementById(element.id);
          if (el === document.activeElement) {
            //  isAFieldWithFocus = true;
            elementId = element.id;
          }
        }
      });
      //
      if (elementId !== '') {
        const elB = document.getElementById(elementId);
        fieldId = (elB as HTMLElement).getAttribute('id')?.toString() || '001000';
        fieldValue = (elB as HTMLElement).getAttribute('value')?.toUpperCase() || '';
      }
      this.props.setCurrentField(fieldId);
      const hD = !this.props.showHelpContent
        ? new HjelpFormData(this.trans, fieldId, fieldValue, this.props.termId?.substring(0, 4), this.props.termId?.substring(4, this.props.termId.length))
        : new HjelpFormData(this.trans, '001000', '', this.props.termId?.substring(0, 4), this.props.termId?.substring(4, this.props.termId.length));
      //
      this.props.transHelpGetSuccess('loading');
      this.props.setHelpParams(hD);
      this.props.transHelpGet(hD);
      this.props.setShowHelpContent(true);
      return;
    } else if (__KEY === 'f3' && this.props.showHelpContent) {
      this.props.setShowHelpContent(false);
      return;
    } else if (__KEY === 'f3' && !this.props.showHelpContent && this.IsF3MenuKeyEnabled) {
      this.handlePressedEndKey(__KEY, event);
      return;
    } else if (__KEY === 'ctrl + shift + f1') {
      //
      this.props.setShowOpenModalWindow(!this.props.showOpenModalWindow);
      return;
    }
    /*************** POST THE FORM ***********/
    this.submitVSEForm(__KEY);
  }

  /*
   *
   */
  submitVSEForm(key = '') {
    //
    const target: any = this.formRef.current;
    let formData = '';
    // fix for ipad or Ipad user must use Options key + '-', ... '————————-'
    for (let i = 0; i < target.length; i++) {
      const fName = target.elements[i].getAttribute('name');
      let fValue = target.elements[i].getAttribute('data-state') === '2' ? ' ' : encodeURIComponent(target.elements[i].value.toUpperCase());
      // fix for ipad or Ipad user must use Options key + '-', ... '————————-'
      fValue = fValue.replaceAll('%E2%80%94', '--');
      //
      if (fName === 'DFH_CURSOR' && this.props.currentFieldwithFocus) fValue = this.props.currentFieldwithFocus?.getAttribute('name');
      formData += (i > 0 ? '&' : '') + fName + '=' + fValue;
    }
    //
    const reg = this.props.updateKey;
    reg(key.toString().toUpperCase());
    // Avoid repeats
    this.props.postFormData(new NavFormData(this.props.menuItemTarget?.code, '', formData + Utils.getInputParamfromKeyPressed(key), this.props.termId));
    setTimeout(function () {
      reg('');
    }, 600);
  }

  submitWebSocketForm(key = '', wesocketMessageFormData: string) {
    this.props.transFormDataToWS(wesocketMessageFormData);
  }

  /****************************************************************************************************/
  /**************************************** PARSE AND RENDER VSE **************************************/
  /****************************************************************************************************/
  handleHTMLResponseUpdate() {
    const { htmlContent/*, classes*/ } = this.props;
    this.IsF3MenuKeyEnabled = false;

    //Fetching the body el as string
    if (htmlContent) {
      if (htmlContent.body.textContent?.includes('PROG.ORD1')) {
        this.handlePressedEndKey();
        return;
      }

      if (htmlContent.getElementById('buttonpanel')) {
        htmlContent.getElementById('buttonpanel')?.parentElement?.remove();
      }
      //
      const innerHTML: string = htmlContent.body.innerHTML;
      // Options for the HTML parser
      const secondPass = {
        replace: (domNode) => {
          if (!domNode.attribs) {
            return;
          }
          if (
            !Utils.isEmpty(this.props.keyBindingFieldInputStateObj) &&
            domNode.name.toString() === 'input' &&
            domNode.attribs.onfocus &&
            domNode.attribs.onfocus === 'setCurrentField(this)'
          ) {
            // Creating new Obj Attributes without onfocus and onfocusout
            const { onfocus, onfocusout, /* value,*/ ...newAttribs } = domNode.attribs;
            // assigning the new attribute obj to the node
            domNode.attribs = { ...newAttribs };
            // adding SyntheticEvent
            domNode.attribs.onClick = (e) => this.handlerInputClicked(e);
            if (this.props.keyBindingFieldInputStateObj)
              domNode.attribs.value = this.props.keyBindingFieldInputStateObj['' + domNode.attribs.name] ? this.props.keyBindingFieldInputStateObj['' + domNode.attribs.name] : '';

            //if (keyBindingFieldInputStateObj) 
            //domNode.attribs.value = keyBindingFieldInputStateObj['' + domNode.attribs.name] ? keyBindingFieldInputStateObj['' + domNode.attribs.name] : '';
            //domNode.attribs.ref = 'searchString';

            domNode.attribs.onChange = (e) => this.handlerInputChanged(e);
            domNode.attribs.onKeyUp = (e) => this.handlerKeyUpInputChanged(e);
            // domNode.attribs.onFocus = this.handlerOnFocusChanged;
            // domNode.attribs.onFocusOut = this.handlerOnFocusChanged;
            domNode.attribs.onBlur = (e) => this.handlerOnBlurChanged(e);
            // New attriv - Hack to force data deletion
            // 0 unchanged
            // 1
            // 2 empty, and set a space char for the server to delete the record.
            domNode.attribs['data-state'] = '0';
            domNode.attribs['enterkeyhint'] = 'enter';
            // Example with Numerics
            // domNode.attribs.currencySymbol="€"
            // return <ReactNumeric {...props} />;
            // assembling props
            const props = attributesToProps(domNode.attribs);
            // Return the new input node
            return <input title={domNode.attribs.name} autoComplete="off" {...props} />;
          }
          //
          else if (
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('f3=meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('f3 = meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('pf3=meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('p3=meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('f3 meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('3=meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('meny=f3') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('meny=3') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('meny=pf3') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('pf3=til meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('til meny=pf3')
          ) {
            this.IsF3MenuKeyEnabled = true;
          }
          // Check if the current programme is included to the incl. excl. list enabling redirection to the previous menu, or not.
          else if (((domNode as any).firstChild?.data as string)?.toLowerCase().includes('prog:')) {
            this.trans = (domNode as any).firstChild?.data as string;
            this.trans = this.trans?.split(':')[1];
            this.trans = this.trans?.replaceAll(' ', '');
            if (this.trans.length > 3) {
              this.trans = this.trans.substring(0, 4);
              if (programIncludeListWithF3MenyKeyEnabled.includes(this.trans)
                || programIncludeListWithF3MenyKeyEnabled.includes(this.props.menuItemTarget?.code as string)) {
                this.IsF3MenuKeyEnabled = true;

              }
              if (programExcludeListWithF3MenyKeyDisabled.includes(this.trans)) {
                this.IsF3MenuKeyEnabled = false;
              }
            }
          }
        },
      };
      // Options for the HTML parser
      const firstPass = {
        replace: (domNode) => {
          if (!domNode.attribs) {
            return;
          }
          if (domNode.name.toString() === 'div' && domNode.attribs.id === 'screenimage') {
            //domNode.attribs.class = classes.bob;
            const props = attributesToProps(domNode.attribs);
            return <div {...props}>{domToReact(domNode.children, secondPass)}</div>;
          }
        },
      };
      // Converts string to ReadtDomEl
      this.parsedContent = parse(innerHTML, firstPass);
      // isolating the form HTML element
      this.zForm = (this.parsedContent as JSX.Element[])[1];
      // isolating the children of form HTML element
      // More debug logic required
      return (this.zFormChildren = this.zForm ? this.zForm?.props?.children : [<span>Loading...</span>]);
    }
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  index = -1;
  //
  handleFocusAssingment($id = '', isLengthSelectedOnFocus = false) {
    //
    this.index = -1;
    const target: any = this.formRef.current;

    //
    if (target) {
      for (let i = 0; i < target.length; i++) {
        const objtoFocus: HTMLFormElement = target.elements[i];
        if (objtoFocus.nodeName.toString() === 'INPUT' && objtoFocus.getAttribute('id') && $id !== '') {
          if (objtoFocus.getAttribute('id') === $id) {
            objtoFocus.focus();
            // onLoad register de default field with focus
            this.props.setCurrentFieldwithFocus(objtoFocus);
            objtoFocus.select();
            if (objtoFocus.getAttribute('size') === '1') {
              objtoFocus.nodeValue = '';
            }
            //
            if (this.props.isFieldSelectEnabled === true || isLengthSelectedOnFocus === true) {
              objtoFocus.select();
              //
              if (objtoFocus.getAttribute('size') === '1') {
                this.props.inputArrayState?.forEach((element, i) => {
                  if (element.name === objtoFocus.name) {
                    this.index = i;
                  }
                });
                return;
              }
            } else {
              objtoFocus.selectionEnd = target.elements[i].value.length;
            }
            if (objtoFocus.getAttribute('size')) {
              const num = objtoFocus.getAttribute('size') || '-1';
              if (objtoFocus.value.length === parseInt(num)) {
                this.props.inputArrayState?.forEach((element, i) => {
                  if (!element.name.includes('_') && element.name === objtoFocus.name) {
                    this.index = i;
                  }
                });
                //
                if (this.props.inputArrayState !== undefined) {
                  const pInp =
                    this.props.inputArrayState[this.index + 1]?.type === 'hidden' ? this.props.inputArrayState[this.index + 2]
                      : this.props.inputArrayState[this.index + 1];
                  if (pInp && !this.props.isFieldSelectEnabled) {
                    if (!pInp.name.includes('_')) {
                      this.handleFocusAssingmentNext(pInp.name);
                    }
                  }
                }
              }
            }
          }
        }
        // Gives focus to the first visible input if value is not provided by the API
        else if ($id === '' && objtoFocus.nodeName.toString() === 'INPUT' && objtoFocus.getAttribute('id')) {
          // Gives focus
          objtoFocus.focus();
          objtoFocus.select();
          break;
        }
      }
    }
    //Gives focus to the form
    if (this.props.fieldToAttachFocus === '') {
      this.formRef.current.focus();
    }
  }

  /*
   *
   */
  handleFocusAssingmentNext($id = '') {
    const target = this.formRef.current;
    //
    for (let i = 0; i < target.length; i++) {
      const objtoFocus: HTMLFormElement = target.elements[i];
      if (objtoFocus.nodeName.toString() === 'INPUT' && objtoFocus.getAttribute('id') && $id !== '') {
        if (objtoFocus.getAttribute('id') === $id) {
          objtoFocus.focus();
          objtoFocus.select();
        }
      }
    }
  }

  /*
   *
   */
  handleSubmitOMV(e) {
    e.preventDefault();
    const target = e.target;
    let hasEl = false;
    let wsformData: string = this.props.omvFormClickedBtn ? 'BUTTON=' + this.props.omvFormClickedBtn : 'BUTTON=CLEAR';
    for (let i = 0; i < target.length; i++) {
      if (target.elements[i].type === 'text') {
        hasEl = true;
        wsformData += '|' + target.elements[i].getAttribute('name') + '=' + target.elements[i].value;
      }
    }
    // Closing statement
    wsformData += hasEl ? '|' : '';
    this.submitWebSocketForm('f5', wsformData);
    this.props.setShowOpenModalWindow(false); // TODO: Should be triggered from a sagas methode
  }

  //
  editButtonHandlerClicked() {
    const { setVSEHelpDialog, setEditHelpDialogOpenState } = this.props;
    setVSEHelpDialog(false);
    setEditHelpDialogOpenState(true);
  }

  omvPressedKey(key, event) {
    event.preventDefault();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.vseWrapper}>
        <div className={classes.vseElA}>
          <div id="section-to-print">
            {this.props.showOpenModalWindow ? (
              <OpenMWVitec omvpressedkey={(key, event) => this.omvPressedKey(key, event)} onsubmit={(e) => this.handleSubmitOMV(e)} />
            ) : null}
            <KeyboardEventHandler
              handleKeys={validKey}
              onKeyEvent={(key, e) => {
                const code = this.props.menuItemTarget?.code || 'XZX';
                if (!this.props.isTransPostInProgress && (e as any)?.code?.toString() === 'NumpadEnter'
                  && (code === 'TIL7'
                    /* || code === 'TIL6'*/
                    //|| this.trans === 'OP12'
                  )) {
                  switch (true) {
                    case (e.target as any).value !== '':
                      this.handlePressedKey(key, e);
                      break;
                    default:
                      break;
                  }
                } else this.handlePressedKey(key, e);
              }}
            >
              {this.zForm ? (
                <>
                  {' '}
                  <form
                    name={(this.zForm as any).name}
                    id={(this.zForm as any).name}
                    ref={this.formRef}
                    autoComplete="off"
                    method={'post'}
                    onSubmit={(e) => this.handleSubmit(e)}
                  >
                    <KeyboardEventHandler handleKeys={validKey} onKeyEvent={(key, e) => this.handlePressedKey(key, e)} />
                    {this.handleHTMLResponseUpdate()}{' '}
                  </form>
                </>
              ) : (
                []
              )}
            </KeyboardEventHandler>
          </div>
        </div>

        <div className={classes.vseElB}>
          <div className={classes.vseKeypad}>
            <div className={classes.FunctionKeyBlock}>
              <button onClick={(e) => this.handlePressedKey('f1', e)} className={classes.bF}>
                F1
              </button>
              <button onClick={(e) => this.handlePressedKey('f2', e)} className={classes.bF}>
                F2
              </button>
              <button onClick={(e) => this.handlePressedKey('f3', e)} className={classes.bF}>
                F3
              </button>
              <button onClick={(e) => this.handlePressedKey('f4', e)} className={classes.bF}>
                F4
              </button>
              <button onClick={(e) => this.handlePressedKey('f5', e)} className={classes.bF}>
                F5
              </button>
              <button onClick={(e) => this.handlePressedKey('f6', e)} className={classes.bF}>
                F6
              </button>
              <button onClick={(e) => this.handlePressedKey('f7', e)} className={classes.bF}>
                F7
              </button>
              <button onClick={(e) => this.handlePressedKey('f8', e)} className={classes.bF}>
                F8
              </button>
              <button onClick={(e) => this.handlePressedKey('f9', e)} className={classes.bF}>
                F9
              </button>
              <button onClick={(e) => this.handlePressedKey('f10', e)} className={classes.bF}>
                F10
              </button>
              <button onClick={(e) => this.handlePressedKey('f11', e)} className={classes.bF}>
                F11
              </button>
              <button onClick={(e) => this.handlePressedKey('f12', e)} className={classes.bF}>
                F12
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//
interface PropsPassedFromParents {
  transacId?: any;
}
//
interface StateProps {
  classes?: any;
  currentFieldwithFocus?: any;
  defaultFieldToAttachFocus?: string;
  fieldToAttachFocus?: string;
  helpContent?: any;
  htmlContent?: Document;
  inputArrayState?: any[];
  inputArrayLineState?: any[];
  isDebugEnabled?: boolean;
  isDialogOpen?: boolean;
  isF3AMenuKey?: boolean;
  isFieldSelectEnabled?: boolean;
  isInputStateInitialized?: boolean;
  isSearchFieldFocused?: boolean;
  isTransGetInProgress?: boolean;
  isTransPostInProgress?: boolean;
  istransVSEHelpFetchInProgress?: boolean;
  keyBindingFieldInputStateObj?: any;
  menuItemTarget?: MenuItemTarget;
  omvFormClickedBtn?: any;
  pressedKey?: string;
  showHelpContent?: boolean;
  showOpenModalWindow?: boolean;
  termId?: string;
  menuItems?: MenuEl[];
  locationPath: any,
}
//
const mapStateToProps = (state: AppState): StateProps => {
  return {
    currentFieldwithFocus: state.trans.currentFieldwithFocus,
    defaultFieldToAttachFocus: state.trans.defaultFieldToAttachFocus,
    fieldToAttachFocus: state.trans.fieldToAttachFocus,
    helpContent: state.trans.helpContent,
    htmlContent: state.trans.content,
    inputArrayLineState: state.trans.inputArrayLineState,
    inputArrayState: state.trans.inputArrayState,
    isDebugEnabled: state.utils.isDebugEnabled,
    isDialogOpen: state.trans.isVSEHelpDialogOpen,
    isFieldSelectEnabled: state.trans.isFieldSelectEnabled,
    isInputStateInitialized: state.trans.isInputStateInitialized,
    isSearchFieldFocused: state.menuItem.isNavFetchInProgress,
    isTransGetInProgress: state.trans.isTransGetInProgress,
    isTransPostInProgress: state.trans.isTransPostInProgress,
    istransVSEHelpFetchInProgress: state.trans.istransVSEHelpFetchInProgress,
    keyBindingFieldInputStateObj: state.trans.keyBindingFieldInputStateObj,
    menuItemTarget: state.menuItem.menuItemTarget,
    omvFormClickedBtn: state.trans.omvFormClickedBtn,
    pressedKey: state.trans.pressedKey,
    showHelpContent: state.trans.showHelpContent,
    showOpenModalWindow: state.trans.showOpenModalWindow,
    termId: state.trans.termId,
    menuItems: state.menuItem.menuItems,
    locationPath: state.ui.locationPath,
  };
};
//
interface DispatchProps {
  menuItemSetSelected: (val: any) => void;
  menuItemSetTarget: (val: any) => void;
  postFormData: (formData: NavFormData) => void;
  setCharEnc: (val: any) => void;
  setCurrentField: (val: string) => void;
  setCurrentFieldwithFocus: (val: any) => void;
  setEditHelpDialogOpenState: (val: boolean) => void;
  setHelpParams: (val: HjelpFormData) => void;
  setInputState: (state: any) => void;
  setSearchInputFocused: (val: boolean) => void;
  setSelectionEnabled: (val: boolean) => void;
  setShowHelpContent: (val: boolean) => void;
  setShowOpenModalWindow: (val: boolean) => void;
  setVSEHelpDialog: (val: boolean) => void;
  transFormDataToWS: (value: any) => void;
  transGetInit: () => void;
  transHelpGet: (val: HjelpFormData) => void;
  transHelpGetSuccess: (val: any) => void;
  updateInputState: (name: string, value: string) => void;
  updateKey: (key: string) => void;
  userLogoutFromTerminal: () => void;
  setLocationPath: (val: any) => void;
  updateRecordingLog: (val: any) => void;
}
//
const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    updateInputState: (name: string, value: string) => dispatch(actions.updateInputState(name, value)),
    setInputState: (state: any) => dispatch(actions.setInputState(state)),
    menuItemSetSelected: (val: any) => dispatch(actions.menuItemSetSelected(val)),
    menuItemSetTarget: (val: any) => dispatch(actions.menuItemSetTarget(val)),
    postFormData: (formData: NavFormData) => dispatch(actions.transFormDataPost(formData)),
    setCharEnc: (val) => dispatch(actions.setCharEnc(val)),
    setCurrentField: (val: string) => dispatch(actions.setFieldToAttachFocus(val)),
    setCurrentFieldwithFocus: (val: string) => dispatch(actions.setCurrentFieldwithFocus(val)),
    setEditHelpDialogOpenState: (val: boolean) => dispatch(actions.setEditHelpDialogOpenState(val)),
    setHelpParams: (val: HjelpFormData) => dispatch(actions.setHelpParams(val)),
    setSearchInputFocused: (val: boolean) => dispatch(actions.setSearchInputFocused(val)),
    setSelectionEnabled: (val: boolean) => dispatch(actions.setFieldSelectEnabled(val)),
    setShowHelpContent: (val: boolean) => dispatch(actions.setShowHelpContent(val)),
    setShowOpenModalWindow: (val: boolean) => dispatch(actions.setShowOpenModalWindow(val)),
    transGetInit: () => dispatch(actions.transGetInit()),
    setVSEHelpDialog: (val: boolean) => dispatch(actions.setVSEHelpDialog(val)),
    transFormDataToWS: (value: any) => dispatch(actions.transFormDataToWS(value)), // : (value: any) => void;
    transHelpGet: (val: HjelpFormData) => dispatch(actions.transHelpGet(val)),
    transHelpGetSuccess: (val: any) => dispatch(actions.transHelpGetSuccess(val)),
    updateKey: (key: string) => dispatch(actions.updatePressedKey(key)),
    userLogoutFromTerminal: () => dispatch(actions.userLogoutFromTerminal()),
    setLocationPath: (val: any) => dispatch(actions.setLocationPath(val)),
    updateRecordingLog: (val: any) => dispatch(actions.updateRecordingLog(val)),
  }
}
//
type Props = PropsPassedFromParents & StateProps & DispatchProps;

const styles = (theme: Theme) => ({
  vseWrapper: {
    display: 'flex',
    marginLeft: 40,
    zIndex: 2500,
    marginTop: '55px'
  },
  vseElA: {
    position: 'relative',
    border: 'none',
    backgroundColor: '#000',
    fontFamily: 'PT Mono, Courier New',
    fontSize: '1em',
    color: 'green',
    width: `calc(97vh * ${1.13})`,
    height: `calc(100vh  - ${90}px)`,
    '@media (min-width:0px) and (max-width:1366px)': { width: `calc(97vh * ${1.13})`, height: `calc(100vh  - ${35}px)` },
  },
  vseElB: {
    marginLeft: 40,
    visibility: 'hidden',
  },
  vseKeypad: {},
  //
  FunctionKeyBlock: {
    display: 'table',
    width: '15vh',
    height: `calc(100vh  - ${90}px)`,
  },
  //
  bF: {
    marginBottom: `calc(3.2vh  - ${6}px)`,
    background: '#222328',
    color: 'white',
    width: '100%',
    height: '5vh',
    fontSize: '1.3em',
    textTransform: 'uppercase',
    cursor: 'pointer',

    '&:hover': {
      background: '#84565d',
      color: 'white',
      transform: 'scale(1.05)',
      transitionDuration: '0.1s',
    },
  },

  /*XXS*/[theme.breakpoints.between(0, 801)]: { vseWrapper: { marginTop: '55px' }, vseElA: { height: `calc(100vh  - ${72}px)` } },
  /*XS */[theme.breakpoints.between(801, 1367)]: { vseWrapper: { marginTop: '105px' }, vseElA: { height: `calc(100vh  - ${72}px)` } },
  /*SM */[theme.breakpoints.between(1367, 1681)]: { vseWrapper: { marginTop: '55px' }, vseElA: { height: `calc(100vh  - ${72}px)` } },
  /*MD */[theme.breakpoints.between(1681, 1921)]: { vseWrapper: { marginTop: '55px' }, vseElA: { height: `calc(100vh  - ${90}px)` } },
  /*LD */[theme.breakpoints.between(1921, 2561)]: { vseWrapper: { marginTop: '55px' }, vseElA: { height: `calc(100vh  - ${90}px)` } },
  /*XL */[theme.breakpoints.between(2561, 3841)]: { vseWrapper: { marginTop: '55px' }, vseElA: { height: `calc(100vh  - ${90}px)` } },
  /*XXL*/[theme.breakpoints.between(3841, 10000)]: { vseWrapper: { marginTop: '55px' }, vseElA: { height: `calc(100vh  - ${90}px)` } },

  // Apple 10.2-inch iPad - 9. generasjon
  '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
    vseWrapper: {
      marginTop: '77px',
    },
    vseElA: {
      height: `calc(95vh  - ${94}px)`,
    },
    vseElB: {
      visibility: 'visible',
    },
    bF: {
      marginBottom: `calc(2.2vh  - ${6}px)`,
    },
  },

  // Samsung GalaxyTab
  '@media only screen and (min-width: 1205px) and (max-height: 650px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)': {
    vseWrapper: { marginLeft: '10px', marginRight: 0 },
    vseElA: { height: `calc(100vh  - ${154}px)` },
    vseElB: { width: '60px', marginLeft: 15, visibility: 'visible' },
    bF: { marginBottom: `calc(2.2vh  - ${6}px)`, width: '60px' },
  },
});

export default connect<StateProps, DispatchProps, PropsPassedFromParents, AppState>(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(VSEContent));
