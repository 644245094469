import { put } from 'redux-saga/effects';
import { fetchDriftPost, fetchNyheterPost, logToApiPost, postBugReportToSupport } from '../../felles_____OOO/X_X_X_X_X_X_X_X_APICalls';
import * as actions from '../actions';

export function* getNyheterPost() {
  try {
    yield put(actions.nyheterMeldingStarted());
    const rep: any = yield fetchNyheterPost();
    if (rep && rep.data) {
      yield put(actions.nyheterMeldingFetchSuccess(rep.data));
    }
  } catch (error) {
    yield put(actions.nyheterMeldingFetchFailed(error));
  }
}

export function* getDriftPost() {
  try {
    yield put(actions.driftMeldingStarted());
    const rep = yield fetchDriftPost();
    if (rep && rep.data) {
      yield put(actions.driftMeldingFetchSuccess(rep.data));
    }
  } catch (error) {
    yield put(actions.driftMeldingFetchFailed(error));
  }
}

export function* logToApiSaga(action) {
  try {
    yield logToApiPost(action.payload);
  } catch (error) {
    console.log('error sending log');
  }
}

export function* bugReportingPostSaga(action) {
  try {
    const resp = yield postBugReportToSupport(action.payload);
      if (resp && resp.data) {
        yield put(actions.setRecordingState(4));
      }
  } catch (error) {
    yield put(actions.setRecordingState(5));
    console.log('error sending Bug report');
  }
}


