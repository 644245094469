import fileDownload from 'js-file-download';
import { put } from 'redux-saga/effects';
import { getPDFDocument } from '../../felles_____OOO/X_X_X_X_X_X_X_X_APICalls';
import PDFFormData from '../../model/PDFFormData';
import * as actions from '../actions';
import PromiseFileReader from 'promise-file-reader';
/*
 * 
 */
export function* fecthPDFDocument(action: any) {
  yield put(actions.setIsloading(true));
  try {
    yield put(actions.pdfGetInit());
    const responseFromPost = yield getPDFDocument(action.payload);
    yield fileDownload(responseFromPost.data, (action.payload as PDFFormData).fileName);
  } catch (error) {
    const readContent = yield PromiseFileReader.readAsText((error as any).response.data);
    yield put(actions.setMsg(readContent));
    yield put(actions.setTitle('Error from PDFConverter'));
    yield put(actions.setSimpledialogOpeningState(true));
  }
  yield put(actions.setIsloading(false));
}