import { Button, CircularProgress } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Utils from "../../felles_____OOO/Utils";
import MenuEl from '../../model/MenuEl';
import UISize from "../../model/UISize";
import * as actions from '../../store/actions/index';
import { history } from '../../store/history';
import { AppState } from '../../store/rootReducer';
import LPAppBar from '../landing-page/components/LPAppBar';
import LPDrawer from '../landing-page/components/LPDrawer';
import LPDrawerHeader from '../landing-page/components/LPDrawerHeader';
import SupportContent from "./SupportContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex', height: '100vh', width: '100vw', },
    content: {
      marginTop: -10,
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }), marginLeft: -340,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }), marginLeft: 0,
    },
    backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff', },
  }),
);

export default function LPSupport(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuItems: MenuEl[] = useSelector(state => (state as AppState).menuItem.menuItems);
  const location = useLocation();
  const htmlContent: any = useSelector(state => (state as AppState).trans.content);
  const transError: any = useSelector(state => (state as AppState).trans.error);

  // const getErrorMsg = () => transError && transError.error ? transError.error : '';
  const screenSize: UISize = useSelector(state => (state as AppState).ui.screenSize);
  dispatch(actions.setDrawerState(!(screenSize.width > 0 && screenSize.width < 1200)));
  const drawerOpenState: boolean = useSelector(state => (state as AppState).ui.isDrawerOpened);

  const feedbackPostState: number = useSelector(state => (state as AppState).support.feedbackPostState);

  const handleDiagClose = () => {
    dispatch(actions.transErrorDialogUpdated(false));
    dispatch(actions.setAutoErrorSendingState(0));
    history.push(Utils.HOMEPAGE_CONTEXT + '/menu');
  }

  useEffect(() => { }, [dispatch, htmlContent, feedbackPostState, location.search, menuItems])

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={feedbackPostState === 1}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={feedbackPostState > 1 && feedbackPostState !== 0} onClose={handleDiagClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{feedbackPostState === 2 ? 'Takk for din tilbakemelding' : feedbackPostState === 3 ? 'Feil ved sending av tilbakemelding' : null}</DialogTitle>
        <DialogActions>
          <Button onClick={handleDiagClose} color="primary" autoFocus>Ok</Button>
        </DialogActions>
      </Dialog>
      <CssBaseline />
      <LPAppBar />{transError ? transError.name : ''}
      <LPDrawer />
      <main className={clsx(classes.content, { [classes.contentShift]: drawerOpenState, })}>
        <LPDrawerHeader />
        <SupportContent />{transError ? transError.name : ''}
      </main>
    </div>
  );
}
