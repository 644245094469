import jwtDecode from 'jwt-decode';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuEl from '../model/MenuEl';
import { history } from '../store/history';
import { AppState } from '../store/rootReducer';
import U from './Utils';

interface Props {
  auth: boolean;
  menuItems: MenuEl[];
}

function mapStateToProps(state: AppState) {
  return {
    auth: state.auth.authenticated,
    menuItems: state.menuItem.menuItems,
  };
}

/*
 * HOC
 */
export default (ChildComponent, role: any = undefined) => {
  class ComposedComponent extends Component<Props> {
    // Our component just got rendered
    componentDidMount() { this.shouldNavigateAway(); }

    // Our component just got updated
    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      // TODO validate token API side
      if (sessionStorage.getItem('token')) {
        const { exp, roles }: { exp: number, roles: string[] } = jwtDecode(sessionStorage.getItem('token') || '');
        const expirationTime = (exp * 1000) - 60000
        if (!this.props.auth || sessionStorage.getItem('token') == null || Date.now() >= expirationTime) {
          history.push(U.HOMEPAGE_CONTEXT + '/home');
        }
        //        
        if (role && this.props.menuItems.length > 0) {
          const lookup = U.getTransactionsRole(this.props.menuItems, role);
          if (lookup) {
            if (!roles.includes(lookup)) {
              history.push(U.HOMEPAGE_CONTEXT + '/menu');
            }
          } else {
            history.push(U.HOMEPAGE_CONTEXT + '/menu');
          }
        }
      } else {
        history.push(U.HOMEPAGE_CONTEXT + '/home');
      }
    }
    //
    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps)(ComposedComponent);
};