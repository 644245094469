import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from 'react-redux';
import Utils from "../../../felles_____OOO/Utils";
import { AppState } from "../../../store/rootReducer";
//



let rows = [
  { id: "navn", numeric: false, disablePadding: false, label: "Varegruppe - Navn", width: '14%' },
  //{ id: "produktsjef", numeric: false, disablePadding: false, label: "Produktsjef", width: '10%' },
  //{ id: "innkjop", numeric: false, disablePadding: false, label: "Innkjøp", width: '10%' },
  { id: "hovedgruppe", numeric: false, disablePadding: false, label: "Hovedgruppe", width: '10%' },
  { id: "gruppe", numeric: false, disablePadding: false, label: "Gruppe", width: '12%' },
  { id: "summeringsgruppe", numeric: false, disablePadding: false, label: "Sum.gruppe", width: '12%' },
  //{ id: "totalsummering", numeric: false, disablePadding: false, label: "Totalsum.", width: '12%' },
  { id: "Handlinger", numeric: false, disablePadding: false, label: "Handlinger", width: '12%' },
];




class CriteriaListTableHead extends Component<Props> {
  //  


  //Init
  componentDidMount() {
    if (!Utils.isDesktop)
      rows = rows.filter(obj => obj.id !== 'produktsjef' && obj.id !== 'innkjop');
  }

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };
  //
  render() {
    const { classes } = this.props;
    //
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" className={classes.tableCell} >
            <Checkbox
              indeterminate={this.props.selectedCriteriaItemList?.length > 0 && this.props.selectedCriteriaItemList?.length < this.props.rowCount}
              checked={this.props.selectedCriteriaItemList?.length === this.props.rowCount}
              onChange={this.props.onSelectAllClick}
              style={{ width: 10 }}
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell className={classes.tableCell}
                key={row.id}
                align="left"
                padding="none"
                sortDirection={(this.props.orderBy === row.id ? (this.props.order === "desc" ? "desc" : "asc") : false!)/**/}
                style={{ minWidth: row.width, maxWidth: row.width }}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  {row.id !== 'Handlinger' ? <TableSortLabel
                    active={this.props.orderBy === row.id}
                    direction={(this.props.order === "desc" ? "desc" : "asc")}
                    onClick={this.createSortHandler(row.id)}
                  >{row.label}</TableSortLabel> : <>{row.label + ''}</>}
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}


const styles = theme => ({
  tableCell: {  /*border:'4px solid red',*/ marginLeft: -5, padding: 0, textAlign: 'left', },
});



interface OwnProps {
  numSelected: number;
  order?: string;
  orderBy?: string;
  onSelectAllClick: any;
  rowCount: number;
  onRequestSort: (event: any, property: any) => void;
}

interface StateProps {
  selectedCriteriaItemList: number[];
  classes: any;
}

type Props = OwnProps & StateProps/* & DispatchProps*/;

export function mapStateToProps(state: AppState): StateProps {
  return {
    selectedCriteriaItemList: state.tallenesTale.selectedCriteriaItemList,
    classes: {},
  };
}

export default connect<StateProps, undefined, OwnProps, AppState>(mapStateToProps, null)(withStyles(styles as any)(CriteriaListTableHead));



