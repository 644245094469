import { CircularProgress, Toolbar } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { Theme, withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import 'react-grid-layout/css/styles.css';
import { connect } from 'react-redux';
import ButtonVitec from '../../../components/ButtonVitec';
import SVGIconBin from "../../../components/SVGIconBin";
import SVGIconEdit from "../../../components/SVGIconEdit";
import Utils from "../../../felles_____OOO/Utils";
import LdapUser from '../../../model/LdapUser';
import MenuItemTarget from '../../../model/MenuItemTarget';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';
import UserDelete from "./UserDelete";
import UserEdit from "./UserEdit";
import UserListTableToolbar from "./UserListTableToolbar";
import UserListTableHead from "./UserListTableHead";




interface Props {
  onRequestSort: (event: any, property: any) => void;
  onRequestSelected: (Selected: any[]) => void;
  onCompactTypeChange: (val: any) => void;
  onLayoutChange: (val: any) => void;
  getUserList: () => void;
  userListExportToCVS: () => void;
  getUserLDAPData: (val: string) => void;
  setUserEditState: (val: number) => void;
  setUserDeletionState: (val: number) => void;
  setSandwichButtonStateClicked: (val: boolean) => void;
  menuItemTarget: MenuItemTarget;
  classes: any;
  userDataList: LdapUser[];
  selectedUserList: any[];
  isFetchingUserList: boolean;
  userEditState: number;
  userDeletionState: number;
  userJWT: any,
  currentBreakpoint: string;
}
//
const mapStateToProps = (state: AppState) => {
  return {
    menuItemTarget: state.menuItem.menuItemTarget,
    userDataList: state.userAdmin.userDataList,
    isFetchingUserList: state.userAdmin.isFetchingUserList,
    selectedUserList: state.userAdmin.selectedUserList,
    userEditState: state.userAdmin.userEditState,
    userDeletionState: state.userAdmin.userDeletionState,
    userJWT: state.auth.userJWT,
    currentBreakpoint: state.ui.currentBreakpoint,
  }
}
//
const mapDispatchToProps = (dispatch: any) => {
  return {
    getUserList: () => dispatch(actions.userListFetch()),
    userListExportToCVS: () => dispatch(actions.userListExportToCVS()),
    getUserLDAPData: (val: string) => dispatch(actions.userDataFetched(val)),
    setUserEditState: (val: number) => dispatch(actions.setUserEditState(val)),
    setUserDeletionState: (val: number) => dispatch(actions.setUserDeletionState(val)),
    onRequestSelected: (Selected: string[]) => dispatch(actions.userListSelectedUpdated(Selected)),
    setSandwichButtonStateClicked: (val: boolean) => dispatch(actions.setSandwichButtonStateClicked(val)),
  }
}



/********************************************************************************/

class UserList extends Component<Props> {
  //
  state = {
    order: "asc",
    orderBy: "username",
    page: 0,
    rowsPerPage: -1 //10
  };

  //Init
  componentDidMount() {
    document.body.removeEventListener('touchmove', Utils.preventDefault);
    this.props.getUserList();
    this.props.setSandwichButtonStateClicked(false);
    // dispatch(actions.setSandwichButtonStateClicked(false));
    // for the tablet IPAD / GALAX

  }

  //
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "asc";
    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }

    this.setState({ order, orderBy });
  };
  //
  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.props.onRequestSelected(this.props.userDataList.map(n => n.username));
      return;
    }
    this.props.onRequestSelected([]);
  };
  //
  handleCheckboxClick = (event, username: string) => {
    event.stopPropagation();
    const selectedIndex = this.props.selectedUserList.indexOf(username as string);
    //
    let newSelectedList: string[] = [];
    if (selectedIndex === -1) {
      newSelectedList = newSelectedList.concat(this.props.selectedUserList, username);
    } else if (selectedIndex === 0) {
      newSelectedList = newSelectedList.concat(this.props.selectedUserList.slice(1));
    } else if (selectedIndex === this.props.selectedUserList.length - 1) {
      newSelectedList = newSelectedList.concat(this.props.selectedUserList.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedList = newSelectedList.concat(
        this.props.selectedUserList.slice(0, selectedIndex),
        this.props.selectedUserList.slice(selectedIndex + 1)
      );
    }
    this.props.onRequestSelected(newSelectedList);
  };
  //
  handleSingleDelete = (event, username: string) => {
    event.stopPropagation();
    const newSelectedList: string[] = [];
    newSelectedList.push(username)
    this.props.onRequestSelected(newSelectedList);
    this.props.setUserDeletionState(1);
  };
  //
  handleChangePage = (event, page) => { this.setState({ page }); };
  //
  handleChangeRowsPerPage = event => { this.setState({ rowsPerPage: event.target.value }); };
  //
  handleUserEdit = (event, val: string) => { this.props.setUserEditState(1); this.props.getUserLDAPData(val); };
  //
  handleUserListDeletion = (event, val: string) => { this.props.setUserDeletionState(1); };
  //
  isSelected = (username: string) => this.props.selectedUserList.indexOf(username) !== -1;
  //
  generateEditStates = () => {
    const { userEditState } = this.props;
    switch (true) {
      case (userEditState === 0):
        return null;
      case (userEditState === 1):
        return <UserEdit />;
      case (userEditState === 2):
        return <UserEdit />;
      default:
        return null;
    }
  }

  sendRequest = () => {
    this.props.userListExportToCVS();
  }

  openLink = () => {
    const newWindow = window.open('https://vitec-template.azurewebsites.net/media/t30jcrkx/programkodeforklaring-auto-online.pdf', '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  //
  generateDeletionStates = () => {
    const { userDeletionState } = this.props;
    switch (true) {
      case (userDeletionState === 0):
        return null;
      case (userDeletionState === 1):
        return <UserDelete />;
      case (userDeletionState === 2):
        return <UserDelete />;
      case (userDeletionState === 3):
        return <UserDelete />;
      default:
        return null;
    }
  }


  render() {
    const { classes } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.props.userDataList.length - page * rowsPerPage);
    //
    return (
      <div className={classes.root}>
        <Paper>
          <div>
            <UserListTableToolbar />
            <TableContainer className={classes.container}>
              <Backdrop className={classes.backdrop} open={this.props.isFetchingUserList}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <div className={classes.tableWrapper}>
                <Table stickyHeader className={classes.table} size="small" aria-labelledby="tableTitle">
                  <UserListTableHead
                    numSelected={this.props.selectedUserList?.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this.handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.props.userDataList.length}
                  />
                  <TableBody>
                    {this.props.userDataList ? Utils.stableSort(this.props.userDataList, Utils.getSorting(order, orderBy))
                      .map((n: LdapUser) => {
                        const isSelected = this.isSelected(n.username as string);
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={n.username}
                            selected={isSelected}
                          >
                            <TableCell className="selectCheckbox" padding="checkbox">
                              <Checkbox onClick={event => this.handleCheckboxClick(event, n.username)} className="selectCheckbox" checked={isSelected} />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.username}</TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.firstname}</TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.lastname}</TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.email}</TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.operatorId}</TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.lastLogonTimestamp}</TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none"
                            >
                              {n.username !== this.props.userJWT.sub ? <div className={classes.iconWrapper}><div className={classes.locallinkA} onClick={event => this.handleUserEdit(event, n.username)}>
                                <SVGIconEdit title="Edit" width={22} />
                              </div><div className={classes.locallinkB} onClick={event => this.handleSingleDelete(event, n.username)}>  <SVGIconBin width={22} /></div></div> : null}
                            </TableCell>
                          </TableRow>
                        );
                      }) : null}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}><TableCell colSpan={9} /></TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </div>
          <Toolbar>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <ButtonVitec className={classes.button} variant="contained" _onclick={() => this.openLink()} _type={'button'} _width={230} _label={'Programkodeforklaring'} />
            </div>&nbsp;&nbsp;
            <div className={classes.actions}>
              <ButtonVitec className={classes.button} variant="contained" _onclick={() => this.sendRequest()} _type={'button'} _width={150} _label={'Export (.xlsx)'} />
            </div>
          </Toolbar>
        </Paper>
        {/*this.props.userDeletionState*/}
        {this.generateEditStates()}
        {this.generateDeletionStates()}
      </div>
    );
  }
}

const styles = (theme: Theme) => ({
  root: {
    marginTop: 45,
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 0,
    width: `calc(100% - ${40}px)`,//1900, //props.size.width + 'px',
    '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
      paddingTop: 0,
      marginTop: 8,

    },
  },

  backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff', },
  container: { height: `calc(100vh - ${230}px)`, /* border: '3px solid red'*/ },
  paper: { width: '100vw', marginTop: 30, },
  table: {
    minWidth: 700,
    fontSize: 200,
  },
  tableCell: { padding: 4, width: '20px', },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  locallinkA: { cursor: 'pointer', paddingTop: 6, },
  locallinkB: { cursor: 'pointer', paddingTop: 4, paddingLeft: 14, paddingRight: 14, },
  button: { width: 150, marginRight: theme.spacing(1) },
  spacer: { flex: "1 1 100%" },
  actions: { color: theme.palette.text.secondary },



  // Apple 10.2-inch iPad Wi-Fi + Cellular - 9. generasjon
  //'@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
  //  container: { height: `calc(98vh - ${395}px)`, /* border: '3px solid red'*/},
  //},

  //Samsung GalaxyTab
  '@media only screen and (min-width: 1205px) and (max-height: 650px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)': {
    container: { height: `calc(90vh - ${213}px)` },
  },

  iconWrapper: {
    display: 'flex'
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(UserList));
