import { Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiTreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Utils from '../../../felles_____OOO/Utils';
import MenuEl from '../../../model/MenuEl';
import MenuItemTarget from '../../../model/MenuItemTarget';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';
// import C from '../../../felles_____OOO/UtilsCSS';

//
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    color: '#fff',
    bottom: 0,
    left: 0,
  },
  labelRoot: { display: 'flex', alignItems: 'center', padding: theme.spacing(0.5, 0) },
  labelIcon: { height: 35, marginRight: theme.spacing(1), width: 25 },
  labelText: { height: 35, flexGrow: 1 },
}));
//
const TreItem = withStyles((theme) => ({
  root: {
    '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      color: '#666',
      borderRadius: 4,
      backgroundColor: '#f3f3f3 !important',
      marginRight: 12,
    },
  },
  content: {
    flexDirection: 'row-reverse',
    height: '3.7vh',
    //Samsung GalaxyTab
    '@media only screen and (min-width: 1205px) and (max-height: 650px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)': {
      height: '22px',
    }
  },
  //
  label: {
    lineHeight: '3.1vh',
    paddingLeft: 12,
    fontSize: '2.1vh',
    height: '3.1vh',
    /*XXS*/[theme.breakpoints.between(0, 801)]: { lineHeight: '2em', paddingLeft: 12, fontSize: '1em', height: '2em', },
    /*XS */[theme.breakpoints.between(801, 1367)]: { lineHeight: '2em', paddingLeft: 12, fontSize: '1.1em', height: '2em', },
    /*SM */[theme.breakpoints.between(1367, 1681)]: { lineHeight: '2em', paddingLeft: 12, fontSize: '1.2em', height: '2em', },
    /*MD */[theme.breakpoints.between(1681, 1921)]: { lineHeight: '2em', paddingLeft: 12, fontSize: '1.3em', height: '2em', },
    /*LD */[theme.breakpoints.between(1921, 2561)]: { lineHeight: '2em', paddingLeft: 12, fontSize: '1.4em', height: '2em', },
    /*XL */[theme.breakpoints.between(2561, 3841)]: { lineHeight: '2em', paddingLeft: 12, fontSize: '1.6em', height: '2em', },
    /*XXL*/[theme.breakpoints.between(3841, 10000)]: { lineHeight: '2em', paddingLeft: 12, fontSize: '1.6em', height: '2em', },
    //Samsung galaxy
    '@media only screen and (min-width: 1205px) and (max-height: 650px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)': {
      height: '30px',
      lineHeight: '30px',
    }
  },
  iconContainer: {
    transform: 'scale(2)',
  },
}))(MuiTreeItem);


/*
 *
 *
 */
export default function LPNavMenuTree() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuItems: MenuEl[] = useSelector((state) => (state as AppState).menuItem.menuItems);
  const expanded: string[] = useSelector((state) => (state as AppState).menuItem.expanded);
  const selected: string[] = useSelector((state) => (state as AppState).menuItem.selected);
  const JSXItem: any[] = useSelector((state) => (state as AppState).menuItem.JSXItemElemments);
  const history = useHistory();
  const _isLogged: number = useSelector((state) => (state as AppState).auth.isLogged);
  // const userJWT = useSelector((state) => (state as AppState).auth.userJWT);

  /*
   * Build the menu items subnodes
   */
  const renderMenuItems = (item: MenuEl) => {
    const JSXSubItemElemments: any = [];
    if (item.nodes.length > 0 /* && item.heading !== ''*/)
      item.nodes.forEach((subItem: MenuEl) => {
        if (subItem.heading !== '') {
          JSXSubItemElemments.push(
            <StyledTreeItem
              key={'mIt' + subItem.name}
              nodeId={subItem.name + '' + subItem.rootName}
              onClick={(event) => hChange(event, subItem.rootName, subItem.name + '' + subItem.rootName, subItem.name, subItem.tilpasset, subItem.hasChildren, subItem.description)}
              label={subItem.name}
            />
          );
        }
      });
    return JSXSubItemElemments;
  };

  /*
   * Build the menu items parent nodes
   */
  const renderRootMenuItems = () => {
    const JSXItemEl: any = [];
    if (menuItems && menuItems.length > 0) {
      menuItems.forEach((item: MenuEl, i) => {
        if (item.nodes.length > 0) {
          // Root node with child
          JSXItemEl.push(
            <StyledTreeItem key={'rch' + i} nodeId={item.rootName} onClick={(event) => hRootClicked(event, item)} label={item.name}>
              {item.heading !== '' ? renderMenuItems(item) : []}
            </StyledTreeItem>
          );
        } else {
          // Root with no child
          JSXItemEl.push(<StyledTreeItem key={'rot' + i} nodeId={item.rootName} onClick={(event) => hRootClicked(event, item)} label={item.name} />);
        }
      });
    }
    //
    return JSXItemEl;
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    console.log(_isLogged);
    
    if (menuItems.length === 0 && _isLogged > 0) {
      dispatch(actions.menuItemGet());
    } // else { }
  }, [dispatch/*,renderRootMenuItems, roles*/, menuItems, _isLogged/*, JSXItem*/]);


  if (JSXItem.length === 0 && menuItems.length) {
    dispatch(actions.menuItemSetJSXItemElements(renderRootMenuItems()));
  }

  /*
   *
   */
  function StyledTreeItem(props) {
    const { labelText } = props;
    //
    return (
      <TreItem
        label={
          <div className={classes.labelRoot}>
            <div className={classes.labelIcon} />
            <Typography variant="h1" className={classes.labelText}>
              {labelText}
            </Typography>
          </div>
        }
        classes={{ content: classes.root, }}
        {...props}
      />
    );
  }

  /*
   *
   */
  function hRootClicked(event, item: MenuEl) {
    event.stopPropagation();
    event.preventDefault();
    if (expanded.indexOf(item.rootName) > -1) {
      dispatch(actions.menuItemSetExpanded([]));
    } else {
      dispatch(actions.menuItemSetExpanded([item.rootName]));
    }
    if (item.hasChildren && item.nodes[0]) {
      dispatch(actions.menuItemSetTarget(new MenuItemTarget(item.rootName, item.nodes[0].name, '', '', item.nodes[0].tilpasset, item.hasChildren, item.description)));
      dispatch(actions.menuItemSetSelected([item.rootName, item.nodes[0].name + item.rootName]));
    } else {
      dispatch(actions.menuItemSetTarget(new MenuItemTarget(item.rootName, item.rootName, '', '', item.tilpasset, item.hasChildren, item.description)));
      dispatch(actions.menuItemSetSelected([item.rootName]));
    }
    dispatch(actions.setLocationPath(<>{item.rootName} {item.nodes.length > 1 ? ' > ' + item.nodes[0].name : null}</>));
    history.push(Utils.HOMEPAGE_CONTEXT + '/menu');
  }

  /*
   * Handle subclicked menu items - dispatch selections and set selected
   */
  function hChange(event, rootMenuItem, menuItem = '', nodeId = '', tilpasset, hasChildren: boolean, title: string) {
    const menuItemTarget = new MenuItemTarget(rootMenuItem, nodeId, '', '', tilpasset, hasChildren, title);
    event.stopPropagation();
    event.preventDefault();
    dispatch(actions.menuItemSetTarget(menuItemTarget));
    dispatch(actions.menuItemSetSelected([rootMenuItem, menuItem]));
    dispatch(actions.setLocationPath(<>{rootMenuItem + ' > ' + nodeId}</>));
    history.push(Utils.HOMEPAGE_CONTEXT + '/menu');
  }

  /*
   *
   */
  return (
    <TreeView
      expanded={expanded}
      selected={selected}
      key="thTr"
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {JSXItem && JSXItem.length > 0 ? JSXItem : <div>Loading menu ...</div>}
    </TreeView>
  );
}
