import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import U from '../../../felles_____OOO/Utils';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { width: '100%' },
    margin: { height: theme.spacing(3) },
    gridItem: {
      display: 'flex',
      borderTop: '1px solid  #c6c6c6',
      padding: '18px 0',
      cursor: 'pointer',
      '&:hover': { backgroundColor: '#f3f3f3' },
      '&:first-child': { borderTop: '0px solid' },

      /* XXS */[theme.breakpoints.between(0, 801)]: { fontSize: '.8em' },
      /* XS  */[theme.breakpoints.between(801, 1367)]: { fontSize: '.8em' },
      /* SM  */[theme.breakpoints.between(1367, 1681)]: { fontSize: '1em' },
      /* MD  */[theme.breakpoints.between(1681, 1921)]: { fontSize: '1em' },
      /* LD  */[theme.breakpoints.between(1921, 2561)]: { fontSize: '1em' },
      /* XL  */[theme.breakpoints.between(2561, 3841)]: { fontSize: '1.1em' },
      /* XXL */[theme.breakpoints.between(3841, 10000)]: { fontSize: '1.2em' },
    },
    gridItemDate: { color: '#333' },
    gridItemTitle: { paddingLeft: '10px', color: '#961932' },
  })
);

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
  numitems: number;
}

export default function CNyheter(props) {
  const classes = useStyles();
  const LINK_SITE = 'https://www.vitec-autosystemer.com';
  const dispatch = useDispatch();
  const nyheterContent: any = useSelector((state) => (state as AppState).utils.nyheterContent);
  const isNyheterContentLoading: any = useSelector((state) => (state as AppState).utils.isNyheterContentLoading);

  useEffect(() => {
    if (!nyheterContent && !isNyheterContentLoading) {
      dispatch(actions.nyheterMeldingFetchStarted());
    }
  }, [dispatch, nyheterContent, isNyheterContentLoading]);

  function handleHTMLResponseUpdate() {
    if (nyheterContent) {
      const _nyhetPost: any[] = [];
      (nyheterContent?.listitems as Array<any>)?.sort((a: any, b: any) => {
        return new Date(b.sortdate).getTime() - new Date(a.sortdate).getTime();
      }).forEach((item: any, i) => {
          _nyhetPost.push(
            <div key={U.I} className={classes.gridItem} onClick={(e) => handleClick(e, item.url)}>
              <div className={classes.gridItemDate}>{item.sortdate.substring(0, 10)}</div>
              <div className={classes.gridItemTitle}>{item.heading}</div>
            </div>
          );
        });
      return _nyhetPost;
    }
  }

  const handleClick = (event: any, l: any) => {
    event.preventDefault();
    const newWindow = window.open(LINK_SITE + '' + l, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  //
  return <div className={classes.root}>{handleHTMLResponseUpdate() || 'Loading...'}</div>;
}
