import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import AccountingJobPage from '../containers/accounting/AccountingJobPage';
import PageAddUser from '../containers/admin-user/PageAddUser';
import PageUserList from '../containers/admin-user/PageUserList';
import Home from '../containers/home/Home';
import LandingPage from '../containers/landing-page/LandingPage';
import Logout from '../containers/logout/Logout';
import PasswordLinkRecovery from '../containers/password/PasswordLinkRecovery';
import PasswordUpdate from '../containers/password/PasswordUpdate';
import PDFForm from '../containers/pdf/PDFForm';
import LPSupport from '../containers/support/_LPSupport';
import CriteriaItemPage from '../containers/tallenes/CriteriaItemPage';
import TallenesTalePage from '../containers/tallenes/TallenesTalePage';
import LPVSE from '../containers/vse/_LPVSE';
import U from './Utils';
import RequireAuth from './requireAuth';
import PageProfil from '../containers/admin-user/PageProfil';



const getRoutes = () => {
  const renderRootMenuItems = () => {
    const JSXItemEl: any = [];
    let i = 0;
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/account`} component={RequireAuth(AccountingJobPage, 'ACC1')} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/home`} component={Home} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/logout`} component={Logout} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/login.html`} component={Home} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/login`} component={Home} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/logout`} component={Home} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/menu`} component={RequireAuth(LandingPage)} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/vse`} component={RequireAuth(LPVSE)} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/support`} component={RequireAuth(LPSupport)} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/passwordlinkrecovery`} component={PasswordLinkRecovery} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/passwordupdate`} component={PasswordUpdate} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/pdf`} component={PDFForm} />);
    // Gives access to 31 users' roles
    if ((U?.T_roles as string[])?.includes('ROLE_31') || (U?.T_roles as string[])?.includes('ROLE_32')) {
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/SS10`} component={RequireAuth(TallenesTalePage)} />);
    }
    if ((U?.T_roles as string[])?.includes('ROLE_32')) {
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/SS11`} component={RequireAuth(CriteriaItemPage)} />);
    }
    if ((U?.T_roles as string[])?.includes('ROLE_ADMIN')) {
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/BR00`} component={RequireAuth(PageUserList)} />);
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/BR01`} component={RequireAuth(PageAddUser)} />);
    }
    // Else is handled by the server API - When ADMIN, MINIADMIN is removed
    if ((U?.T_roles as string[])?.includes('ROLE_MINIADMIN')) {
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/BR02`} component={RequireAuth(PageUserList)} />);
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/BR03`} component={RequireAuth(PageAddUser)} />);
    }
    // Gives access to 22 users' roles
    if ((U?.T_roles as string[])?.includes('ROLE_22')) {
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/ACC1`} component={RequireAuth(AccountingJobPage, 'ACC1')} />);
    }

    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/BR04`} component={RequireAuth(PageProfil)} />);
    // Redirect to menu when no match found
    JSXItemEl.push(<Redirect exact key={i++} from={'*'} to={`${U.HOMEPAGE_CONTEXT}/menu`} />);
    //
    return JSXItemEl;
  };
  return <Switch>{renderRootMenuItems()}</Switch>;
}

export default getRoutes;
