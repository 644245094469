import { Popper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import LogoSym from '../../../components/SVGLogoSym';
import Utils from '../../../felles_____OOO/Utils';
import U from '../../../felles_____OOO/Utils';
import MenuEl from '../../../model/MenuEl';
import MenuItemTarget from '../../../model/MenuItemTarget';
import SearchSel from '../../../model/SearchSel';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiAutocomplete-option[data-focus="true"]': {
        background: 'white',
      },
    },
    customTooltip: {
      display: 'float',
      right: -50,
      // I used the rgba color for the standard "secondary" color
      backgroundColor: '#fff',
      fontSize: '1em',
      color: 'black',
      border: '1px solid #fff',
      margin: 0,
      marginTop: -5,
      marginLeft: 20,
    },
    customArrow: {
      color: ' #fff',
    },
    root: {
      backgroundColor: '#945f67',
      marginBottom: 27,
      height: 45,
      display: 'flex',
      '& label': { height: 45, textAlign: 'center', color: '#945f67' },
      '& label.Mui-focused': { animationIterationCount: 1, transform: 'translate(0, -10px) scale(1)', fontSize: '.85em' },
      '& .MuiInputLabel-formControl': { top: 15, left: 5, position: 'absolute', transform: 'translate(0, 0) scale(1)' },
      '& .MuiAutocomplete-popupIndicator': { left: 5 },
      '& .MuiInput-underline': {
        width: 210,
        height: 45,
        marginTop: 8,
        marginleft: 7,
        '&::before': { border: '0px' },
        '&::after': { border: '0px', height: 45 },
        '&:hover': { borderBottom: `0px`, height: 45 },
      },
      '& .MuiInput-underline:after': { height: 45, width: 0 },
    },

    rootSelected: {
      backgroundColor: '#945f67',
      marginBottom: 32,
      height: 45,
      display: 'flex',
      '& label': {
        height: 45,
        textAlign: 'center',
        color: '#945f67',
        animationIterationCount: 1,
        transform: 'translate(-50px, -100px) scale(1)',
        fontSize: '.85em',
      },
      '& .MuiAutocomplete-popupIndicator': { left: -33 },
      '& .MuiInput-root ': { height: 35, width: '250px !important' },
      '& .MuiInputLabel-formControl': { top: 8, left: 5, position: 'absolute', transform: 'translate(0, 0) scale(1)' },
      '& .MuiInput-underline': {
        width: 210,
        height: 45,
        marginTop: 8,
        marginleft: 7,
        '&::before': { border: '0px' },
        '&::after': { border: '0px', height: 45 },
        '&:hover': { borderBottom: `0px`, height: 45 },
      },
      '& .MuiInput-underline:after': { height: 45, width: 0 },
    },

    search: {
      paddingLeft: 5,
      paddingRight: 5,
      margint: 'auto',
      width: 100,
      height: 45,
      backgroundColor: '#fff',
      flex: 1,
      textAlign: 'center',
    },
    iconButton: { padding: 6, marginLeft: 2, backgroundColor: '#945f67', color: '#fff' },
    buttonRoot: { width: '100%', display: 'inline-block', textAlign: 'left' },
    buttonCode: { fontWeight: 600, color: '#945f67' },
    buttonDesc: { color: '#000', marginLeft: 5, fontSize: '.9em' },
    buttonHeading: { textAlign: 'left', color: '#000', marginLeft: 5, fontSize: '.9em', verticalAlign: 'bottom' },
    path: { display: 'inline-block', color: '#000', width: 500, fontSize: '.9em' },
    rootName: { color: '#243367' },
    parentName: {},
    headName: { color: '#945f67' },

    /*
    /*XXS[theme.breakpoints.between(0, 801)]: { root: { marginBottom: 10 } },
    /*XS [theme.breakpoints.between(801, 1367)]: { root: { marginBottom: 10 } },
    /*SM [theme.breakpoints.between(1367, 1681)]: { root: { marginBottom: 10 } },
    /*MD [theme.breakpoints.between(1681, 1921)]: { root: { marginBottom: 10 } },
    /*LD [theme.breakpoints.between(1921, 2561)]: { root: { marginBottom: 10 } },
    /*XL [theme.breakpoints.between(2561, 3841)]: { root: { marginBottom: 10 } },
    /*XXL[theme.breakpoints.between(3841, 10000)]: { root: { marginBottom: 10 } },*/
  })
);

const PopperMy = function (props) {
  return <Popper {...props} style={{ width: 550 }} placement="bottom-start" />;
};

export default function LPNavigationSearch() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedSearchOption: SearchSel = useSelector((state) => (state as AppState).search.searchSelectionOptions);
  const inputValue: string = useSelector((state) => (state as AppState).search.searchInputValue);
  let isFilled: boolean = useSelector((state) => (state as AppState).search.isSearchInputPopulated);
  const menuItems: MenuEl[] = useSelector((state) => (state as AppState).menuItem.menuItems);
  const [searchVal, setSearchVal] = useState('');
  const isSearchFieldFocused: boolean = useSelector((state) => (state as AppState).search.isSearchFieldFocused);
  const formRef: any = createRef();
  const isTransGetInProgress: boolean = useSelector((state) => (state as AppState).trans.isTransGetInProgress);

  //
  useEffect(() => {
    if (isSearchFieldFocused) {
      const _target = formRef.current?.childNodes[0]?.childNodes[1]?.childNodes[0];
      if (_target) {
        _target.focus();
      }
    }
  }, [isSearchFieldFocused, formRef, isTransGetInProgress]);
  //
  const handleHomeClick = () => {
    // Refreshes news and operations feed
    dispatch(actions.nyheterMeldingFetchStarted());
    dispatch(actions.driftMeldingFetchStarted());
    //
    dispatch(actions.menuItemSetTarget(new MenuItemTarget()));
    dispatch(actions.setSearchSelectionOptions(new SearchSel()));
    dispatch(actions.setSearchInputPopulatedState(false));
    dispatch(actions.menuItemSetExpanded([undefined]));
    dispatch(actions.menuItemSetSelected([undefined, undefined]));
    history.push(Utils.HOMEPAGE_CONTEXT + '/menu');
  };
  //
  function handleRightClick(e, data) {
    const clickX = e.clientX;
    const clickY = e.clientY;
    dispatch(actions.setContextMenuVisible(true));
    dispatch(actions.setContexMenuX(clickX));
    dispatch(actions.setContexMenuY(clickY));
    dispatch(actions.setTransidRedirect(data.code));/**/
    dispatch(actions.setTilpasset(data.tilpasset));/**/
  }
  //
  const handleSearchClick = (e: any) => {
    dispatch(actions.setSandwichButtonStateClicked(false));
    // Prevent form submit
    e?.preventDefault();
    e?.stopPropagation();
    // require a min two char
    if (searchVal?.length > 2) {
      if (searchVal === 'ACC1' || searchVal === 'ACC' || searchVal === 'AC') {
        history.push(Utils.HOMEPAGE_CONTEXT + '/account');
      } else {
        if (searchVal.length >= 2) {
          for (const element of menuOptions()) {
            if (element.code.includes(searchVal)) {
              if (element.tilpasset === 1) {
                history.push(Utils.HOMEPAGE_CONTEXT + '/' + element.code);
              } else if (!isTransGetInProgress) {
                dispatch(actions.transGet(new MenuItemTarget(element.menuItem.rootName, element.menuItem.parentName, element.menuItem.name, element.code, element.menuItem.tilpasset, false, element.menuItem.description)));
              }
              dispatch(actions.menuItemSetExpanded([element.menuItem.rootName]));
              dispatch(actions.menuItemSetSelected([element.menuItem.rootName, element.menuItem.parentName]));
              break;
            }
          }
        }
      }
      // Clean up
      setSearchVal('');
      dispatch(actions.setSearchInputFocused(false));
      dispatch(actions.setSearchInputValue('')); // To check
      dispatch(actions.setSearchSelectionOptions({}));
    }
  };

  //
  function menuOptions() {
    const options: SearchSel[] = [];
    if (menuItems && menuItems.length > 0) {
      menuItems.forEach((item: MenuEl) => {
        item.nodes.forEach((subItem: MenuEl) => {
          subItem.nodes.forEach((last: MenuEl) => {
            last.nodes.forEach((lst: MenuEl) => {
              options.push({ code: lst.code, tilpasset: +lst.tilpasset, name: lst.description, indexing: lst.code + ' ' + lst.description, menuItem: lst } as SearchSel);
            });
          });
        });
      });
    }
    return options;
  }

  return (
    <Paper component="form" className={!isFilled ? classes.root : classes.rootSelected}>
      <IconButton className={classes.iconButton} aria-label="menu" onClick={handleHomeClick}>
        <NavLink to={U.HOMEPAGE_CONTEXT + '/menu'}>
          {' '}
          <LogoSym width="20px" />
        </NavLink>
      </IconButton>
      <Autocomplete
        open={isSearchFieldFocused}
        PopperComponent={PopperMy}
        value={selectedSearchOption}
        closeIcon={false}
        id="code-select"
        ref={formRef}
        noOptionsText={'Ingen alternativer funnet.'}
        clearOnEscape={true}
        clearText={'clear'}
        options={menuOptions().sort((a, b) => -b.indexing.localeCompare(a.indexing))}
        onChange={(event: any, val: any) => {
          if (val) {
            setSearchVal(val);
            handleSearchClick(event);
          }
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter' /* && !isTransGetInProgress*/) {
            dispatch(actions.setSearchInputFocused(false));
            handleSearchClick(e);
          } else dispatch(actions.setSearchInputFocused(true));
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          dispatch(actions.setSearchInputValue(newInputValue));
          if (newInputValue.length > 0) dispatch(actions.setSearchInputPopulatedState(true));
        }}
        className={classes.search}
        onFocus={() => {
          dispatch(actions.setSearchInputFocused(true));
        }}
        onBlur={(event) => {
          dispatch(actions.setSearchInputFocused(false));
        }}
        autoHighlight
        getOptionLabel={(option: any) => option.indexing || ''}
        getOptionSelected={(option: any, value: any) => {
          return value.fieldType === option.fieldType;
        }}
        onHighlightChange={(event, newInputValue) => {
          setSearchVal(newInputValue?.code || '');
        }}
        filterOptions={(options, s) => {
          const _result: SearchSel[] = [];
          // first pass searches code
          options.forEach((element) => {
            if (element.code.startsWith(s.inputValue.toUpperCase())) {
              _result.push(element);
            }
          });
          // second pass searches and add indexing
          options.forEach((element: SearchSel) => {
            if (!_result.includes(element) && element.indexing.toLowerCase().includes(s.inputValue.toLowerCase())) {
              if (!s.inputValue.toLowerCase().startsWith(element.code.toLowerCase())) _result.push(element);
            }
          });
          return _result;
        }}
        renderOption={(s: SearchSel, i) => {
          return (
            <div
              className={classes.buttonRoot}
              onContextMenu={(event) =>
                handleRightClick(
                  event,
                  new MenuItemTarget(
                    s.menuItem.rootName,
                    s.menuItem.parentName,
                    s.menuItem.name,
                    s.menuItem.code,
		 s.menuItem.tilpasset,
                    s.menuItem.hasChildren,
                    s.menuItem.description
                  )
                )
              }
            >
              <span className={classes.buttonCode}>
                {s.code}
                <span className={classes.buttonDesc}>{s.name}</span>{' '}
              </span>
              <div className={classes.path}>
                {s.menuItem.rootName ? <span className={classes.rootName}>{s.menuItem.rootName}</span> : null}
                {s.menuItem.parentName ? (
                  <span className={classes.parentName}>
                    {' '}
                    {'>'} {s.menuItem.parentName}
                  </span>
                ) : null}
                {s.menuItem.heading ? (
                  <span className={classes.headName}>
                    {' '}
                    {'>'} {s.menuItem.heading}
                  </span>
                ) : null}
              </div>
            </div>
          );
        }}
        renderInput={(params) => (
          <Tooltip
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
            title={'CTRL + S'}
            placement={'right'}
            arrow
          >
            <TextField {...params} label="Søk i Online" inputProps={{ ...params.inputProps, autoComplete: 'off' }} />
          </Tooltip>
        )}
      />
      <IconButton className={classes.iconButton} aria-label="search" onClick={handleSearchClick}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
