import { put } from 'redux-saga/effects';
import { postFeedbackToSupport } from '../../felles_____OOO/X_X_X_X_X_X_X_X_APICalls';
import * as actions from '../actions';
/*
 * 
 */
export function* sagaSendFeedbackToSupport(action: any) {
  yield put(actions.setAutoErrorSendingState(1));
  try {
    yield put(actions.sendFeedbackToSupportPostInitiated());
    const responseFromPost = yield postFeedbackToSupport(action.payload);
    yield put(actions.sendFeedbackToSupportPostSuccess(responseFromPost.data));
    yield put(actions.setAutoErrorSendingState(2));
  } catch (error) {
    yield put(actions.sendFeedbackToSupportPostFailed(error));
    yield put(actions.setAutoErrorSendingState(3));
  }
}