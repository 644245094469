import PassParam from '../../model/PassParams';
import UserCred from '../../model/UserCred';
import * as actionTypes from './actionTypes';

// LOGIN

export const userLoginToAutodataGetInit = () => {
  return { type: actionTypes.USER_LOGIN_TO_AUTODATA_GET_INIT };
};

export const userLoginToAutodataGet = (userCred: UserCred) => {
  return { type: actionTypes.USER_LOGIN_TO_AUTODATA_GET_AUTH, userCred };
};

export const userLoginToAutodataGetSuccess = () => {
  return { type: actionTypes.USER_LOGIN_TO_AUTODATA_GET_SUCCESS };
};

export const userLoginToAutodataGetFailed = (error: any) => {
  return { type: actionTypes.USER_LOGIN_TO_AUTODATA_GET_FAILED, error };
};

/********************************************************************************** */
/********************************************************************************** */
/********************************************************************************** */
/********************************************************************************** */
/********************************************************************************** */
/********************************************************************************** */

export const userAutologinToAutodataAuthGetInit = () => {
  return { type: actionTypes.USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET_INIT };
};

export const userAutologinToAutodataAuthGet = (token: string, transId = '', performSilent = false, loginState = 0) => {
  return { type: actionTypes.USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET, token, transId, performSilent, loginState};
};

export const userAutologinToAutodataAuthGetSuccess = () => {
  return { type: actionTypes.USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET_SUCCESS };
};

export const userAutologinToAutodataAuthGetFailed = (error: any) => {
  return { type: actionTypes.USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET_FAILED, error };
};

export const setSessionUIState = (payload: any) => {
  return { type: actionTypes.SET_SESSION_AS_TABBED, payload };
};

export const setSessionAsParent = () => {
  return { type: actionTypes.SET_SESSION_AS_PARENT };
};

export const setHasSibling = (payload: any) => {
  return { type: actionTypes.SET_HAS_SIBLING, payload };
};

export const setIsAlreadyLoggedFromAnotherTab = (payload: any) => {
  return { type: actionTypes.SET_IS_ALREADY_LOGGED_FROM_ANOTHER_TAB, payload };
};

export const setTabListSize = (payload: any) => {
  return { type: actionTypes.SET_TABLIST_SIZE, payload };
};

export const setTabAsActive = (payload: any) => {
  return { type: actionTypes.SET_TAB_AS_ACTIVE, payload };
};


export const setTabId = (payload: any) => {
  return { type: actionTypes.SET_TAB_ID, payload };
};

export const setIsLogged = (payload: any) => {
  return { type: actionTypes.SET_IS_LOGGED, payload };
};

export const setQTRState = (payload: any) => {
  return { type: actionTypes.SET_QTR_STATE, payload };
};

//export const setHasLoggedOut = (payload: any) => {
//  return { type: actionTypes.SET_HAS_LOGGED_OUT, payload };
//};

/**/
export const setInitialRedirectURL = (payload: string) => {
  //
  return { type: actionTypes.SET_INITIAL_REDIRECT_URL, payload };
};

/********************************************************************************** */
/********************************************************************************** */
/********************************************************************************** */
/********************************************************************************** */
/********************************************************************************** */
/************************************************************************** */

export const userLogingToHeirloomPostInit = () => {
  return { type: actionTypes.USER_LOGIN_TO_HEIRLOOM_INIT };
};

export const UserLoginToHeirloomFetchValidation = (userCred: UserCred) => {
  return { type: actionTypes.USER_LOGIN_TO_AUTODATA_GET_AUTH, userCred };
};

export const userLoginToHeirloomPostSuccess = () => {
  return { type: actionTypes.USER_LOGIN_TO_AUTODATA_GET_SUCCESS };
};

export const userLoginToHeirloomPostFailed = (error: any) => {
  return { type: actionTypes.USER_LOGIN_TO_AUTODATA_GET_FAILED, error };
};

export const heirloomAuthUpdated = (userCred: UserCred) => {
  return { type: actionTypes.HEIRLOOM_AUTH_UPDATED, userCred };
};

export const setLoginDialogOpeningState = (payload: boolean) => {
  return { type: actionTypes.USER_LOGIN_DIALOG_UPDATED, payload };
};

export const setUserAuthenticatedAgainstAutodataAPI = (payload: any) => {
  return { type: actionTypes.IS_USER_AUTH_TO_AUTODATA_API, payload };
};

export const setUserAuthenticatedAgainstHeirloomAPI = (payload: any) => {
  return { type: actionTypes.IS_USER_AUTH_TO_HEIRLOOM_API, payload };
};

// LOGOUT
export const userLogoutStarted = () => {
  return { type: actionTypes.USER_LOGOUT_POST_INIT };
};



// LOGOUT
export const userLogoutFromTerminal = () => {
  return { type: actionTypes.USER_LOGOUT_FROM_TERMINAL };
};


export const UserLogoutFetchValidation = (
  userCred: UserCred,
  performSilent = false,
  loginState = 0,
  isAlreadyLoggedFromAnotherTab = false,
  tabListSize = 0,
  activeTab = false
) => {
  return { type: actionTypes.USER_LOGOUT_POST, userCred, performSilent, loginState, isAlreadyLoggedFromAnotherTab, tabListSize, activeTab };
};

export const userLogoutSuccess = () => {
  return { type: actionTypes.USER_LOGOUT_POST_SUCCESS };
};

export const userLogoutFailed = (error: any) => {
  return { type: actionTypes.USER_LOGOUT_FAILED, error };
};

// AUTH USER PASSWORD RECOVERY

export const userPasswordResetStarted = () => {
  return { type: actionTypes.USER_PASSWORD_RESET_POST_INIT };
};

export const userPasswordResetFetch = (passParam: PassParam) => {
  return { type: actionTypes.USER_PASSWORD_RESET_POST, passParam };
};

export const userPasswordResetPostSuccess = () => {
  return { type: actionTypes.USER_PASSWORD_RESET_POST_SUCCESS };
};

export const userPasswordResetPostFailed = (error: any) => {
  return { type: actionTypes.USER_PASSWORD_RESET_POST_FAILED, error };
};

export const setUserPasswordResetDialogButtonVisible = (payload: any) => {
  return { type: actionTypes.USER_PASSWORD_RESET_DIALOG_BUTTON_VISIBLE, payload };
};

export const setPasswordLinkRecoveryDialogButtonVisible = (payload: any) => {
  return { type: actionTypes.USER_PASSWORD_LINK_RECOVERY_DIALOG_BUTTON_VISIBLE, payload };
};

// AUTH USER PASSWORD RESET

export const userPasswordLinkRecoveryPostInit = () => {
  return { type: actionTypes.USER_PASSWORD_LINK_RECOVERY_POST_INIT };
};

export const userPasswordLinkRecoveryFetch = (userCred: UserCred) => {
  return { type: actionTypes.USER_PASSWORD_LINK_RECOVERY_POST, userCred };
};

export const userPasswordLinkRecoveryPostSuccess = () => {
  return { type: actionTypes.USER_PASSWORD_LINK_RECOVERY_POST_SUCCESS };
};

export const userPasswordLinkRecoveryPostFailed = (error: any) => {
  return { type: actionTypes.USER_PASSWORD_LINK_RECOVERY_POST_FAILED, error };
};

// USER REGISTRATION

export const userRegistrationStarted = () => {
  return { type: actionTypes.USER_REGISTRATION_POST_INIT };
};

export const userRegistrationFetch = (userCred: UserCred) => {
  return { type: actionTypes.USER_REGISTRATION_POST, userCred };
};

export const userRegistrationFetchSuccess = () => {
  return { type: actionTypes.USER_REGISTRATION_POST_SUCCESS };
};

export const userRegistrationFetchFailed = (error: any) => {
  return { type: actionTypes.USER_REGISTRATION_POST_FAILED, error };
};

export const passwordResetDialogClosed = (payload: any) => {
  return { type: actionTypes.PASSWORD_RESET_DIALOG_CLOSED, payload };
};

export const passwordLinkRecoveryDialogClosed = (payload: any) => {
  return { type: actionTypes.PASSWORD_RECOVERY_DIALOG_CLOSED, payload };
};

export const updateResetDialog = (payload: any) => {
  return { type: actionTypes.UPDATE_RESET_DIALOG, payload };
};

export const updateRecoveryDialog = (payload: any) => {
  return { type: actionTypes.UPDATE_RECOVERY_DIALOG, payload };
};

export const updateAutodataUserAuth = (payload: any) => {
  return { type: actionTypes.AUTODATA_AUTH_USER, payload };
};

export const updateAutodataUserJWT = (payload: any) => {
  return { type: actionTypes.AUTODATA_USER_JWT_UPDATED, payload };
};
