import U from '../../felles_____OOO/Utils';
import MenuEl from '../../model/MenuEl';
import MenuItemTarget from '../../model/MenuItemTarget';
import * as actionTypes from '../actions/actionTypes';
import * as fromApp from '../rootReducer';

export interface FeatureState extends fromApp.AppState {
  state: StateMenuItem;
}

export interface StateMenuItem {
  menuItems: MenuEl[];
  menuItemTarget: MenuItemTarget;
  isNavFetchInProgress: boolean;
  error: any;
  expanded: string[];
  selected: string;
  JSXItemElemments: any[];
}

const initialState = {
  menuItems: [],
  menuItemTarget: new MenuItemTarget(''),
  isNavFetchInProgress: false,
  error: undefined,
  expanded: [],
  selected: 'undefined',
  JSXItemElemments: [],
};

const menuItemGetInit = (state: StateMenuItem) => {
  return U.updateStore(state, { isNavFetchInProgress: true });
}
const menuItemGetSuccess = (state: StateMenuItem, action: any) => {
  return U.updateStore(state, { isNavFetchInProgress: false, menuItems: U.assembleMenuItemList(action.payload) });
}
const menuItemGetFailed = (state: StateMenuItem, action: any) => {
  return U.updateStore(state, { isNavFetchInProgress: false, error: action });
}

const menuItemSetTarget = (state: StateMenuItem, action: any) => {
  return U.updateStore(state, { menuItemTarget: action.menuItemTarget });
}


const navSetExpanded = (state: StateMenuItem, action: any) => {
  return U.updateStore(state, { expanded: action.payload });
}

const menuItemSetSelected = (state: StateMenuItem, action: any) => {
  return U.updateStore(state, { selected: action.payload });
}

const navSetJSXItemElemments = (state: StateMenuItem, action: any) => {
  return U.updateStore(state, { JSXItemElemments: action.payload });
}


const menuItemReducer = (state = initialState, action: any = '') => {
  switch (action.type) {
    case actionTypes.MENU_ITEM_GET_INIT:
      return menuItemGetInit(state);
    case actionTypes.MENU_ITEM_GET_SUCCESS:
      return menuItemGetSuccess(state, action);
    case actionTypes.MENU_ITEM_GET_FAILED:
      return menuItemGetFailed(state, action);
    case actionTypes.NAV_GET_TARGET:
      return menuItemSetTarget(state, action);
    case actionTypes.NAV_SET_EXPANDED:
      return navSetExpanded(state, action);
    case actionTypes.NAV_SET_SELECTED:
      return menuItemSetSelected(state, action);
    case actionTypes.NAV_SET_JSXEL:
      return navSetJSXItemElemments(state, action);
    case actionTypes.DESTROY_SESSION:
      return initialState;
    default:
      return state;
  }
};

export default menuItemReducer;
