import U from '../../felles_____OOO/Utils';
import UserCred from '../../model/UserCred';
import * as actionTypes from '../actions/actionTypes';
import * as fromApp from '../rootReducer';
import jwt from 'jwt-decode';

export interface FeatureState extends fromApp.AppState {
  state: StateAuthCred;
}

export interface StateAuthCred {
  // autodata-api
  isUserAuthenticatedAgainstAutodataAPI: boolean;
  isAutodataLoginInProgress: boolean;
  // heirlooms-api
  isUserAuthenticatedAgainstHeirloomAPI: boolean;
  isHeirloomLoginInProgress: boolean;

  userCred: UserCred;
  redirectPath: string;
  initialRedirectURL: string;

  isLoginDialogueOpen: boolean;

  isPasswordResetDialogOpen: boolean;
  isPasswordLinkRecoveryDialogOpen: boolean;

  feedbackLinkRecoveryDialog: any[];
  feedbackResetDialog: any[];

  isFeedbackResetCloseButtonDialogVisible: boolean;
  isFeedbackLinkRecoveryCloseButtonDialogVisible: boolean;

  isRecoveryLinkSentSuccessfully: boolean;
  isPasswordChangedSuccessfully: boolean;

  isRedirectResetDialogEnabled: boolean;
  isRedirectLinkRecoveryDialogEnabled: boolean;
  authenticated: boolean;
  userJWT: any; // should be a type

  sessionState: number;
  isSessionParent: number;

  hasSibling: boolean;
  isLogged: number;
  stateQTR: number;
  //hasLoggedOut: number;
  timeStamp: string;
  // Common
  error: any;
  isAlreadyLoggedFromAnotherTab: boolean;
  tabListSize: number;
  activeTab: boolean,
  tabId: string,
}

const initialState = {
  // autodata-api
  isUserAuthenticatedAgainstAutodataAPI: false,
  // heirlooms-api
  isUserAuthenticatedAgainstHeirloomAPI: false,
  userCred: new UserCred('Guest', '', '', false),
  redirectPath: '/',
  initialRedirectURL: '',
  isAutodataLoginInProgress: false,
  isHeirloomLoginInProgress: false,
  isLoginDialogueOpen: false,
  isPasswordResetDialogOpen: true,
  isPasswordLinkRecoveryDialogOpen: true,
  feedbackLinkRecoveryDialog: [],
  feedbackResetDialog: [],
  isFeedbackResetCloseButtonDialogVisible: true,
  isFeedbackLinkRecoveryCloseButtonDialogVisible: true,
  isRecoveryLinkSentSuccessfully: false,
  isPasswordChangedSuccessfully: false,
  isRedirectResetDialogEnabled: false,
  isRedirectLinkRecoveryDialogEnabled: false,
  authenticated: false,
  userJWT: undefined,
  error: undefined,
  sessionState: -1,
  isSessionParent: -1,
  hasSibling: false,
  isLogged: 0,
  stateQTR: 0,
  //hasLoggedOut: 0,
  timeStamp: new Date().getTime().toString() /*+ Math.random()*/,
  isAlreadyLoggedFromAnotherTab: false,
  tabListSize: 0,
  activeTab: false,
  tabId: '',
};

const reInitialisedState = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { ...initialState, isLogged: action.loginState, isAlreadyLoggedFromAnotherTab: action.isAlreadyLoggedFromAnotherTab, tabListSize: action.tabListSize, activeTab: action.activeTab });
};


const passwordResetDialogClosed = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { isPasswordResetDialogOpen: action.payload });
};
const userLoginToAutodataGetInit = (state: StateAuthCred) => U.updateStore(state, { isAutodataLoginInProgress: true });
const userLoginToAutodataGet = (state: StateAuthCred, action: any) => U.updateStore(state, { userCred: action.userCred });
const userLoginToAutodataGetSuccess = (state: StateAuthCred) => U.updateStore(state, { isAutodataLoginInProgress: false });
const userLoginToAutodataGetFailed = (state: StateAuthCred, action: any) => U.updateStore(state, { isAutodataLoginInProgress: false, error: action });
const userAutologinToAutodataAuthGetInit = (state: StateAuthCred) => U.updateStore(state, { isAutodataLoginInProgress: true });
const userAutologinToAutodataAuthGet = (state: StateAuthCred, action: any) => U.updateStore(state, { userCred: action.userCred });
const userAutologinToAutodataAuthGetSuccess = (state: StateAuthCred) => U.updateStore(state, { isAutodataLoginInProgress: false });
const userAutologinToAutodataAuthGetFailed = (state: StateAuthCred, action: any) => U.updateStore(state, { isAutodataLoginInProgress: false, error: action });
const userLogingToHeirloomPostInit = (state: StateAuthCred) => U.updateStore(state, { isHeirloomLoginInProgress: true });
const UserLoginToHeirloomFetchValidation = () => console.log('Called UserLoginToHeirloomFetchValidation');
const userLoginToHeirloomPostSuccess = (state: StateAuthCred) => U.updateStore(state, { isHeirloomLoginInProgress: false });
const userLoginToHeirloomPostFailed = (state: StateAuthCred, action: any) => U.updateStore(state, { isHeirloomLoginInProgress: false, error: action });
const heirloomAuthUpdated = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { isLoginInProgress: false, userCred: action.userCred });
};

const setLoginDialogOpeningState = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { isLoginDialogueOpen: action.payload });
};

const passwordLinkRecoveryDialogClosed = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { isPasswordLinkRecoveryDialogOpen: action.payload });
};

const updateResetDialog = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { feedbackResetDialog: action.payload });
};

const updateRecoveryDialog = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { feedbackLinkRecoveryDialog: action.payload });
};

const setSessionUIState = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { sessionState: action.payload });
};

const setSessionAsParent = (state: StateAuthCred) => {
  return U.updateStore(state, { isSessionParent: state.isSessionParent + 1 });
};

const setInitialRedirectURL = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { initialRedirectURL: action.payload });
};

const setHasSibling = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { hasSibling: action.payload });
};

const setIsLogged = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { isLogged: action.payload, stateQTR: U.T_QTR });
};

const setQTRState = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { stateQTR: action.payload });
};

const userPasswordResetPostSuccess = (state: StateAuthCred) => {
  return U.updateStore(state, {
    isPasswordChangedSuccessfully: true,
    isFeedbackResetCloseButtonDialogVisible: true,
    isRedirectResetDialogEnabled: true,
    feedbackResetDialog: U.feedBackTextElement('pass_updated'),
  });
};

const userPasswordResetPostFailed = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, {
    isPasswordChangedSuccessfully: false,
    isRedirectResetDialogEnabled: false,
    isFeedbackResetCloseButtonDialogVisible: true,
    feedbackResetDialog: U.feedBackTextElement(action.error.toString()),
  });
};

const setUserPasswordResetDialogButtonVisible = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { isFeedbackResetCloseButtonDialogVisible: action.payload });
};

const userPasswordResetPostInit = (state: StateAuthCred) => {
  return U.updateStore(state, { isRedirectResetDialogEnabled: false });
};

const userPasswordLinkRecoveryPostInit = (state: StateAuthCred) => {
  return U.updateStore(state, { isRecoveryLinkSentSuccessfully: false, isRedirectLinkRecoveryDialogEnabled: false });
};

const userPasswordLinkRecoveryPostSuccess = (state: StateAuthCred) => {
  return U.updateStore(state, {
    isFeedbackLinkRecoveryCloseButtonDialogVisible: true,
    isRedirectLinkRecoveryDialogEnabled: true,
    isRecoveryLinkSentSuccessfully: true,
    feedbackLinkRecoveryDialog: ['Vi har nå sendt deg instruksjoner om hvordan du lager et nytt passord til din e-postadresse.'],
  });
};

const userPasswordLinkRecoveryPostFailed = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, {
    isFeedbackLinkRecoveryCloseButtonDialogVisible: true,
    isRedirectLinkRecoveryDialogEnabled: false,
    isRecoveryLinkSentSuccessfully: false,
    feedbackLinkRecoveryDialog: U.feedBackTextElement(action.error.toString()),
  });
};

const setPasswordLinkRecoveryDialogButtonVisible = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { isFeedbackLinkRecoveryCloseButtonDialogVisible: action.payload });
};

const setUserAuthenticatedAgainstAutodataAPI = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { isUserAuthenticatedAgainstAutodataAPI: action.payload });
};

const setUserAuthenticatedAgainstHeirloomAPI = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { isUserAuthenticatedAgainstHeirloomAPI: action.payload });
};


const setIsAlreadyLoggedFromAnotherTab = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { isAlreadyLoggedFromAnotherTab: action.payload });
};

const setTabAsActive = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { activeTab: action.payload });
};

const setTabId = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { tabId: state.tabId === '' ? action.payload : state.tabId });
};

const setTabListSize = (state: StateAuthCred, action: any) => {
  return U.updateStore(state, { tabListSize: action.payload });
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // AUTODATA-API LOGIN
    case actionTypes.USER_LOGIN_TO_AUTODATA_GET_INIT:
      return userLoginToAutodataGetInit(state);
    case actionTypes.USER_LOGIN_TO_AUTODATA_GET_AUTH:
      return userLoginToAutodataGet(state, action);
    case actionTypes.USER_LOGIN_TO_AUTODATA_GET_SUCCESS:
      return userLoginToAutodataGetSuccess(state);
    case actionTypes.USER_LOGIN_TO_AUTODATA_GET_FAILED:
      return userLoginToAutodataGetFailed(state, action);
    // AUTODATA-API LOGIN
    case actionTypes.USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET_INIT:
      return userAutologinToAutodataAuthGetInit(state);
    case actionTypes.USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET:
      return userAutologinToAutodataAuthGet(state, action);
    case actionTypes.USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET_SUCCESS:
      return userAutologinToAutodataAuthGetSuccess(state);
    case actionTypes.USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET_FAILED:
      return userAutologinToAutodataAuthGetFailed(state, action);
    //
    case actionTypes.SET_INITIAL_REDIRECT_URL:
      return setInitialRedirectURL(state, action);
    // HEIRLOOM-API  LOGIN
    case actionTypes.USER_LOGIN_TO_HEIRLOOM_INIT:
      return userLogingToHeirloomPostInit(state);
    case actionTypes.USER_LOGIN_TO_HEIRLOOM_POST:
      return UserLoginToHeirloomFetchValidation();
    case actionTypes.USER_LOGIN_TO_HEIRLOOM_POST_SUCCESS:
      return userLoginToHeirloomPostSuccess(state);
    case actionTypes.USER_LOGIN_TO_HEIRLOOM_POST_FAILED:
      return userLoginToHeirloomPostFailed(state, action);
    //
    case actionTypes.HEIRLOOM_AUTH_UPDATED:
      return heirloomAuthUpdated(state, action);
    case actionTypes.USER_LOGIN_DIALOG_UPDATED:
      return setLoginDialogOpeningState(state, action);
    // RESET PWD
    case actionTypes.PASSWORD_RESET_DIALOG_CLOSED:
      return passwordResetDialogClosed(state, action);
    case actionTypes.UPDATE_RESET_DIALOG:
      return updateResetDialog(state, action);
    case actionTypes.USER_PASSWORD_RESET_POST_INIT:
      return userPasswordResetPostInit(state);
    case actionTypes.USER_PASSWORD_RESET_POST_SUCCESS:
      return userPasswordResetPostSuccess(state);
    case actionTypes.USER_PASSWORD_RESET_POST_FAILED:
      return userPasswordResetPostFailed(state, action);
    case actionTypes.USER_PASSWORD_RESET_DIALOG_BUTTON_VISIBLE:
      return setUserPasswordResetDialogButtonVisible(state, action);
    // RECOVERY
    case actionTypes.PASSWORD_RECOVERY_DIALOG_CLOSED:
      return passwordLinkRecoveryDialogClosed(state, action);
    case actionTypes.UPDATE_RECOVERY_DIALOG:
      return updateRecoveryDialog(state, action);
    case actionTypes.USER_PASSWORD_LINK_RECOVERY_DIALOG_BUTTON_VISIBLE:
      return setPasswordLinkRecoveryDialogButtonVisible(state, action);
    case actionTypes.USER_PASSWORD_LINK_RECOVERY_POST_INIT:
      return userPasswordLinkRecoveryPostInit(state);
    case actionTypes.USER_PASSWORD_LINK_RECOVERY_POST_SUCCESS:
      return userPasswordLinkRecoveryPostSuccess(state);
    case actionTypes.USER_PASSWORD_LINK_RECOVERY_POST_FAILED:
      return userPasswordLinkRecoveryPostFailed(state, action);
    //
    case actionTypes.AUTODATA_AUTH_USER:
      return { ...state, authenticated: action.payload };
    case actionTypes.AUTODATA_USER_JWT_UPDATED:
      return { ...state, userJWT: jwt(action.payload) };
    case actionTypes.AUTH_ERROR:
      return { ...state, error: action.payload };
    case actionTypes.IS_USER_AUTH_TO_AUTODATA_API:
      return setUserAuthenticatedAgainstAutodataAPI(state, action);
    case actionTypes.IS_USER_AUTH_TO_HEIRLOOM_API:
      return setUserAuthenticatedAgainstHeirloomAPI(state, action);
    case actionTypes.SET_IS_ALREADY_LOGGED_FROM_ANOTHER_TAB:
      return setIsAlreadyLoggedFromAnotherTab(state, action);
    //
    case actionTypes.SET_TABLIST_SIZE:
      return setTabListSize(state, action);
    case actionTypes.SET_TAB_AS_ACTIVE:
      return setTabAsActive(state, action);
    case actionTypes.SET_TAB_ID:
      return setTabId(state, action);
    case actionTypes.SET_SESSION_AS_TABBED:
      return setSessionUIState(state, action);
    case actionTypes.SET_SESSION_AS_PARENT:
      return setSessionAsParent(state);
    case actionTypes.SET_HAS_SIBLING:
      return setHasSibling(state, action);
    case actionTypes.SET_IS_LOGGED:
      return setIsLogged(state, action);

 case actionTypes.SET_QTR_STATE:
      return setQTRState(state, action);
      
    //
    case actionTypes.DESTROY_SESSION:
      return reInitialisedState(state, action);

    default:
      return state;
  }
};

export default authReducer;
