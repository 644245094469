
import { put } from 'redux-saga/effects';
import { getMenuItems } from '../../felles_____OOO/X_X_X_X_X_X_X_X_APICalls';
import * as actions from '../actions';

export function* getMenuItemSaga() {
  try {
    yield put(actions.menuItemGetInit());
    const response = yield getMenuItems();
    yield put(actions.menuItemGetSuccess(response.data));
  } catch (error) {
    yield put(actions.menuItemGetFailed(error));
  }
}
