import { put } from 'redux-saga/effects';
import { getUserProfileData, updateUserProfileData } from '../../felles_____OOO/X_X_X_X_X_X_X_X_APICalls';
import * as actions from '../actions';

//
export function* userProfileDataFetchSaga() {
  try {
    yield put(actions.userProfileDataFetchStarted());
    const response = yield getUserProfileData();
    yield put(actions.userProfileDataFetchSuccess(response.data));
  } catch (error) {
    yield put(actions.userProfileDataFetchFailed(error));
  }
}

//
export function* userProfileDataUpdate(action: any) {
  try {
    yield put(actions.userProfileDataUpdateStarted());
    const response = yield updateUserProfileData(action.userProfileData);
    yield put(actions.userProfileDataUpdateSuccess(response.data));
  } catch (error) {
    yield put(actions.userProfileDataUpdateFailed(error));
  }
}
