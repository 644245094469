import MenuEl from "./MenuEl";

export default class SearchSel {

  private _code: string;
  private _tilpasset: number;
  private _name: string;
  private _indexing: string;
  private _menuItem: MenuEl;

  constructor(code = '', tilpasset = 0/*: number*/, name = '', indexing = '', menuItem: MenuEl = new MenuEl()) {
    this._code = code;
    this._tilpasset = tilpasset;
    this._name = name;
    this._indexing = indexing;
    this._menuItem = menuItem;
  }

  get code(): string {
    return this._code;
  }

  get tilpasset(): number {
    return this._tilpasset;
  }

  get name(): string {
    return this._name;
  }

  get indexing(): string {
    return this._indexing;
  }

  get menuItem(): MenuEl {
    return this._menuItem;
  }
}
