import fileDownload from 'js-file-download';
import { put, select } from 'redux-saga/effects';
import Utils from '../../felles_____OOO/Utils';
import { deleteFromUserListSelected, getAdminFirmListFromDB, getGroupListFromAD, getGroupListFromAdmin, getLDAPUserData, getUserCVSList, getUserList, registerUser, updateUserData } from '../../felles_____OOO/X_X_X_X_X_X_X_X_APICalls';
import LdapUser from '../../model/LdapUser';
import * as actions from '../actions';
import * as selectors from '../selectors';

//
export function* userListFetchSaga() {
  try {
    yield put(actions.userListStart());
    const response = yield getUserList();
    if (response/* && response.userDataList*/) {
      const userList: LdapUser = response.data.map((l: LdapUser, i) => { l.id = i; return l; });
      yield put(actions.userListFetchSuccess(userList));
    }
  } catch (error) {
    yield put(actions.userListFetchFailed(error));
  }
}

//
export function* fetchUserLDAPDataSaga(action: any) {
  try {
    yield put(actions.userDataFetchStarted());
    const response = yield getLDAPUserData(action.username);
    const responseGroup = yield getGroupListFromAdmin();
    if (response && responseGroup) {
      yield put(actions.userDataFetchSuccess(response.data, responseGroup.data));
    }
  } catch (error) {
    yield put(actions.userDataFetchFailed(error));
  }
}

//
export function* userDataUpdateSaga(payload: any) {
  try {
    yield put(actions.userDataUpdateStarted());
    const response = yield updateUserData(payload.ldapUser);
    if (response) {
      yield put(actions.userDataUpdateSuccess(/*response.data*/));
    }
  } catch (error) {
    yield put(actions.userListFetchFailed(error));
  }
}

//
export function* userListDeleteSelectedSaga(action: any) {
  try {
    yield put(actions.userListSelectedDeletionStarted());
    const response = yield deleteFromUserListSelected(action.userListToDelete);
    if (response) {
      yield put(actions.setUserDeletionState(2));
      yield put(actions.userListSelectedDeletionFetchSuccess());
    }
  } catch (error) {
    yield put(actions.setUserDeletionState(3));
    yield put(actions.userListSelectedDeletionFetchFailed(error));
  }
}

//
export function* userGetGroupListSaga() {
  try {
    yield put(actions.groupListGetStarted());
    const response = yield getGroupListFromAD();
    const responseAdmin = yield getGroupListFromAdmin();
    if (response && responseAdmin) {
      yield put(actions.groupListGetSuccess(response.data, responseAdmin.data));
    }
  } catch (error) {
    yield put(actions.groupListGetFailed(error));
  }
}

//
export function* adminGetFirmListFromDBSaga() {
  try {
    yield put(actions.firmListFromDBGetStarted());
    const response = yield getAdminFirmListFromDB();
    //
    if (response) {
      yield put(actions.firmListFromDBGetSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.firmListFromDBGetFailed(error));
  }
}

export function* userRegistrationSaga(payload: any) {
  try {
    yield put(actions.userRegistrationStarted());
    const response = yield registerUser(payload.ldapUser);
    if (response) {

      yield put(actions.userRegistrationSuccess(response.data));
    }
  } catch (error: any) {
    yield put(actions.userRegistrationFailed(error));
  }
}

// Excel
export function* userListExportDataToExcelDocument() {
  const date = yield select(selectors.date);
  try {
    yield put(actions.userListExportToCVSInit());
    const response = yield getUserCVSList(date);
    if (response) {
      yield fileDownload(response.data, 'bruker-list_' + Utils.getFormattedDate(date) + '_' + new Date().getTime() + '.xlsx');
      yield put(actions.userListExportToCVSSuccess());
    }
  } catch (error) {
    yield put(actions.userListExportToCVSFailed());
  }
}


