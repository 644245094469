import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Profil from '../../../components/SVGProfil';
import Utils from '../../../felles_____OOO/Utils';
import { AppState } from '../../../store/rootReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'transparent',
      color: '#fff',
      display: 'flex',
      width: 290,
      overflow: 'hidden',
      //  border: '2px solid white',
    },
    profileLogo: {
      width: '25%',
      marginLeft: '-5px',
      marginTop: '-14px',
      paddingRight: '10px',

      /* XXS */ [theme.breakpoints.between(0, 801)]: { width: '20%', marginLeft: '-5px', marginTop: '-5px', paddingRight: '10px' },
      /* XS  */ [theme.breakpoints.between(801, 1367)]: { width: '20%', marginLeft: '-5px', marginTop: '-5px', paddingRight: '10px' },
      /* SM  */ [theme.breakpoints.between(1367, 1681)]: {},
      /* MD  */ [theme.breakpoints.between(1681, 1921)]: {},
      /* LD  */ [theme.breakpoints.between(1921, 2561)]: {},
      /* XL  */ [theme.breakpoints.between(2561, 3841)]: {},
      /* XXL */ [theme.breakpoints.between(3841, 10000)]: {},
    },
    profileWrapper: {
      width: '75%',
    },
    profileUsername: {
      fontSize: '1.3em',
      fontWeight: 500,
      margin: '10px 5px 5px 5px',
    },
    profileCompany: {
      fontSize: '.79em',
    },
 
  })
);

export default function LPNavigationProfile() {
  const classes = useStyles();

  const user: any = useSelector((state) => (state as AppState).auth.userJWT);
  return (
     <NavLink to={Utils.HOMEPAGE_CONTEXT + '/BR04'}><div className={classes.root}>
      <div className={classes.profileLogo}>
          <Profil />
      </div>
      <div className={classes.profileWrapper}>
        <div className={classes.profileUsername}>{user && user.displayName ? user.displayName : ''}</div>
        <div className={classes.profileCompany}>{user && user.company ? user.company : ''}</div>
      </div>
    </div> </NavLink>
  );
}
