import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { SizeMe } from 'react-sizeme';
import Utils from '../../felles_____OOO/Utils';
import MenuEl from '../../model/MenuEl';
import UISize from '../../model/UISize';
import { AppState } from '../../store/rootReducer';
import LPError from '../error/_LPError';
import LPAppBar from '../landing-page/components/LPAppBar';
import LPDrawer from '../landing-page/components/LPDrawer';
import AccountingJobTable from './AccountingJobTable';
import { useDispatch, useSelector } from 'react-redux';
import MenuItemTarget from '../../model/MenuItemTarget';
import * as actions from '../../store/actions/index';
import { history } from '../../store/history';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex', height: '100vh', width: '100vw', },
    content: {
      marginTop: 40,
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }), marginLeft: -340,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }), marginLeft: 0,
    },
  }),
);

export default function AccountingJobPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const screenSize: UISize = useSelector(state => (state as AppState).ui.screenSize);
  const menuItems: MenuEl[] = useSelector(state => (state as AppState).menuItem.menuItems);
  const open = screenSize.width > 0 && screenSize.width < 1200 ? false : true;
  let ert: any = undefined;
  if (menuItems && menuItems.length > 0) {
    ert = Utils.getTransactionsRole(menuItems, 'ACC1');
  }

  const n: MenuItemTarget = useSelector((state) => (state as AppState).menuItem.menuItemTarget);
  //
  const ValidKey: string[] = [
    'shift + home',
    'shift + end',
    'f3',
  ];

  //
  const handlePressedEndKey = (key) => {
    dispatch(actions.menuItemSetTarget(new MenuItemTarget(n.rootItem, n.item !== '' ? n.item : n.rootItem, '', '', n.tilpasset, n.hasChildren, n.title)));
    dispatch(actions.menuItemSetSelected([n.rootItem, n.item + n.rootItem]));
    history.push(Utils.HOMEPAGE_CONTEXT + '/menu');
  }

  //
  const handlePressedKey = (key, event) => {
    //
    event.preventDefault();
    if (key === 'shift + end' || key === 'f3') {
      handlePressedEndKey(key);
      return;
    }
  }

  //
  return (
    <> {ert ? <div className={classes.root}>
      <CssBaseline />
      <LPAppBar  ismenuvisible={true} />
      <LPDrawer />
      <KeyboardEventHandler
        handleKeys={ValidKey}
        onKeyEvent={(key, e) => {
          handlePressedKey(key, e);
        }}
      />
      <main className={clsx(classes.content, { [classes.contentShift]: open, })}>
        <SizeMe>{({ size }) => <AccountingJobTable size={size} />}</SizeMe>
      </main>
    </div > : <LPError />}</>
  );
}
