import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, styled, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UISize from '../../../model/UISize';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';
import LPLocationPath from './LPLocationPath';

const useStyles = makeStyles((theme: Theme) =>
({
  appBar: {
    backgroundColor: 'transparent',
    height: 70,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
      /* XXS */[theme.breakpoints.between(0, 801)]: { height: 30 },
      /* XS  */[theme.breakpoints.between(801, 1367)]: { height: 30 },
      /* SM  */[theme.breakpoints.between(1367, 1681)]: {},
      /* MD  */[theme.breakpoints.between(1681, 1921)]: {},
      /* LD  */[theme.breakpoints.between(1921, 2561)]: {},
      /* XL  */[theme.breakpoints.between(2561, 3841)]: {},
      /* XXL */[theme.breakpoints.between(3841, 10000)]: {},
  },
  appBarShift: {
    width: `calc(100% - ${340}px)`,
    marginLeft: 340,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(1), fontSize: '2em',
  },
  hide: { display: 'none' },


  /* XXS */[theme.breakpoints.between(0, 801)]: { height: 30 },
  /* XS  */[theme.breakpoints.between(801, 1367)]: { menuButton: { marginTop: -40, marginLeft: -20 }, },
  /* SM  */[theme.breakpoints.between(1367, 1681)]: {},
  /* MD  */[theme.breakpoints.between(1681, 1921)]: {},
  /* LD  */[theme.breakpoints.between(1921, 2561)]: {},
  /* XL  */[theme.breakpoints.between(2561, 3841)]: {},
  /* XXL */[theme.breakpoints.between(3841, 10000)]: {},


  '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
    menuButton: {
      marginTop: -40, marginLeft: -30,
      color: '#2e2d2c'
    },
  },


})
);



const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
    minHeight: 40,
  },

}));

export default function LPAppBar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const screenSize: UISize = useSelector((state) => (state as AppState).ui.screenSize);
  const drawerOpenState: boolean = useSelector((state) => (state as AppState).ui.isDrawerOpened);
  const button: boolean = useSelector((state) => (state as AppState).ui.isSandwichButtonTurnedOn);
  //
  useEffect(() => {
    dispatch(actions.setDrawerState(!(screenSize.width > 0 && screenSize.width < 1200)));
  }, [dispatch, screenSize]);
  //
  const handleDrawerToggle = () => {
    dispatch(actions.setSandwichButtonStateClicked(!button));
  };
  //
  return (
    <AppBar elevation={0} position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: button ? true : drawerOpenState })}>
      <StyledToolbar>
        {props.ismenuvisible ? <IconButton
          color="inherit"
          aria-label="drawerOpenState drawer"
          onClick={handleDrawerToggle}
          edge="start"
          className={clsx(classes.menuButton, drawerOpenState /* && classes.hide*/)}
        >
          {!drawerOpenState ? <MenuIcon style={{ fontSize: 30 }} /> : []}
        </IconButton> : null}
        <LPLocationPath />
      </StyledToolbar>
    </AppBar>
  );
}
