import FirmNavnId from '../../model/FirmNavnId';
import LdapGroup from '../../model/LdapGroup';
import LdapUser from '../../model/LdapUser';
import * as actionTypes from './actionTypes';

/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
export const userListStart = () => {
  return { type: actionTypes.USER_LIST_GET_INIT };
};

export const userListFetch = () => {
  return { type: actionTypes.USER_LIST_GET };
};

export const userListExportToCVSInit = () => {
  return { type: actionTypes.USER_LIST_EXPORT_TO_CVS_INIT };
};

export const userListExportToCVS = () => {
  return { type: actionTypes.USER_LIST_EXPORT_TO_CVS };
};

export const userListExportToCVSSuccess = () => {
  return { type: actionTypes.USER_LIST_EXPORT_TO_CVS_SUCCESS };
};

export const userListExportToCVSFailed = () => {
  return { type: actionTypes.USER_LIST_EXPORT_TO_CVS_FAILED };
};

export const userListFetchFailed = (error: any) => {
  return { type: actionTypes.USER_LIST_GET_FAILED, error: error };
};

export const userListFetchSuccess = (userDataList: any) => {
  return { type: actionTypes.USER_LIST_GET_SUCCESS, userDataList: userDataList };
};

/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
export const userDataFetchStarted = () => {
  return { type: actionTypes.USER_DATA_GET_INIT };
};

export const userDataFetched = (username: string) => {
  return { type: actionTypes.USER_DATA_GET, username };
};

export const userDataFetchSuccess = (userLDAPData: any, groupListFromAdmin: any) => {
  return { type: actionTypes.USER_DATA_GET_SUCCESS, userLDAPData: userLDAPData, groupListFromAdmin: groupListFromAdmin };
};
export const userDataFetchFailed = (error: any) => {
  return { type: actionTypes.USER_DATA_GET_FAILED, error: error };
};


/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */

export const userDataUpdateStarted = () => {
  return { type: actionTypes.USER_DATA_UPDATE_POST_INIT };
};

export const userDataUpdated = (ldapUser: LdapUser) => {
  return { type: actionTypes.USER_DATA_UPDATE_POST, ldapUser };
};
export const userDataUpdateSuccess = () => {
  return { type: actionTypes.USER_DATA_UPDATE_POST_SUCCESS };
};

export const userDataUpdateFailed = (error: any) => {
  return { type: actionTypes.USER_DATA_UPDATE_POST_FAILED, error: error };
};

/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */

export const userRegistrationStarted = () => {
  return { type: actionTypes.USER_REGISTRATION_POST_INIT };
};

export const userRegistration = (ldapUser: LdapUser) => {
  return { type: actionTypes.USER_REGISTRATION_POST, ldapUser };
};

export const userRegistrationSuccess = (msg: any) => {
  return { type: actionTypes.USER_REGISTRATION_POST_SUCCESS, msg: msg };
};

export const userRegistrationFailed = (error: any) => {
  return { type: actionTypes.USER_REGISTRATION_POST_FAILED, error: error };
};


/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */


export const userListSetDate = (date: any) => {
  return { type: actionTypes.USER_LIST_SET_DATE, date };
};

/***************************************************************** */

export const userListSelectedUpdated = (userId: any[]) => {
  return { type: actionTypes.USER_LIST_SELECTED_UPDATED, userId: userId };
};

/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */

export const userListSelectedDeletionStarted = () => {
  return { type: actionTypes.USER_LIST_SELECTED_DELETION_POST_INIT };
};

export const userListSelectedDeletionFetch = (userListToDelete: string[]) => {
  return { type: actionTypes.USER_LIST_SELECTED_DELETION_POST, userListToDelete: userListToDelete };
};

export const userListSelectedDeletionFetchSuccess = () => {
  return { type: actionTypes.USER_LIST_SELECTED_DELETION_POST_SUCCESS };
};

export const userListSelectedDeletionFetchFailed = (error: any) => {
  return { type: actionTypes.USER_LIST_SELECTED_DELETION_POST_FAILED, error: error };
};

export const userDeletionDialogUpdated = (payload: boolean) => {
  return { type: actionTypes.USER_DELETE_DIALOG_UPDATED, payload };
};


export const setUserEditState = (payload: number) => {
  return { type: actionTypes.USER_STATE_EDIT, payload };
};


export const setUserRegistrationState = (payload: number) => {
  return { type: actionTypes.USER_STATE_REGISTRATION, payload };
};

export const setUserDeletionState = (payload: number) => {
  return { type: actionTypes.USER_STATE_DELETE, payload };
};

export const setUserSelectedGroupList = (userSelectedGroupList: string[]) => {
  return { type: actionTypes.USER_GROUP_LIST_FROM_LDAP_SET_SELECTED, userSelectedGroupList: userSelectedGroupList };
};

export const groupListGetStarted = () => {
  return { type: actionTypes.USER_GROUP_LIST_FROM_LDAP_GET_INIT };
};

export const groupListGet = () => {
  return { type: actionTypes.USER_GROUP_LIST_FROM_LDAP_GET };
};

export const groupListGetSuccess = (groupListFromAD: string[], groupListFromAdmin: LdapGroup[]) => {
  return { type: actionTypes.USER_GROUP_LIST_FROM_LDAP_GET_SUCCESS, groupListFromAD: groupListFromAD, groupListFromAdmin: groupListFromAdmin };
};

export const groupListGetFailed = (error: any) => {
  return { type: actionTypes.USER_GROUP_LIST_FROM_LDAP_GET_FAILED, error: error };
};




export const firmListFromDBGetStarted = () => {
  return { type: actionTypes.ADMIN_FIRM_LIST_FROM_DB_GET_INIT };
};

export const firmListFromDBGet = () => {
  return { type: actionTypes.ADMIN_FIRM_LIST_FROM_DB_GET };
};

export const firmListFromDBGetSuccess = (firmListFromAdmin: FirmNavnId[]) => {
  return { type: actionTypes.ADMIN_FIRM_LIST_FROM_DB_GET_SUCCESS, firmListFromAdmin: firmListFromAdmin};
};

export const firmListFromDBGetFailed = (error: any) => {
  return { type: actionTypes.ADMIN_FIRM_LIST_FROM_DB_GET_FAILED, error: error };
};




