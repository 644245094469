export default class MenuEl {
  private _heading: string;
  private _code: string;
  private _name: string;
  private _role: string;
  private _parentName: string;
  private _rootName: string;
  private _description: string;

  private _nodes: MenuEl[] = [];
  private _noNodes: MenuEl[] = [];
  private _hasChildren: boolean;
  private _tilpasset: number;
 

  constructor(name = '', role = '', parentName = '', rootName = '', heading = '', code = '', tilpasset= 0, description = '', nodes: MenuEl[] = []
    , hasChildren = false) {
    this._name = name;
    this._role = role;
    this._parentName = parentName;
    this._rootName = rootName;
    this._heading = heading;
    this._code = code;
    this._description = description;
    this._nodes = nodes;
    this._hasChildren = hasChildren;
    this._tilpasset = tilpasset;

  }

  get name(): string {
    return this._name;
  }

  get parentName(): string {
    return this._parentName;
  }

  get rootName(): string {
    return this._rootName;
  }

  get description(): string {
    return this._description;
  }

  get role(): string {
    return this._role;
  }

  get heading(): string {
    return this._heading;
  }

  get code(): string {
    return this._code;
  }

  get nodes(): any[] {
    return this._nodes;
  }

  get noNodes(): any[] {
    return this._noNodes;
  }

  get hasChildren(): boolean {
    return this._hasChildren;
  }

  get tilpasset(): number {
    return this._tilpasset;
  }

}
