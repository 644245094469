import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SVGLeftArrow from '../../../components/SVGLeftArrow';
import MenuEl from '../../../model/MenuEl';
import MenuItemTarget from '../../../model/MenuItemTarget';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';
import C from '../../../felles_____OOO/UtilsCSS';
import UISize from '../../../model/UISize';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    base: {
      fontFamily: '"Inter", sans-serif', fontWeight: 'normal', fontSize: '1.2em', marginLeft: 0, marginTop: 0, color: '#fff',
      [C.r(theme, C.XXS, C.XXX)]: { fontSize: '1.2em', },
      [C.r(theme, C.XS, C.XSX)]: { fontSize: '1.2em', },
      [C.r(theme, C.SM, C.SMX)]: { fontSize: '1.3em', },
      [C.r(theme, C.MD, C.MDX)]: { fontSize: '1.4em', },
      [C.r(theme, C.LG, C.LGX)]: { fontSize: '1.5em', },
      [C.r(theme, C.XL, C.XLX)]: { fontSize: '1.6em', },
      [C.r(theme, C.XXL, C.YLX)]: { fontSize: '1.7em', },
      [C.IPAD_10]: { color: '#000', fontSize: '1em', marginTop: 15, },
      [C.SAMSUNG]: { color: '#000', fontSize: '1em', marginTop: 15, },
    },
    pathItem: { display: 'flex', columnGap: '6px' },

    // Apple 10.2-inch iPad Wi-Fi + Cellular - 9. generasjon
    '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
      root: { color: '#000', },
    },

    // Samsung GalaxyTab
    '@media only screen and (min-width: 1205px) and (max-height: 650px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)': {
      root: { color: '#000', },
    },

    visible: {
      visibility: 'visible',
   
    },
    hidden: {
      visibility: 'hidden',
   },


  })
);

export default function LPLocationPath(props) {
  const classes = useStyles();
  const mIT: MenuItemTarget = useSelector((state) => (state as AppState).menuItem.menuItemTarget);
  const termID: string = useSelector((state) => (state as AppState).trans.termId);
  const menuItems: MenuEl[] = useSelector((state) => (state as AppState).menuItem.menuItems);
  const isDebugEnabled: boolean = useSelector((state) => (state as AppState).utils.isDebugEnabled);
  const currentBreakpoint: string = useSelector((state) => (state as AppState).ui.currentBreakpoint);
  const scrollTop: number = useSelector((state) => (state as AppState).ui.scrollTop);
  const selected: string = useSelector((state) => (state as AppState).menuItem.selected);
  const dispatch = useDispatch();
  const screenSize: UISize = useSelector((state) => (state as AppState).ui.screenSize);
  const locationPath: string = useSelector((state) => (state as AppState).ui.locationPath);

  useEffect(() => {
    // Traversing meny item list for new opened tab or windows
    // setups the location and selected menu
    if (mIT.code !== '' && mIT.code !== document.title.substring(0, 4)) {
      if (mIT.rootItem === '') {
        menuItems.forEach((A: MenuEl, i) => {
          A.nodes.forEach((B: MenuEl, j) => {
            B.nodes.forEach((C: MenuEl, j) => {
              C.nodes.forEach((D: MenuEl, j) => {
                if (mIT.code === D.code) {
                  mIT.rootItem = D.rootName;
                  mIT.item = D.parentName;
                  mIT.subitem = D.name;
                  mIT.title = D.description;
                  dispatch(actions.menuItemSetExpanded([D.rootName]));
                  dispatch(actions.menuItemSetSelected([D.rootName, D.parentName + D.rootName]));
                  document.title = mIT.code + ' - ' + mIT.title;
                }
              });
            });
          });
        });
      } else {
        document.title = mIT.code + ' - ' + mIT.title;
      }
    }
  }, [dispatch, termID, mIT, selected, screenSize, locationPath]);
  //
  return (
    <>
     <div key="locatA" className={classes.base}>
          <div key="locatB" className={classes.pathItem}>{isDebugEnabled ? currentBreakpoint + ' | ' : null}
            <div className={(scrollTop > 20 || scrollTop === 0) ? classes.visible : classes.hidden}>{locationPath}</div>
          </div>
        </div>
    </>
  );
}
